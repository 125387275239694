import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    marginBottom: theme.spacing(4),
  },
  paper: {
    maxWidth: theme.spacing(64),
    borderRadius: 8,
    boxShadow: '0px 4px 80px rgba(0, 0, 0, 0.08)',
  },
  container: {
    padding: theme.spacing(5),
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      padding: theme.spacing(3),
    },
  },
  ackPaper: {
    maxWidth: theme.spacing(96),
  },
  formTitle: {
    color: theme.colors.text.black02,
    textAlign: 'center',
    padding: theme.spacing(4, 0, 4, 0),
    fontSize: '24px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '-0.18px',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      fontWeight: 500,
      padding: theme.spacing(2, 0),
    },
  },
  formBox: {
    width: '100%',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  messages: {
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-0.18px',
    wordBreak: 'break-word',
    color: theme.colors.text.black,
  },
  alertContainer: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  actionButton: {
    margin: 0,
    width: '100%',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down(theme.screenbreakpoint.tablet)]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      width: '100%',
    },
  },
  signLink: {
    textDecoration: 'none',
  },
}));

export default styles;
