import React from 'react';
import { FetchResult, useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import { CITIZEN_SIGN_ID } from '../mutation';
import {
  CitizenSignIdMutation,
  CitizenSignIdMutationVariables,
} from '../types/CitizenSignIdMutation';
import { useErrorHanlder } from '../../../modules/hooks';
import { ApolloErrorCode } from '../../../consts';
import { REACT_APP_HEALTH_PROFILE_URL } from '../../../config';

export const useCitizenSignIdMutation = (): {
  signIdMutation: (
    citizenId: string,
  ) => Promise<FetchResult<CitizenSignIdMutation>>;
  signIdLoading: boolean;
  errorAlert?: string | null;
  closeAlert: () => void;
} => {
  const intl = useIntl();
  //   const { enqueueSnackbar } = useSnackbar();
  const handleError = useErrorHanlder();

  const [errorAlert, setErrorAlert] = React.useState<string | null>();

  const closeAlert = () => {
    setErrorAlert(null);
  };

  const [mutate, { loading: signIdLoading }] = useMutation<
    CitizenSignIdMutation,
    CitizenSignIdMutationVariables
  >(CITIZEN_SIGN_ID, {
    onCompleted: (data) => {
      if (data?.healthProfileSignInCitizenId?.accessKey) {
        setErrorAlert(null);
        window.open(
          REACT_APP_HEALTH_PROFILE_URL +
            '/#/c/' +
            data.healthProfileSignInCitizenId.accessKey,
          '_blank',
        );
      }
    },
    onError: (error) => {
      handleError(error, [
        {
          code: ApolloErrorCode.CITIZEN_NOT_FOUND,
          message: intl.formatMessage({
            id: 'citizen.error.notfound.label',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'error.message.unknow',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
      ]);
    },
  });

  const signIdMutation = (
    citizenId: string,
  ): Promise<FetchResult<CitizenSignIdMutation>> => {
    return mutate({ variables: { citizenId } });
  };

  return {
    signIdMutation,
    signIdLoading,
    errorAlert,
    closeAlert,
  };
};
