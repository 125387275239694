import { gql } from '@apollo/client';

export const JOB_INFO_FRAGMENT = gql`
  fragment JobInfoFragment on Job {
    id
    code
    name
    nameMg
    other
  }
`;

export const EMPLOYERENTITY_INFO_FRAGMENT = gql`
  fragment EmployerEntityInfoFragment on EmployerEntity {
    id
    municipalityId
    name
  }
`;
