export default {
  'signin.app.label': 'Application pour la Gestion de Profil de santé',
  'signin.form.hint.label': "Miditra amin'ny kaontinao",
  'signin.form.call.for.registration.message': 'Misoratà anarana',
  'signin.page.title.label': 'Fidirana',
  'signin.login.label': "Solon'anarana",
  'signin.login.placeholder.label': 'Anarana',
  'signin.password.label': 'Teny miafina',
  'signin.password.placeholder.label': 'EPI_99999999',

  'signin.buttom.label': 'Miditra',
  'signin.faq.label': 'Jereo ny torolalana',
  'signin.back.button.label': 'Retourner à la page de connection',
  'signin.forgot.password.label': 'Hadino ny teny miafina ?',
  'signin.keepmeloggedin.label': 'Gardez-moi connecté',
  'signin.login.success': 'Tontosa ny fanamarinana',
  'signin.login.error': 'Erreur: identifiant ou mot de passe invalide!',
  'signin.password.error.min.message': 'Tokony hisy soratra 4 farafahakeliny',
  'signin.password.error.max.message': 'Tokony hisy soratra 32 farafahalavany',
  'signin.password.error.required.message': 'Ampidiro ny teny miafina',
  'signin.login.error.required.message': "Ampidiro ny anaran'ny kaontinao",
  'signin.login.error.min.message': 'Tokony hisy soratra 2 farafahakeliny',
  'signin.login.error.max.message': 'Tokony hisy soratra 32 farafahalavany',

  'signin.tips.primary.title': 'VAKSINY FANEFITRY NY COVID-19',
  'signin.tips.secondary.title':
    "Tongasoa eto amin'ny tranokala ofisialy fisoratana anarana amin'ny vaksiny fanefitry ny covid-19..<br/><strong>“An-tsitrapo ny vaksiny”.</strong>",
  'signin.subscribe.hint.message':
    "Misokatra ny fisoratana anarana ho an'izay maniry ny hatao vaksiny<br/> indrindra ho an'ireto sokajin'olona manaraka ireto :",
  'signin.persona.travelers.label':
    'Ireo olona tsy maintsy mandeha any ivelany mba hiasa na hianatra ary manana antontan-taratasy fanamarinana',
  'signin.persona.healthworker.label':
    "Mpiasan'ny fahasalamana<br/>(amin'ny Fanjakana sy ny tsy miankina)",
  'signin.persona.frailpeople.label':
    'Ireo olona manana aretina mitaiza sy misitraka fanaraha-maso ara-pahasalamana',
  'signin.persona.govsecurity.label': 'Ny mpitandro filaminana',
  'signin.persona.socialworker.label': "Ireo mpiasan'ny sosialy",
  'signin.persona.teleoperator.label':
    'Raha mila fanazavana fanampiny dia antsoy ny laharana 910.',
  'signin.tips.main.message': `<p>La vaccination est maintenant accessible à toutes les personnes âgées de 18ans et plus.
    <p>Vous n’êtes pas encore vacciné ? ou vous êtes vaccinés mais pas encore enregistrés via tablette lors de votre passage au centre de vaccination.</p>
    <p><b>Prenez 2 minutes pour vous inscrire sur cette plateforme!</b></p>
    <p>L’inscription n’est pas obligatoire mais avoir un compte vous permet de bénéficier des avantages suivants :</p>
    <p>- faciliter votre enregistrement au niveau des centres de vaccinations ;</p>
    <p>- organiser votre prochain rendez-vous pour la 2e dose ;</p>
    <p>- disposer ultérieurement des fonctionnalités complémentaires proposées par la plateforme comme la délivrance d’une attestation de vaccination officielle ou d’une carte de vaccination digitale et bien d’autres.</p>
    <p>Pour toutes informations complémentaires, appelez le 910.</p>`,
};
