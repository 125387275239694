import React, { FC } from 'react';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { useIntl } from 'react-intl';
import GovMgBrandingImage from '../../../assets/images/gov-mg-brand.svg';
import MsanpBrandingImage from '../../../assets/images/msanp-brand.svg';
import { useStyles } from './styles';

const PageHeader: FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <Box component="div" className={classes.root}>
      <Container className={classes.container}>
        <Box className={classes.wrapper}>
          <Box>
            <img
              className={classes.govBrand}
              src={GovMgBrandingImage}
              alt="GOV MG LOGO"
            />
          </Box>
          <Box>
            <a
              href="http://www.sante.gov.mg/ministere-sante-publique"
              target="blank"
            >
              <img
                className={classes.msanpBrand}
                src={MsanpBrandingImage}
                alt="MSANP LOGO"
              />
            </a>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PageHeader;
