export default {
  'faq.back.button.label': 'Retour à la page de connexion',
  'faq.page.title': 'Foire aux questions',
  'faq.form.title': 'FAQs',
  'faq.technical.support': 'Support technique.',
  'faq.caption.message':
    "Tout ce que vous devez savoir sur la vaccination. Si Vous ne trouvez pas la réponse que vous cherchez ?  <a href='mailto:vaccinationcovid19@googlegroups.com'>Contactez notre équipe.</a>",
  'faq.empty.primary.message': 'Nous ne pouvons pas trouver cette question',
  'faq.empty.secondary.message':
    "Désolé, la reponse que vous recherchez n'existe pas.",
  'faq.choose.category.label': 'Sélectionner une catégorie',
  'faq.choose.theme.label': 'Rechercher une thématique',
};
