import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import classnames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import UploadIcon from '@material-ui/icons/Backup';

import DefaultAvatar from '../../../assets/images/default-profile-pic.svg';

import useStyles from './styles';
interface IProps {
  src?: string | null;
  size?: 'small' | 'medium' | 'large';
  onEditClick?: () => void;
  onClick?: () => void;
}

const UserAvatar = React.forwardRef<HTMLButtonElement, IProps>(
  ({ size = 'small', src, onClick, onEditClick }, ref) => {
    const classes = useStyles();
    const renderAvatar = () => (
      <Avatar
        alt="User Avatar"
        variant="rounded"
        src={src ?? DefaultAvatar}
        className={classnames(
          size === 'small' && classes.small,
          size === 'medium' && classes.medium,
          size === 'large' && classes.large,
        )}
      />
    );

    return (
      <Box className={classes.container} component="div">
        {onClick ? (
          <ButtonBase
            className={classes.avatarButton}
            ref={ref}
            onClick={onClick}
          >
            {renderAvatar()}
          </ButtonBase>
        ) : (
          renderAvatar()
        )}
        {onEditClick && (
          <Box component="div" className={classes.editButtonContainer}>
            <IconButton onClick={onEditClick}>
              <UploadIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>
    );
  },
);

UserAvatar.displayName = 'UserAvatar';

export default UserAvatar;
