import { makeStyles } from '@material-ui/core/styles';
// import bgImage from '../../assets/images/bg-vakisiny.jpeg';

const styles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(7, 16),
    [theme.breakpoints.down(theme.screenbreakpoint.tablet)]: {
      padding: theme.spacing(3, 8),
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      padding: theme.spacing(3, 4),
    },
  },
  title: {
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '0.82px',
    color: theme.colors.text.black02,
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down(theme.screenbreakpoint.tablet)]: {
      marginBottom: theme.spacing(7),
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      marginBottom: theme.spacing(3),
    },
  },
  centent: {
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-0.18px',
    wordBreak: 'keep-all',
    color: theme.colors.text.black,
  },
}));

export default styles;
