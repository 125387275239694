import { format, add, sub } from 'date-fns';

export default {
  'error.title.backToHome': 'Back to Home',
  'error.title.error': 'Error',
  'error.message.unknow':
    "Une erreur inconnue s'est produite, vérifiez votre réseau ou contactez l'administrateur.",
  'error.message.internalServerError': 'Internal server error',
  'error.message.networkError':
    'Veuillez vérifier votre connexion Internet et réessayer.',
  'error.message.unauthenticated':
    'You must be logged in to perform this operation.',
  'error.message.forbidden':
    "Vous n'avez pas les droits pour effectuer cette action.",
  'error.message.args.type': 'Wrong type of arguments.',
  'error.loading.label':
    "Une erreur s'est produite lors du chargement des données",
  'error.helper.required': 'Ce champ est obligatoire',

  'error.date.invalid.message': 'Date incorrecte',
  'error.date.max.label': `La date ne doit pas être postérieure à ${format(
    new Date(),
    'dd/MM/yyyy',
  )}`,
  'error.date.min.label': "L'année ne doit pas être antérieure à 1900",

  'error.birth.date.max.label': `La date ne doit pas être postérieure à  ${format(
    sub(new Date(), { years: 18 }),
    'dd/MM/yyyy',
  )}`,
  'error.appointment.date.min.label': `La date ne doit pas être antérieure à  ${format(
    add(new Date(), { days: 1 }),
    'dd/MM/yyyy',
  )}`,
  'error.appointment.date.max.label': `La date ne doit pas être postérieure à  ${format(
    add(new Date(), { years: 1 }),
    'dd/MM/yyyy',
  )}`,
};
