import React, { FC, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ConfirmButton from '../../components/button/ConfirmButton';
import AutoCompleteField from '../../components/inputfields/AutoCompleteField';
import TextInputField from '../../components/inputfields/TextInputField';

import PageContainer from '../../components/page/PageContainer';
import useStyles from './styles';
import { useAllFaq } from '../../graphql/faq/hooks';
import { usePageMetaContext } from '../../modules/PageMetaProvider';
import FaqCuriousIndividu from '../../assets/images/faq-curious-individu.svg';
import FaqEmpty from '../../assets/images/faq-empty.svg';
import FaqItem from './FaqItem';
import { DEFAULT_SIGNIN_ROUTE } from '../../consts';

const Faq: FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const [faqCategory, setFaqCategory] = useState<string>();
  const [searchQuery, setSearchQuery] = useState<string>();
  const { faqCategs, faqs, isLoading: isLoading } = useAllFaq();

  // Page context handler
  const { setPathLabels } = usePageMetaContext();

  // Update page context
  useEffect(() => {
    setPathLabels([
      intl.formatMessage({
        id: 'faq.page.title',
      }),
    ]);
  }, [intl.locale]);

  const faqList = faqs
    .filter(
      (qaItem) =>
        (faqCategory && qaItem.categId === faqCategory) || !faqCategory,
    )
    .filter(
      (qaItem) =>
        (searchQuery &&
          (qaItem.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
            qaItem.answer.toLowerCase().includes(searchQuery.toLowerCase()))) ||
        !searchQuery,
    );

  return (
    <>
      <PageContainer showBackground={true}>
        <Box component="div" className={classes.root}>
          <Box component="div" className={classes.controlPanel}>
            <Link to={DEFAULT_SIGNIN_ROUTE} className={classes.backLink}>
              <ConfirmButton color="secondary" startIcon={<ArrowBackIcon />}>
                {intl.formatMessage({
                  id: 'faq.back.button.label',
                })}
              </ConfirmButton>
            </Link>
          </Box>
          <Paper className={classes.paper}>
            <Box component="div" className={classes.content}>
              <Box component="div" className={classes.controlForm}>
                <Typography className={classes.technicalLabel}>
                  {intl.formatMessage({
                    id: 'faq.technical.support',
                  })}
                </Typography>
                <Typography className={classes.faqLabel}>
                  {intl.formatMessage({
                    id: 'faq.form.title',
                  })}
                </Typography>
                <AutoCompleteField
                  name="faqCategory"
                  isLoading={isLoading}
                  defaultValue={faqCategory ?? ''}
                  items={faqCategs}
                  getItemKey={(item) => item?.id ?? ''}
                  getItemLabel={(item) => item?.name ?? ''}
                  onSelectionChange={(selectedVal) => {
                    setFaqCategory(selectedVal);
                  }}
                  placeholder={intl.formatMessage({
                    id: 'faq.choose.category.label',
                  })}
                />
                <TextInputField
                  name="searchQuery"
                  fullWidth
                  disabled={isLoading}
                  value={searchQuery ?? ''}
                  onChange={(evt) => setSearchQuery(evt.target.value)}
                  placeholder={intl.formatMessage({
                    id: 'faq.choose.theme.label',
                  })}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <img
                  src={FaqCuriousIndividu}
                  className={classes.faqCuriousIndividu}
                />
                <Typography
                  className={classes.captionMessage}
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: 'faq.caption.message',
                    }),
                  }}
                />
              </Box>
              <Box component="div" className={classes.qaPanel}>
                {faqList.map((qaItem) => (
                  <FaqItem
                    key={`qa-${qaItem.id}`}
                    question={qaItem.question}
                    answer={qaItem.answer}
                  />
                ))}
                {faqList.length === 0 && (
                  <Box component="div" className={classes.qaEmptyPanel}>
                    <img src={FaqEmpty} className={classes.faqEmptyImage} />
                    <Typography className={classes.emptyPrimaryMessage}>
                      {intl.formatMessage({
                        id: 'faq.empty.primary.message',
                      })}
                    </Typography>
                    <Typography className={classes.emptySecondaryMessage}>
                      {intl.formatMessage({
                        id: 'faq.empty.secondary.message',
                      })}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </PageContainer>
    </>
  );
};

export default Faq;
