export default {
  'forgotpassword.title.label': 'Mot de passe oublié',
  'forgotpassword.hint.message':
    "Veuillez saisir votre adresse email ou identifiant unique afin d'obtenir le lien pour réinitialiser votre mot de passe.",
  'forgotpassword.username.required.message': 'Entrez votre identifiant',
  'forgotpassword.id.label': 'Adresse email ou identifiant',
  'forgotpassword.reset.mypassword.label': 'Réinitialiser mon mot de passe',
  'forgotpassword.reset.cancel.label': 'Annuler',
  'forgotpassword.login.invalid.message':
    "Votre adresse e-mail ou votre identifiant n'a pas été reconnu. Il n'existe pas de profil de santé correspondant à l'identifiant que vous essayez d'entrer !",
  'forgotpassword.email.not.trusted.message':
    "Votre adresse e-mail n'est pas valide. Il est également possible que vous n'avez pas fourni l'adresse correcte. Il est donc impossible de réinitialiser votre mot de passe.",
  'forgotpassword.username.invalid.message':
    'Addresse email ou identifiant unique 12 chiffres',
  'forgotpassword.invalid.link.message':
    "Ce lien n'est plus valide, il a peut-être expiré. Vous devez retourner à la page Mot de passe oublié pour le réinitialiser.",
  'forgotpassword.ack.title.label':
    'Email de réinitialisation du mot de passe envoyé',
  'forgotpassword.ack.resend.email.label': "Renvoyez l'e-mail",
  'forgotpassword.ack.message.label1': '<p>Veuillez vérifier votre email.</p>',
  'forgotpassword.ack.message.label2':
    'Un email a été envoyé à {theEmailAddress}.',
  'forgotpassword.ack.message.label3':
    "<p>Cliquez sur réinitialiser mon mot de passe, puis commencez à saisir votre nouveau mot de passe sur la page qui s'ouvre.</p><p>Vous n'avez pas reçu d'e-mail ?</p>",
};
