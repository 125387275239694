import React from 'react';
import { FetchResult, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useErrorHanlder } from '../../../modules/hooks';
import { ApolloErrorCode } from '../../../consts';
import { MY_PROFILE } from '../../authentication/query';
import { MyProfile } from '../../authentication/types/MyProfile';
import { CITIZEN_UPLOAD_PIC } from '../mutation';
import {
  CitizenUpdateProfilePicMutation,
  CitizenUpdateProfilePicMutationVariables,
} from '../types/CitizenUpdateProfilePicMutation';

export const useCitizenUpdateProfilePicMutation = (
  citizenId: string,
  options?: IMutationOptions,
): {
  updateProfilePicMutation: (
    thePicture: any,
  ) => Promise<FetchResult<CitizenUpdateProfilePicMutation>>;
  updateProfilePicLoading: boolean;
  successAlert?: string | null;
  errorAlert?: string | null;
  closeAlert: () => void;
} => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useErrorHanlder();
  const [successAlert, setSuccessAlert] = React.useState<string | null>();
  const [errorAlert, setErrorAlert] = React.useState<string | null>();

  const closeAlert = () => {
    setErrorAlert(null);
    setSuccessAlert(null);
  };

  const [mutate, { loading: updateProfilePicLoading }] = useMutation<
    CitizenUpdateProfilePicMutation,
    CitizenUpdateProfilePicMutationVariables
  >(CITIZEN_UPLOAD_PIC, {
    onCompleted: (data) => {
      if (data?.updateProfilePic?.success) {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'userprofile.uploadavatar.updated.message',
          }),
          {
            variant: 'success',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          },
        );
        setSuccessAlert(
          intl.formatMessage({
            id: 'userprofile.uploadavatar.updated.message',
          }),
        );
        options?.onSuccess?.();
      }
    },
    update: (cache, { data }) => {
      if (data && data?.updateProfilePic?.success) {
        const myData = cache.readQuery<MyProfile>({
          query: MY_PROFILE,
          variables: { citizenId },
        });

        if (myData && myData?.myProfile?.id) {
          cache.writeQuery<MyProfile>({
            query: MY_PROFILE,
            data: {
              ...myData,
              myProfile: {
                ...myData.myProfile,
                photo: data?.updateProfilePic?.photo,
              },
            },
            variables: { citizenId },
          });
        }
      }
    },
    onError: (error) => {
      handleError(error, [
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'userprofile.uploadavatar.error.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
      ]);
    },
  });

  const updateProfilePicMutation = (
    thePicture: any,
  ): Promise<FetchResult<CitizenUpdateProfilePicMutation>> => {
    closeAlert();
    return mutate({
      variables: { thePic: thePicture },
    });
  };

  return {
    successAlert,
    errorAlert,
    closeAlert,
    updateProfilePicMutation,
    updateProfilePicLoading,
  };
};
