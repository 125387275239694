import React from 'react';
import { FetchResult, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

import { useAuthContext } from '../../../modules/AuthProvider';
import { useErrorHanlder } from '../../../modules/hooks';
import { ApolloErrorCode, DEFAULT_HOME_ROUTE } from '../../../consts';
import { REACT_APP_HEALTH_PROFILE_URL } from '../../../config';
import { USER_SIGN_IN } from '../mutation';
import { SignIn, SignInVariables } from '../types/SignIn';

export const useSignIn = (): {
  signInMutation: (
    currentPassword: string,
    newPassword: string,
  ) => Promise<FetchResult<SignIn>>;
  signInLoading: boolean;
  errorAlert?: string | null;
  closeAlert: () => void;
} => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useErrorHanlder();
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search);

  const { signIn, signOut } = useAuthContext();

  const [errorAlert, setErrorAlert] = React.useState<string | null>();

  const closeAlert = () => {
    setErrorAlert(null);
  };

  const [mutate, { loading: signInLoading }] = useMutation<
    SignIn,
    SignInVariables
  >(USER_SIGN_IN, {
    onCompleted: (data) => {
      if (
        data?.healthProfileSignIn?.accessToken &&
        data?.healthProfileSignIn?.refreshToken
      ) {
        const redirectRoute = urlSearchParams.get('from');
        enqueueSnackbar(
          intl.formatMessage({
            id: 'signin.login.success',
          }),
          {
            variant: 'success',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          },
        );
        signIn(data.healthProfileSignIn.accessToken);
        history.push(redirectRoute ?? DEFAULT_HOME_ROUTE);
      } else if (data?.healthProfileSignIn?.certAccessKey) {
        window.location.href =
          REACT_APP_HEALTH_PROFILE_URL +
          '/#/choice-certificate/' +
          data.healthProfileSignIn.certAccessKey;
      }
    },
    onError: (error) => {
      signOut();
      handleError(error, [
        {
          code: ApolloErrorCode.INVALID_CREDENTIAL,
          message: intl.formatMessage({
            id: 'signin.login.error',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'error.message.unknow',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
      ]);
    },
  });

  const signInMutation = (
    username: string,
    password: string,
  ): Promise<FetchResult<SignIn>> => {
    return mutate({
      variables: { username, password },
    });
  };

  return {
    signInMutation,
    signInLoading,
    errorAlert,
    closeAlert,
  };
};
