import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import { Container } from '@material-ui/core';
import classnames from 'classnames';
import PageHeader from '../PageHeader';
import PageFooter from '../PageFooter';
import { useStyles, IStyleProps } from './styles';

export interface IProps extends IStyleProps {
  children?: React.ReactNode;
  className?: string;
}

const PageContainer: FC<IProps> = ({
  children,
  showBackground = false,
  className,
}) => {
  const classes = useStyles({ showBackground });
  return (
    <Box component="div" className={classes.root}>
      <PageHeader />
      <Container component="div" className={classes.container}>
        <Box component="div" className={classnames(classes.content, className)}>
          {children}
        </Box>
      </Container>
      <PageFooter />
    </Box>
  );
};

export default PageContainer;
