import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CovaxBrandingImage from '../../../assets/images/covax-branding-image.svg';
import EgmBrandingImage from '../../../assets/images/new-ugd-logo.svg';
import La2mBrandingImage from '../../../assets/images/la2mg.svg';

import { useStyles } from './styles';
import { EnumLocaleKeys } from '../../../modules/LocaleProvider/LocaleContext';
import FlagMgIcon from '../../../assets/images/flag-mg.svg';
import FlagFrIcon from '../../../assets/images/flag-fr.svg';
import ActionButton from '../../button/ActionButton';
import { useLocaleContext } from '../../../modules/LocaleProvider';

const PageFooter: FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const { locale, setLocale } = useLocaleContext();

  return (
    <Box component="div" className={classes.root}>
      <Container className={classes.container}>
        <Box className={classes.copyrightWrapper}>
          <div style={{ paddingRight: '10px' }}>
            <ActionButton
              color="default"
              style={{ maxWidth: 'max-content', minWidth: 'auto' }}
              classes={{ label: classes.languageLabel }}
              tooltip={
                <p>
                  {intl.formatMessage({
                    id:
                      EnumLocaleKeys.Francais === locale
                        ? 'common.language.mg.iso.tooltip'
                        : 'common.language.fr.iso.tooltip',
                  })}
                </p>
              }
              onClick={() =>
                setLocale(
                  EnumLocaleKeys.Francais === locale
                    ? EnumLocaleKeys.Malagasy
                    : EnumLocaleKeys.Francais,
                )
              }
            >
              <img
                src={
                  EnumLocaleKeys.Francais === locale ? FlagMgIcon : FlagFrIcon
                }
                className={classes.flagIcon}
              />
              {intl.formatMessage({
                id:
                  EnumLocaleKeys.Francais === locale
                    ? 'common.language.mg.iso'
                    : 'common.language.fr.iso',
              })}
            </ActionButton>
          </div>
          <Typography className={classes.copyright}>
            {intl.formatMessage({ id: 'common.page.footer.copyright' })}
          </Typography>
        </Box>
        <Box>
          <a href="https://digital.gov.mg" target="blank">
            <img
              className={classes.makerBrand}
              src={EgmBrandingImage}
              alt="UGD LOGO"
            />
          </a>
        </Box>
        <Box>
          <img
            className={classes.covaxBrand}
            src={CovaxBrandingImage}
            alt="COVAX UNIT LOGO"
          />
          <img
            className={classes.la2mBrand}
            src={La2mBrandingImage}
            alt="LA2M LOGO"
          />
        </Box>
      </Container>
    </Box>
  );
};

export default PageFooter;
