import React, { FC } from 'react';
import clsx from 'clsx';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';

import useCommonStyles from '../../styles';
import useStyles from './styles';

export interface ILabeledIconButtonProps extends Omit<ButtonProps, 'color'> {
  children: React.ReactChild;
  icon: React.ReactElement<any, any>;
  loading?: boolean;
  disabled?: boolean;
  color?: 'neutral' | 'primary' | 'secondary';
  CircularProgressProps?: Partial<CircularProgressProps>;
  className?: string;
  classes?: Partial<ReturnType<typeof useStyles>>;
}

const LabeledIconButton: FC<ILabeledIconButtonProps> = (props) => {
  const {
    children,
    icon,
    loading = false,
    disabled = false,
    color = 'neutral',
    CircularProgressProps = {},
    className,
    classes: _,
    ...otherProps
  } = props;

  const commonClasses = useCommonStyles();
  const classes = useStyles(props);

  return (
    <Button
      {...{
        variant: 'outlined',
        size: 'medium',
        disabled,
        startIcon: !loading ? (
          icon
        ) : (
          <CircularProgress
            {...{
              size: 15,
              thickness: 5,
              ...CircularProgressProps,
              className: clsx(classes.iconColor, {
                [classes.primaryIconColor]: color === 'primary',
                [classes.disabledIconColor]: disabled,
              }),
            }}
          />
        ),

        classes: {
          root: clsx(
            classes.root,
            {
              [classes.primary]: color === 'primary',
              [classes.secondary]: color === 'secondary',
            },
            className,
          ),
          label: classes.content,
          disabled: clsx(classes.disabled, {
            [classes.primaryDisabled]: color === 'primary',
          }),
          startIcon: clsx(classes.icon, classes.iconColor, {
            [classes.primaryIconColor]: color === 'primary',
            [classes.disabledIconColor]: disabled,
          }),
        },
        ...otherProps,
      }}
    >
      <Box
        {...{
          component: 'span',
          className: clsx(commonClasses.inlineLabel, classes.label, {
            [classes.primaryLabel]: color === 'primary',
            [classes.disabledLabel]: disabled,
          }),
        }}
      >
        {children}
      </Box>
    </Button>
  );
};

export default LabeledIconButton;
