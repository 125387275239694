import { createContext } from 'react';

type PageMetaContextType = {
  pathLabels: string[];
  setPathLabels: (pathLabels: string[]) => void;
};

export const PageMetaContext = createContext<PageMetaContextType>({
  pathLabels: [],
  setPathLabels: () => {},
});

export default PageMetaContext;
