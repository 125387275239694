import React from 'react';
import { FetchResult, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';

import { MY_PROFILE } from '../../authentication/query';
import { MyProfile } from '../../authentication/types/MyProfile';
import { useErrorHanlder } from '../../../modules/hooks';
import { ApolloErrorCode } from '../../../consts';

import { CITIZEN_EDIT_PROFILE } from '../mutation';
import {
  CitizenEditProfileMutation,
  CitizenEditProfileMutationVariables,
} from '../types/CitizenEditProfileMutation';
import { UpdateCitizenProfileInput } from '../../../types';

export const useCitizenEditProfileMutation = (
  options?: IMutationOptions,
): {
  updateCitizenProfileMutation: (
    profileInput: UpdateCitizenProfileInput,
  ) => Promise<FetchResult<CitizenEditProfileMutation>>;
  updateCitizenProfileLoading: boolean;
  successAlert?: string | null;
  errorAlert?: string | null;
  closeAlert: () => void;
} => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useErrorHanlder();
  const [successAlert, setSuccessAlert] = React.useState<string | null>();
  const [errorAlert, setErrorAlert] = React.useState<string | null>();

  const closeAlert = () => {
    setErrorAlert(null);
    setSuccessAlert(null);
  };

  const [mutate, { loading: updateCitizenProfileLoading }] = useMutation<
    CitizenEditProfileMutation,
    CitizenEditProfileMutationVariables
  >(CITIZEN_EDIT_PROFILE, {
    onCompleted: (data) => {
      if (data?.updateCitizenProfile?.citizen) {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'citizen.success.edit.message',
          }),
          {
            variant: 'success',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          },
        );
        setSuccessAlert(
          intl.formatMessage({
            id: 'citizen.success.edit.message',
          }),
        );
        options?.onSuccess?.();
      }
    },
    update: (cache, { data }) => {
      if (data && data?.updateCitizenProfile?.citizen) {
        const myData = cache.readQuery<MyProfile>({
          query: MY_PROFILE,
          variables: { citizenId: data.updateCitizenProfile.citizen.id },
        });

        if (myData && myData?.myProfile?.id) {
          cache.writeQuery<MyProfile>({
            query: MY_PROFILE,
            data: {
              ...myData,
              ...data?.updateCitizenProfile?.citizen,
            },
            variables: { citizenId: data.updateCitizenProfile.citizen.id },
          });
        }
      }
    },
    onError: (error) => {
      handleError(error, [
        {
          code: ApolloErrorCode.CITIZEN_EXISTS,
          message: intl.formatMessage({
            id: 'citizen.error.exists.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'error.loading.label',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.NON_AUTHORIZED_OPERATION,
          message: intl.formatMessage({
            id: 'error.message.forbidden',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.HEALTH_REGISTRATION_FAILED,
          message: intl.formatMessage({
            id: 'citizen.error.health.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.JOB_REGISTRATION_FAILED,
          message: intl.formatMessage({
            id: 'citizen.error.job.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.ADDRESS_REGISTRATION_FAILED,
          message: intl.formatMessage({
            id: 'citizen.error.address.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
      ]);
    },
  });

  const updateCitizenProfileMutation = (
    profileInput: UpdateCitizenProfileInput,
  ): Promise<FetchResult<CitizenEditProfileMutation>> => {
    closeAlert();
    return mutate({
      variables: {
        input: {
          id: profileInput.id,
          birthDate: format(new Date(profileInput.birthDate), 'yyyy-MM-dd'),
          cniNum: profileInput.cniNum,
          lastPcrTest: profileInput.lastPcrTest,
          municipalityId: profileInput.municipalityId,
          fokontanyId: profileInput.fokontanyId,
          addressName: profileInput.addressName,
          jobId: profileInput.jobId,
          jobName: profileInput.jobName,
          offRegNum: profileInput.offRegNum,
          profRegNum: profileInput.profRegNum,
          employerName: profileInput.employerName,
          comorbidityId: profileInput.comorbidityId,
          comorbidityName: profileInput.comorbidityName,
          desiredVaccineId: profileInput.desiredVaccineId,
          healthCenterId: profileInput.healthCenterId,
        },
      },
    });
  };

  return {
    successAlert,
    errorAlert,
    closeAlert,
    updateCitizenProfileMutation,
    updateCitizenProfileLoading,
  };
};
