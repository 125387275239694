import { gql } from '@apollo/client';

export const COMORBIDITY_INFO_FRAGMENT = gql`
  fragment ComorbidityInfoFragment on Comorbidity {
    id
    name
    nameMg
    other
  }
`;

export const HEALTHCENTER_INFO_FRAGMENT = gql`
  fragment HealthCenterInfoFragment on HealthCenter {
    id
    municipalityId
    name
  }
`;
