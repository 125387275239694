import { FormikHelpers, useFormik as useBaseFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { SignInVariables } from '../../graphql/authentication/types/SignIn';

interface Config {
  onSubmit: (
    values: SignInVariables,
    formikHelpers: FormikHelpers<SignInVariables>,
  ) => void;
}

export const useFormik = (config: Config) => {
  const intl = useIntl();

  const validationSchema = yup.object({
    password: yup
      .string()
      .min(
        4,
        intl.formatMessage({
          id: 'signin.password.error.min.message',
        }),
      )
      .max(
        32,
        intl.formatMessage({
          id: 'signin.password.error.max.message',
        }),
      )
      .required(
        intl.formatMessage({
          id: 'signin.password.error.required.message',
        }),
      ),
    username: yup
      .string()
      .min(
        2,
        intl.formatMessage({
          id: 'signin.login.error.min.message',
        }),
      )
      .max(
        32,
        intl.formatMessage({
          id: 'signin.login.error.max.message',
        }),
      )
      .required(
        intl.formatMessage({
          id: 'signin.login.error.required.message',
        }),
      ),
  });

  const formik = useBaseFormik<SignInVariables>({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: validationSchema,
    ...config,
  });

  return formik;
};
