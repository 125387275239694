import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  container: {
    // padding: theme.spacing(3),
    position: 'relative',
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  medium: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  large: {
    width: theme.spacing(24),
    height: theme.spacing(24),
  },
  avatarButton: {
    borderRadius: '50%',
  },
  editButtonContainer: {
    width: '100%',
    height: '100%',
    top: theme.spacing(0),
    right: theme.spacing(0),
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiIconButton-root': {
      padding: theme.spacing(1),
      backgroundColor: theme.palette.action.focus,
      opacity: 0,
      '&:hover': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.action.active,
        opacity: 1,
      },
    },
  },
}));

export default styles;
