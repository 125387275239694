import { FormikHelpers, useFormik as useBaseFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

interface ForgotPasswordVariables {
  username: string;
}

interface Config {
  onSubmit: (
    values: ForgotPasswordVariables,
    formikHelpers: FormikHelpers<ForgotPasswordVariables>,
  ) => void;
}

export const useFormik = (config: Config) => {
  const intl = useIntl();

  const validationSchema = yup.object({
    username: yup
      .string()
      .matches(
        /^[0-9ABCDEFabcdef]{12}|^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-_.]+\.[a-zA-Z0-9-]*$/,
        intl.formatMessage({
          id: 'forgotpassword.username.invalid.message',
        }),
      )
      // .length(
      //   12,
      //   intl.formatMessage({
      //     id: 'forgotpassword.username.invalid.message',
      //   }),
      // )
      .required(
        intl.formatMessage({
          id: 'forgotpassword.username.required.message',
        }),
      ),
  });

  const formik = useBaseFormik<ForgotPasswordVariables>({
    initialValues: {
      username: '',
    },
    validationSchema: validationSchema,
    ...config,
  });

  return formik;
};
