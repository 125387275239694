import React from 'react';
import { useMutation, FetchResult } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

import { useErrorHanlder } from '../../../modules/hooks';
import { ApolloErrorCode } from '../../../consts';
import { USER_FORGOT_PASSWORD } from '../mutation';
import {
  HealthProfileForgotPassword,
  HealthProfileForgotPasswordVariables,
  HealthProfileForgotPassword_healthProfileForgotPassword,
} from '../types/HealthProfileForgotPassword';

export const useForgotPassword = (
  options?: IMutationOptions<HealthProfileForgotPassword_healthProfileForgotPassword>,
): {
  forgotPasswordMutation: (
    username: string,
  ) => Promise<FetchResult<HealthProfileForgotPassword>>;
  forgotPasswordLoading: boolean;
  errorAlert?: string | null;
  closeAlert: () => void;
} => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useErrorHanlder();

  const [errorAlert, setErrorAlert] = React.useState<string | null>();

  const closeAlert = () => {
    setErrorAlert(null);
  };

  const [mutate, { loading: forgotPasswordLoading }] = useMutation<
    HealthProfileForgotPassword,
    HealthProfileForgotPasswordVariables
  >(USER_FORGOT_PASSWORD, {
    onCompleted: (data) => {
      if (data?.healthProfileForgotPassword?.resetToken) {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'forgotpassword.ack.title.label',
          }),
          {
            variant: 'success',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          },
        );
        options?.onSuccess?.(data.healthProfileForgotPassword);
      }
    },
    onError: (error) => {
      handleError(error, [
        {
          code: ApolloErrorCode.INVALID_CREDENTIAL,
          message: intl.formatMessage({
            id: 'forgotpassword.login.invalid.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.INVALID_EMAIL_ADDRESS,
          message: intl.formatMessage({
            id: 'forgotpassword.email.not.trusted.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'error.message.unknow',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
      ]);
    },
  });

  const forgotPasswordMutation = (
    username: string,
  ): Promise<FetchResult<HealthProfileForgotPassword>> => {
    return mutate({
      variables: { username },
    });
  };

  return {
    forgotPasswordMutation,
    forgotPasswordLoading,
    errorAlert,
    closeAlert,
  };
};
