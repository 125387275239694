import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { ApolloErrorCode } from '../../../consts';
import { useErrorHanlder } from '../../../modules/hooks';

import { GET_ALL_ADDRESS } from '../query';
import {
  GetAllAddress,
  GetAllAddressVariables,
  GetAllAddress_addresses,
} from '../types/GetAllAddress';

export const useAllAddress = (
  fokontanyId?: string | null,
  skip?: boolean,
): {
  isLoading: boolean;
  addresses: GetAllAddress_addresses[];
} => {
  // Error handler
  const handleError = useErrorHanlder();

  // Intl
  const intl = useIntl();

  // Graphql query
  const { data, loading } = useQuery<GetAllAddress, GetAllAddressVariables>(
    GET_ALL_ADDRESS,
    {
      variables: { fokontanyId: fokontanyId ?? '' },
      skip: !fokontanyId || skip,
      onError: (error) => {
        handleError(error, [
          {
            code: ApolloErrorCode.UKNOWN_ERROR,
            message: intl.formatMessage({
              id: 'error.loading.label',
            }),
          },
        ]);
      },
      fetchPolicy: 'cache-first',
    },
  );

  return {
    isLoading: loading,
    addresses: data?.addresses ?? [],
  };
};
