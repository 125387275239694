import { gql } from '@apollo/client';
import { CITIZEN_INFO_FRAGMENT } from './fragment';

// Fetch citizen's profile info by access key
export const CITIZEN_VACCINATION_PROFILE = gql`
  query CitizenVaccinationProfile($accessKey: String!) {
    citizenProfile(accessKey: $accessKey) {
      ...AllCitizenInfoFragment
    }
  }
  ${CITIZEN_INFO_FRAGMENT}
`;
