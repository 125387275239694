import React, { FC, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useIntl } from 'react-intl';
import { Alert } from '@material-ui/lab';
import { Helmet } from 'react-helmet';
import { UpdateCitizenProfileInput } from '../../types';
import PageContainer from '../../components/page/PageContainer';
import { useCitizenEditProfileMutation } from '../../graphql/citizen/hooks';
import { useMyProfile } from '../../graphql/authentication/hooks';
import InfiniteLoading from '../../components/InfiniteLoading';
import { useAuthContext } from '../../modules/AuthProvider';
import { RegistrationStep } from '../../consts';
import useStyles from './styles';
import { useFormik } from './formik';
import CitizenInfoForm from './CitizenInfoForm';
import CitizenJobForm from './CitizenJobForm';
import CitizenHealthForm from './CitizenHealthForm';
import MainProfile from './MainProfile';

const Profile: FC = () => {
  const intl = useIntl();

  const [currentForm, setCurrentForm] = React.useState<RegistrationStep>();

  const classes = useStyles();

  const { currentUser } = useAuthContext();

  const initialValues = {
    id: currentUser?.id,
    municipalityId: currentUser?.municipality.id,
    fokontanyId: currentUser?.fokontany.id,
    addressName: currentUser?.address?.name,
    jobId: currentUser?.job?.jobId,
    employerName: currentUser?.job?.employer?.name,
    healthCenterId: currentUser?.health?.healthCenterId,
    lastPcrTest: currentUser?.health?.lastPcrTest,
    birthDate: currentUser?.birthDate,
    gender: currentUser?.gender,
    cniNum: currentUser?.cniNum,
    offRegNum: currentUser?.job?.offRegNum,
    profRegNum: currentUser?.job?.profRegNum,
    jobName: currentUser?.job?.otherJob?.name,
    comorbidityId: currentUser?.health?.comorbidityId,
    comorbidityName: currentUser?.health?.otherComorbidity?.name,
    desiredVaccineId: currentUser?.health?.desiredVaccineId,
  };

  const {
    updateCitizenProfileLoading,
    updateCitizenProfileMutation,
    closeAlert: createCloseAlert,
    successAlert: createSuccessAlert,
    errorAlert: createErrorAlert,
  } = useCitizenEditProfileMutation({
    onSuccess: () => {
      setCurrentForm(undefined);
    },
  });

  // Of formik as form state handler
  const formik = useFormik({
    initialValues,
    onSubmit: async (values, helper) => {
      updateCitizenProfileMutation(values as UpdateCitizenProfileInput);
    },
  });

  // Chech is current citizen is vaccined
  const isVaccined = currentUser?.health?.vaccineEvents
    ? currentUser?.health?.vaccineEvents?.length > 1
      ? true
      : false
    : false;

  // Hanle edit form change
  const handleCurrentEditableFormChange = (
    newSelectedForm: RegistrationStep,
  ) => {
    setCurrentForm((lastSelectedForm) =>
      lastSelectedForm !== newSelectedForm ? newSelectedForm : undefined,
    );
    // Reset the form
    formik.setValues(initialValues);
  };

  const isLoading = updateCitizenProfileLoading;
  const successAlert = createSuccessAlert;
  const errorAlert = createErrorAlert;
  const closeAlert = createCloseAlert;

  return (
    <form
      className={classes.form}
      onSubmit={formik.handleSubmit}
      autoComplete="off"
    >
      <Helmet titleTemplate="%s" defer={false}>
        <title>
          {intl.formatMessage({ id: 'common.health.profile.label' })}
        </title>
      </Helmet>
      <Grid container xs={12}>
        <Grid item xs={12}>
          {(successAlert || errorAlert) && (
            <Box component="div" className={classes.alertContainer}>
              <Alert
                severity={successAlert ? 'success' : 'error'}
                onClose={() => closeAlert()}
              >
                {successAlert ? successAlert : errorAlert}
              </Alert>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container xs={12} direction="column">
        <MainProfile formik={formik} isLoading={isLoading} />
        <CitizenInfoForm
          formik={formik}
          isLoading={isLoading}
          disabled={currentForm !== RegistrationStep.REGISTRATION_PERSONAL_INFO}
          isVaccined={isVaccined}
          onToggleEditor={() =>
            handleCurrentEditableFormChange(
              RegistrationStep.REGISTRATION_PERSONAL_INFO,
            )
          }
        />
        <CitizenJobForm
          formik={formik}
          isLoading={isLoading}
          disabled={currentForm !== RegistrationStep.REGISTRATION_JOB_INFO}
          isVaccined={isVaccined}
          onToggleEditor={() =>
            handleCurrentEditableFormChange(
              RegistrationStep.REGISTRATION_JOB_INFO,
            )
          }
        />
        <CitizenHealthForm
          formik={formik}
          isLoading={isLoading}
          disabled={currentForm !== RegistrationStep.REGISTRATION_HEALTH_INFO}
          isVaccined={isVaccined}
          onToggleEditor={() =>
            handleCurrentEditableFormChange(
              RegistrationStep.REGISTRATION_HEALTH_INFO,
            )
          }
        />
      </Grid>
    </form>
  );
};

const ProfileWrapper: FC = () => {
  const classes = useStyles();
  const { isLoading: isLoadingProfile } = useMyProfile();
  const { currentUser } = useAuthContext();
  return (
    <PageContainer showBackground={true}>
      <Box component="div" className={classes.root}>
        {isLoadingProfile || !currentUser ? <InfiniteLoading /> : <Profile />}
      </Box>
    </PageContainer>
  );
};

export default ProfileWrapper;
