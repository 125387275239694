import { makeStyles } from '@material-ui/core/styles';
import GovBrandPatternImage from '../../../assets/images/gov-mg-brand-original-pattern.png';

const MAX_LARGE_TIPS_WIDTH = 1600;

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3, 0, 3, 0),
    // background: `url(${GovBrandPatternImage}) no-repeat calc(100% + 271px) center / 542px 521px`,
    [theme.breakpoints.down(theme.screenbreakpoint.large + 1)]: {
      padding: theme.spacing(2, 0, 2, 0),
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      paddingTop: theme.spacing(5),
    },
  },
  tips: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(25),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    [theme.breakpoints.down(theme.screenbreakpoint.xlarge + 1)]: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(0),
    },
    [theme.breakpoints.down(theme.screenbreakpoint.large + 1)]: {
      marginTop: theme.spacing(4),
      marginLeft: theme.spacing(0),
    },
  },
  hintMessage: {
    flexBasis: '100%',
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.82px',
    color: theme.colors.text.black,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone + 134)]: {
      textAlign: 'center',
    },
  },
  hideOnSmartphone: {
    display: 'block',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone + 134)]: {
      display: 'none',
    },
  },
  hideOnTablet: {
    display: 'block',
    [theme.breakpoints.down(theme.screenbreakpoint.tablet + 340)]: {
      display: 'none',
    },
  },
  hideOnLargeScreen: {
    display: 'block',
    [theme.breakpoints.up(theme.screenbreakpoint.tablet + 340)]: {
      display: 'none',
    },
  },
}));
