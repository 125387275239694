import { makeStyles } from '@material-ui/core/styles';
// import bgImage from '../../assets/images/bg-vakisiny.jpeg';

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    marginBottom: theme.spacing(4),
  },
  paper: {
    maxWidth: theme.spacing(116),
    borderRadius: 8,
    boxShadow: '0px 4px 80px rgba(0, 0, 0, 0.08)',
  },
  fullWithPaper: {
    maxWidth: theme.spacing(169),
  },
  content: {
    padding: theme.spacing(5, 3, 5, 5),
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      padding: theme.spacing(3, 1, 3, 3),
    },
  },
  tipsItem: {
    paddingRight: theme.spacing(8),
  },
  form: {
    padding: theme.spacing(0, 0, 0, 0),
    '& .MuiGrid-item': {
      padding: theme.spacing(1, 2, 1, 2),
    },
  },
  formTitle: {
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '0.82px',
    color: theme.colors.text.black02,
    padding: theme.spacing(0, 0, 2, 0),
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
  alertContainer: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  actionButton: {
    minWidth: 'auto',
    marginLeft: 'auto',
    width: theme.spacing(21),
    margin: 0,
    [theme.breakpoints.down(theme.screenbreakpoint.tablet)]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      width: '100%',
    },
  },
  titleContainer: {
    color: theme.palette.secondary.main,
    marginBottom: 18,
  },
  signLink: {
    textDecoration: 'none',
  },
  requiredHint: {
    flexGrow: 1,
    color: theme.colors.text.lightPurple,
  },
  actionButtonWrapper: {
    [theme.breakpoints.down(theme.screenbreakpoint.tablet)]: {
      flexDirection: 'column-reverse',
    },
  },
}));

export default styles;
