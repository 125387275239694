import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    marginBottom: theme.spacing(4),
  },

  paper: {
    maxWidth: theme.spacing(112),
    borderRadius: 8,
    boxShadow: '0px 4px 80px rgba(0, 0, 0, 0.08)',
    marginTop: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(4),
    justifyContent: 'center',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      padding: theme.spacing(3),
    },
  },
  mainProfile: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      flexDirection: 'column',
    },
  },
  mainProfilePicture: {
    display: 'flex',
    flexDirection: 'row',
  },
  mainProfileInfo: {
    flexGrow: 1,
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      padding: theme.spacing(3, 0, 0, 0),
    },
  },
  mainProfileName: {
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '45px',
    color: theme.colors.text.black02,
    paddingBottom: theme.spacing(2),
  },
  mainProfileAttr: {
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    color: theme.colors.text.black02,
    paddingBottom: theme.spacing(1),
  },
  vaccineDoseWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
  },
  vaccineDoseItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  vaccineDoseIcon: {
    height: '32px',
    width: '32px',
    color: theme.colors.text.grey,
  },
  vaccineDoseIconChecked: {
    color: theme.button.primary,
  },
  vaccineDoseLabel: {
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '25px',
    color: theme.colors.text.black,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      paddingRight: theme.spacing(2),
    },
  },
  tipsItem: {
    paddingRight: theme.spacing(8),
  },
  form: {
    padding: theme.spacing(0, 0, 0, 0),
    '& .MuiGrid-item': {
      padding: theme.spacing(1, 2, 1, 2),
    },
  },
  formTitle: {
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '0.82px',
    color: theme.colors.text.black02,
    padding: theme.spacing(0, 0, 2, 0),
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
  alertContainer: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  actionButton: {
    minWidth: 'auto',
    marginLeft: 'auto',
    width: theme.spacing(21),
    marginRight: theme.spacing(2),
    margin: 0,
    [theme.breakpoints.down(theme.screenbreakpoint.tablet)]: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      width: '100%',
    },
  },
  titleContainer: {
    color: theme.palette.secondary.main,
    marginBottom: 18,
  },
  signLink: {
    textDecoration: 'none',
  },
  requiredHint: {
    flexGrow: 1,
    color: theme.colors.text.lightPurple,
  },
  actionButtonWrapper: {
    [theme.breakpoints.down(theme.screenbreakpoint.tablet)]: {
      flexDirection: 'column-reverse',
    },
  },
  bottomaActionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      justifyContent: 'center',
    },
  },
  topActionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(0, 2, 3, 2),
    },
  },
}));

export default styles;
