import {
  FormikErrors,
  FormikHelpers,
  useFormik as useBaseFormik,
} from 'formik';
import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { UpdateCitizenProfileInput } from '../../types';

type CitizenProfileUpdateField = Partial<UpdateCitizenProfileInput>;
export type CitizenProfileUpdateFieldError =
  FormikErrors<UpdateCitizenProfileInput>;

interface Config {
  initialValues: CitizenProfileUpdateField;
  onSubmit: (
    values: CitizenProfileUpdateField,
    formikHelpers: FormikHelpers<CitizenProfileUpdateField>,
  ) => void;
}

export const useFormik = (config: Config) => {
  const intl = useIntl();

  const validationSchema = yup.object({
    municipalityId: yup.string().required(
      intl.formatMessage({
        id: 'error.helper.required',
      }),
    ),
    fokontanyId: yup.string().required(
      intl.formatMessage({
        id: 'error.helper.required',
      }),
    ),

    jobId: yup.string().required(
      intl.formatMessage({
        id: 'error.helper.required',
      }),
    ),
  });

  const formik = useBaseFormik<CitizenProfileUpdateField>({
    validationSchema: validationSchema,
    validateOnChange: true,
    validate: (values: CitizenProfileUpdateField) => {
      const errors: FormikErrors<CitizenProfileUpdateField> = {};

      if (
        !values.addressName ||
        (values.addressName && values.addressName.trim().length === 0)
      ) {
        errors.addressName = intl.formatMessage({
          id: 'error.helper.required',
        });
      }

      if (!values.birthDate) {
        errors.birthDate = intl.formatMessage({
          id: 'error.helper.required',
        });
      }

      if (values.birthDate && values.birthDate.toString() === 'Invalid Date') {
        errors.birthDate = intl.formatMessage({
          id: 'common.editor.date.invalid.message',
        });
      }

      return errors;
    },
    ...config,
  });

  return formik;
};
