import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './styles';

export interface IProps {
  label?: string;
}

const InfiniteLoading: FC<IProps> = ({ label }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <CircularProgress />
      {label && <Typography className={classes.label}>{label}</Typography>}
    </Box>
  );
};

export default InfiniteLoading;
