import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { ApolloErrorCode } from '../../../consts';
import { useErrorHanlder } from '../../../modules/hooks';

import { GET_ALL_MUNICIPALITY } from '../query';
import {
  GetAllMunicipality,
  GetAllMunicipality_municipalities,
} from '../types/GetAllMunicipality';

export const useAllMunicipality = (
  skip?: boolean,
): {
  isLoading: boolean;
  municipalities: GetAllMunicipality_municipalities[];
} => {
  // Error handler
  const handleError = useErrorHanlder();

  // Intl
  const intl = useIntl();

  // Graphql query
  const { data, loading } = useQuery<GetAllMunicipality>(GET_ALL_MUNICIPALITY, {
    skip: skip,
    onError: (error) => {
      handleError(error, [
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'error.loading.label',
          }),
        },
      ]);
    },
    fetchPolicy: 'cache-first',
  });

  return {
    isLoading: loading,
    municipalities: data?.municipalities ?? [],
  };
};
