import React, { FC, ChangeEvent, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import LabeledIconButton from '../../../components/button/LabeledIconButton';
import ActionButton from '../../../components/button/ActionButton';
import {
  useAllComorbidity,
  useAllHealthCenter,
} from '../../../graphql/health/hooks';
import RadioInputField from '../../../components/inputfields/RadioInputField';
import AutoCompleteField from '../../../components/inputfields/AutoCompleteField';
import { useAllVaccineMeta } from '../../../graphql/vaccine_meta/hooks';
import { useFormik } from '../formik';
import useStyles from '../styles';
import { OTHER_COMORBIDITY_ID } from '../../../consts';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';

interface ICitizenHealthFormProps {
  isLoading?: boolean;
  formik: ReturnType<typeof useFormik>;
  disabled?: boolean;
  isVaccined?: boolean;
  onToggleEditor: () => void;
}

const CitizenHealthForm: FC<ICitizenHealthFormProps> = ({
  isLoading: isParentFormLoading,
  formik,
  disabled = true,
  isVaccined,
  onToggleEditor,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { comorbidities, isLoading: isLoadingComorbidity } =
    useAllComorbidity(false);
  const { healthCenters, isLoading: isLoadingHealthCenter } =
    useAllHealthCenter(formik.values.municipalityId);

  const {
    comorbidities: otherComorbidities,
    isLoading: isLoadingOtherComorbidity,
  } = useAllComorbidity(true, !comorbidities.length);

  const { vaccines, isLoading: isLoadingVaccines } = useAllVaccineMeta();

  const isLoading =
    isParentFormLoading ||
    isLoadingComorbidity ||
    isLoadingOtherComorbidity ||
    isLoadingVaccines ||
    isLoadingHealthCenter;

  return (
    <Paper className={classes.paper}>
      <Box component="div" className={classes.content}>
        <Box component="div" className={classes.topActionBar}>
          <Typography className={classes.formTitle}>
            <FormattedMessage id="profile.healthinfo.title.label" />
          </Typography>
          {isVaccined && (
            <LabeledIconButton
              {...{
                color: 'secondary',
                icon: disabled ? <EditIcon /> : <ClearIcon />,
                onClick: onToggleEditor,
              }}
            >
              <FormattedMessage
                id={
                  disabled
                    ? 'userprofile.edit.label'
                    : 'userprofile.cancel.edit.label'
                }
              />
            </LabeledIconButton>
          )}
        </Box>

        <Grid container xs={12}>
          <Grid item md={12} xs={12}>
            <RadioInputField
              aria-label="comorbidity"
              name="comorbidityId"
              disabled={disabled || isLoading}
              label={intl.formatMessage({
                id: 'citizen.health.comorbidity.label',
              })}
              value={formik.values.comorbidityId}
              onChange={(event, newValue) =>
                formik.handleChange('comorbidityId')(newValue)
              }
              options={comorbidities}
              getOptionKey={(item) => item.id}
              getOptionLabel={(item) => item.name}
              error={Boolean(formik.errors.comorbidityId)}
              helperText={formik.errors.comorbidityId}
            />
          </Grid>
        </Grid>
        {OTHER_COMORBIDITY_ID === formik.values.comorbidityId && (
          <Grid item md={12} xs={12}>
            <AutoCompleteField
              id="comorbidity-other-picker"
              name="comorbidityName"
              freeSolo={true}
              required={true}
              disabled={disabled || isLoading}
              defaultValue={formik.values.comorbidityName ?? ''}
              items={otherComorbidities.map(
                (comorbidityItm) => comorbidityItm.name,
              )}
              label={intl.formatMessage({
                id: 'citizen.comorbidity.category.other.label',
              })}
              placeholder={intl.formatMessage({
                id: 'citizen.comorbidity.category.other.placeholder.label',
              })}
              getItemKey={(item) => item ?? ''}
              getItemLabel={(item) => item ?? ''}
              onSelectionChange={(selectedVal) => {
                formik.handleChange('comorbidityName')(selectedVal ?? '');
              }}
            />
          </Grid>
        )}
        <Grid item md={12} xs={12}>
          <AutoCompleteField
            id="healthcenter-picker"
            name="healthCenterId"
            required={true}
            disabled={disabled || isLoading}
            defaultValue={formik.values.healthCenterId ?? ''}
            items={healthCenters}
            label={intl.formatMessage({
              id: 'citizen.health.facility.label',
            })}
            getItemKey={(item) => `${item?.id}`}
            getItemLabel={(item) => `${item?.name ?? ''}`}
            onSelectionChange={(selectedVal) => {
              formik.handleChange('healthCenterId')(selectedVal ?? '');
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.healthCenterId &&
              Boolean(formik.errors.healthCenterId)
            }
            helperText={
              formik.touched.healthCenterId && formik.errors.healthCenterId
            }
            textFieldProps={{
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalHospitalIcon />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <AutoCompleteField
            id="desired-vaccine-id-picker"
            name="desiredVaccineId"
            required={true}
            disabled={disabled || isLoading}
            defaultValue={formik.values.desiredVaccineId ?? ''}
            items={vaccines}
            label={intl.formatMessage({
              id: 'citizen.health.desired.vaccine.label',
            })}
            getItemKey={(item) => `${item?.id}`}
            getItemLabel={(item) => `${item?.name ?? ''}`}
            onSelectionChange={(selectedVal) => {
              formik.handleChange('desiredVaccineId')(selectedVal ?? '');
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.desiredVaccineId &&
              Boolean(formik.errors.desiredVaccineId)
            }
            helperText={
              formik.touched.desiredVaccineId && formik.errors.desiredVaccineId
            }
            textFieldProps={{
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <LocalHospitalIcon />
                  </InputAdornment>
                ),
              },
            }}
          />
        </Grid>
        {isVaccined && (
          <Box component="div" className={classes.bottomaActionBar}>
            <ActionButton
              loading={!disabled && isLoading}
              color="primary"
              type="submit"
              disabled={disabled || !formik.isValid}
              className={classes.actionButton}
            >
              <FormattedMessage id={'register.button.submit.label'} />
            </ActionButton>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default CitizenHealthForm;
