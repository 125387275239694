import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { useIntl } from 'react-intl';
import InputAdornment from '@material-ui/core/InputAdornment';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AutoCompleteField from '../../../components/inputfields/AutoCompleteField';
import { useFormik } from '../formik';
import {
  useAllComorbidity,
  useAllHealthCenter,
} from '../../../graphql/health/hooks';
import { useAllVaccineMeta } from '../../../graphql/vaccine_meta/hooks';
import RadioInputField from '../../../components/inputfields/RadioInputField';
import {
  OTHER_COMORBIDITY_ID,
  OTHER_DEFAULT_COMORBIDITY_ID,
} from '../../../consts';

interface ICitizenHealthFormProps {
  isLoading?: boolean;
  formik: ReturnType<typeof useFormik>;
}

const CitizenHealthForm: FC<ICitizenHealthFormProps> = ({
  isLoading: isParentFormLoading,
  formik,
}) => {
  const intl = useIntl();

  const { comorbidities, isLoading: isLoadingComorbidity } =
    useAllComorbidity(false);

  const { vaccines, isLoading: isLoadingVaccines } = useAllVaccineMeta();

  const { healthCenters, isLoading: isLoadingHealthCenter } =
    useAllHealthCenter(formik.values.municipalityId);
  const {
    comorbidities: otherComorbidities,
    isLoading: isLoadingOtherComorbidity,
  } = useAllComorbidity(true, !comorbidities.length);

  const isLoading =
    isParentFormLoading ||
    isLoadingComorbidity ||
    isLoadingHealthCenter ||
    isLoadingOtherComorbidity ||
    isLoadingVaccines;

  return (
    <>
      <Grid item md={12} xs={12}>
        <RadioInputField
          aria-label="comorbidity"
          name="comorbidityId"
          disabled={isLoading}
          label={intl.formatMessage({
            id: 'citizen.health.comorbidity.label',
          })}
          value={formik.values.comorbidityId}
          defaultValue={OTHER_DEFAULT_COMORBIDITY_ID}
          onChange={(event, newValue) =>
            formik.handleChange('comorbidityId')(newValue)
          }
          options={comorbidities}
          getOptionKey={(item) => item.id}
          getOptionLabel={(item) => item.name}
          error={Boolean(formik.errors.comorbidityId)}
          helperText={formik.errors.comorbidityId}
        />
      </Grid>
      {OTHER_COMORBIDITY_ID === formik.values.comorbidityId && (
        <Grid item md={12} xs={12}>
          <AutoCompleteField
            id="comorbidity-other-picker"
            name="comorbidityName"
            freeSolo={true}
            required={true}
            isLoading={isLoading}
            defaultValue={formik.values.comorbidityName ?? ''}
            items={otherComorbidities.map(
              (comorbidityItm) => comorbidityItm.name,
            )}
            label={intl.formatMessage({
              id: 'citizen.comorbidity.category.other.label',
            })}
            placeholder={intl.formatMessage({
              id: 'citizen.comorbidity.category.other.placeholder.label',
            })}
            getItemKey={(item) => item ?? ''}
            getItemLabel={(item) => item ?? ''}
            onSelectionChange={(selectedVal) => {
              formik.handleChange('comorbidityName')(selectedVal ?? '');
            }}
          />
        </Grid>
      )}
      <Grid item md={12} xs={12}>
        <AutoCompleteField
          id="healthcenter-picker"
          name="healthCenterId"
          required={true}
          isLoading={isLoading}
          defaultValue={formik.values.healthCenterId ?? ''}
          items={healthCenters}
          label={intl.formatMessage({
            id: 'citizen.health.facility.label',
          })}
          getItemKey={(item) => `${item?.id}`}
          getItemLabel={(item) => `${item?.name ?? ''}`}
          onSelectionChange={(selectedVal) => {
            formik.handleChange('healthCenterId')(selectedVal ?? '');
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.healthCenterId &&
            Boolean(formik.errors.healthCenterId)
          }
          helperText={
            formik.touched.healthCenterId && formik.errors.healthCenterId
          }
          textFieldProps={{
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <LocalHospitalIcon />
                </InputAdornment>
              ),
            },
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <AutoCompleteField
          id="desired-vaccine-id-picker"
          name="desiredVaccineId"
          isLoading={isLoading}
          defaultValue={formik.values.desiredVaccineId ?? ''}
          items={vaccines}
          label={intl.formatMessage({
            id: 'citizen.health.desired.vaccine.label',
          })}
          getItemKey={(item) => `${item?.id}`}
          getItemLabel={(item) => `${item?.name ?? ''}`}
          onSelectionChange={(selectedVal) => {
            formik.handleChange('desiredVaccineId')(selectedVal ?? '');
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.desiredVaccineId &&
            Boolean(formik.errors.desiredVaccineId)
          }
          helperText={
            formik.touched.desiredVaccineId && formik.errors.desiredVaccineId
          }
          textFieldProps={{
            InputProps: {
              startAdornment: (
                <InputAdornment position="start">
                  <LocalHospitalIcon />
                </InputAdornment>
              ),
            },
          }}
        />
      </Grid>
    </>
  );
};

export default CitizenHealthForm;
