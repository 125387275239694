import React, { FC } from 'react';
import ActionButton from '../ActionButton';
import { IProps as ActionButtonProps } from '../ActionButton/ActionButton';

const ConfirmButton: FC<ActionButtonProps> = ({
  children,
  color = 'primary',
  ...otherProps
}) => <ActionButton {...{ color, ...otherProps }}>{children}</ActionButton>;

export default ConfirmButton;
