import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { ApolloErrorCode } from '../../../consts';
import { useErrorHanlder } from '../../../modules/hooks';

import { GET_ALL_REGION } from '../query';
import { GetAllRegion, GetAllRegion_regions } from '../types/GetAllRegion';

export const useAllRegion = (
  skip?: boolean,
): {
  isLoading: boolean;
  regions: GetAllRegion_regions[];
} => {
  // Error handler
  const handleError = useErrorHanlder();

  // Intl
  const intl = useIntl();

  // Graphql query
  const { data, loading } = useQuery<GetAllRegion>(GET_ALL_REGION, {
    skip: skip,
    onError: (error) => {
      handleError(error, [
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'error.loading.label',
          }),
        },
      ]);
    },
    fetchPolicy: 'cache-first',
  });

  return {
    isLoading: loading,
    regions: data?.regions ?? [],
  };
};
