import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { ApolloErrorCode } from '../../../consts';
import { useErrorHanlder } from '../../../modules/hooks';

import { GET_ALL_EMPLOYERENTITY } from '../query';
import {
  GetAllEmployerEntity,
  GetAllEmployerEntityVariables,
  GetAllEmployerEntity_employerEntities,
} from '../types/GetAllEmployerEntity';

export const useAllEmployerEntity = (
  municipalityId?: string | null,
  skip?: boolean,
): {
  isLoading: boolean;
  employerEntities: GetAllEmployerEntity_employerEntities[];
} => {
  // Error handler
  const handleError = useErrorHanlder();

  // Intl
  const intl = useIntl();

  // Graphql query
  const { data, loading } = useQuery<
    GetAllEmployerEntity,
    GetAllEmployerEntityVariables
  >(GET_ALL_EMPLOYERENTITY, {
    variables: { municipalityId: municipalityId ?? '' },
    skip: !municipalityId || skip,
    onError: (error) => {
      handleError(error, [
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'error.loading.label',
          }),
        },
      ]);
    },
  });

  return {
    isLoading: loading,
    employerEntities: data?.employerEntities ?? [],
  };
};
