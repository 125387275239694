import React, { FC } from 'react';
import { InputLabel, TextField, TextFieldProps, Box } from '@material-ui/core';
import { useStyles } from './styles';

type ITextInputFieldProps = Omit<TextFieldProps, 'variant'> & {
  id?: string;
};

const TextInputField: FC<ITextInputFieldProps> = ({
  label,
  ...otherProps
}): React.ReactElement => {
  const classes = useStyles();
  return (
    <Box component="div">
      <InputLabel
        disableAnimation={true}
        className={classes.inputLabelRoot}
        required={otherProps.required}
      >
        {label}
      </InputLabel>
      <TextField
        color="secondary"
        variant="outlined"
        className={classes.textFieldroot}
        {...otherProps}
      />
    </Box>
  );
};

export default TextInputField;
