import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dialogTitleText: {
    minWidth: 'min-content',
    minHeight: 'min-content',
    whiteSpace: 'nowrap',
    color: theme.palette.primary.dark,
    fontSize: '1.3125rem',

    fontFamily: theme.typography.fontFamily,
    lineHeight: '1.16667em',
  },
  dialog: {
    overflow: 'hidden',
  },
  dialogTitleSection: {
    flex: '1 1 auto',
    marginBottom: 16,
    fontSize: 14,
    backgroundColor: theme.palette.primary.main,

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  closeIconWrapper: {
    minWidth: 'min-content',
    minHeight: 'min-content',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  closeIcon: {
    '&:hover': { backgroundColor: '#00000010' },
    '&:active': { backgroundColor: '#00000020' },
  },
  titleWrapper: {
    padding: '5px  5px 0px 0px',
  },
  dialogContent: {
    // width: '100%',
    height: '100%',
    minWidth: 'min-content',
    minHeight: 'min-content',
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  actions: {
    // flex: '1 1 auto',
    height: 'min-content',
    minWidth: 'min-content',
    minHeight: 'min-content',
    margin: '0px 25px 25px',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',

    color: '#000',
  },

  label: {
    cursor: 'pointer',
    padding: '6px 0px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },

  noPadding: {
    padding: '0 !important',
  },

  bottom: { marginTop: 20 },

  secondButton: {
    width: '100%',
    height: '35px',
    color: '#1F3967',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '0px 2px 2px #00000040',
    borderRadius: '3px',
    display: 'flow-root',
    '&:hover': {
      color: '#1F3967',
      backgroundColor: theme.palette.secondary.main,
      boxShadow: '0px 2px 2px #00000040',
    },
    marginLeft: 'auto',
  },
  buttonProgressCircle: {
    color: '#1F3967',
    margin: 'auto',
  },
  dialogAvatarWidth: {
    minWidth: '500px',
    minHeight: '500px',
  },
  paper: {
    position: 'absolute',
    zIndex: 2,
    top: -20,
  },
  uploadButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  uploadIcon: {
    marginRight: 10,
  },

  content: {
    position: 'relative',
    flex: '1 1 auto',
    webkitFlex: '1 1 auto',
    msFlex: '1 1 auto',
    width: '100%',
    minWidth: 'min-content',
    minHeight: 'min-content',
    boxSizing: 'border-box',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },

  title: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '20px',
    //@ts-ignore
    color: theme.palette.text.white,
  },
  imageCropper: {
    position: 'relative',
    width: '100%',
    height: theme.spacing(40),
  },
  actionWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    '& .MuiButton-root': {
      marginTop: theme.spacing(2),
    },
  },
  defaultAvatar: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
  },
  zoomControl: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
}));
