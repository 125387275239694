import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  mainContainer: {
    [theme.breakpoints.down(theme.screenbreakpoint.large + 1)]: {
      padding: theme.spacing(0, 4, 0, 4),
    },
    [theme.breakpoints.down(theme.screenbreakpoint.tablet + 340)]: {
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      padding: theme.spacing(0, 1, 0, 1),
    },
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down(theme.screenbreakpoint.large + 1)]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
  paper: {
    width: theme.spacing(50),
    padding: theme.spacing(1, 4, 1, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 8,
    boxShadow: '0px 4px 80px rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.down(theme.screenbreakpoint.large + 1)]: {
      width: theme.spacing(48),
    },
    [theme.breakpoints.down(theme.screenbreakpoint.tablet + 1)]: {
      width: '100%',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    width: '100%',
  },
  tipsItem: {
    // paddingRight: theme.spacing(14),
    // [theme.breakpoints.down(theme.screenbreakpoint.large + 1)]: {
    //   paddingRight: theme.spacing(8),
    // },
    // [theme.breakpoints.down(410)]: {
    //   paddingRight: 0,
    // },
  },
  loginCaption: {
    color: theme.colors.text.black02,
    textAlign: 'center',
    padding: theme.spacing(4, 0, 4, 0),
    fontSize: '24px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '-0.18px',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      fontWeight: 500,
      padding: theme.spacing(2, 0),
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },

  formBox: {
    width: '100%',
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  actionButton: {
    margin: theme.spacing(3, 0, 0),
    [theme.breakpoints.down(theme.screenbreakpoint.large + 1)]: {
      margin: theme.spacing(2, 0, 0),
    },
  },
  infoButton: {
    margin: theme.spacing(3, 0, 0),
    backgroundColor: theme.colors.background.info,
    [theme.breakpoints.down(theme.screenbreakpoint.large + 1)]: {
      margin: theme.spacing(2, 0, 0),
    },
  },

  alertContainer: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  forgotPasswordLink: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.18px',
    textDecorationLine: 'underline',
    color: theme.colors.text.black02,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(3, 0, 2, 0),
  },
  linkWrappers: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: theme.spacing(2, 0, 2, 0),
  },
  registerHint: {
    color: theme.colors.text.black02,
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '-0.18px',
    whiteSpace: 'nowrap',
  },
  mainTipsContent: {
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '-0.18px',
    wordBreak: 'keep-all',
    color: theme.colors.text.black,
  },
  registerLink: {
    textDecoration: 'none',
  },
}));

export default styles;
