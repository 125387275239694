import { gql } from '@apollo/client';

export const REGION_INFO_FRAGMENT = gql`
  fragment RegionInfoFragment on Region {
    id
    name
    provinceId
  }
`;

export const DISTRICT_INFO_FRAGMENT = gql`
  fragment DistrictInfoFragment on District {
    id
    name
    region {
      ...RegionInfoFragment
    }
  }
  ${REGION_INFO_FRAGMENT}
`;

export const MUNICIPALITY_INFO_FRAGMENT = gql`
  fragment MunicipalityInfoFragment on Municipality {
    id
    name
    district {
      ...DistrictInfoFragment
    }
  }
  ${DISTRICT_INFO_FRAGMENT}
`;

export const FOKONTANY_INFO_FRAGMENT = gql`
  fragment FokontanyInfoFragment on Fokontany {
    id
    name
    boroughId
  }
`;

export const ADDRESS_INFO_FRAGMENT = gql`
  fragment AddressInfoFragment on Address {
    id
    name
    fokontanyId
  }
`;
