import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { ApolloErrorCode } from '../../../consts';
import { useErrorHanlder } from '../../../modules/hooks';

import { GET_MUNICIPALITIES_BY_DISTRICT } from '../query';
import {
  GetMunicipalitiesByDistrict,
  GetMunicipalitiesByDistrictVariables,
  GetMunicipalitiesByDistrict_municipalities,
} from '../types/GetMunicipalitiesByDistrict';

export const useMunicipalitesByDistrict = (
  districtId?: string | null,
  skip?: boolean,
): {
  isLoading: boolean;
  municipalities: GetMunicipalitiesByDistrict_municipalities[];
} => {
  // Error handler
  const handleError = useErrorHanlder();

  // Intl
  const intl = useIntl();

  // Graphql query
  const { data, loading } = useQuery<
    GetMunicipalitiesByDistrict,
    GetMunicipalitiesByDistrictVariables
  >(GET_MUNICIPALITIES_BY_DISTRICT, {
    variables: { districtId: districtId ?? '' },
    skip: !districtId || skip,
    onError: (error) => {
      handleError(error, [
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'error.loading.label',
          }),
        },
      ]);
    },
    fetchPolicy: 'cache-first',
  });

  return {
    isLoading: loading,
    municipalities: data?.municipalities ?? [],
  };
};
