import React from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { ApolloErrorCode } from '../../../consts';
import { useErrorHanlder } from '../../../modules/hooks';

import { USER_CHECK_PASSWORD_RESET } from '../query';
import {
  HealthProfilePasswordResetCheck,
  HealthProfilePasswordResetCheckVariables,
} from '../types/HealthProfilePasswordResetCheck';

export const usePasswordResetCheck = (
  resetToken?: string,
): {
  isLoading: boolean;
  isValid?: boolean;
  errorAlert?: string | null;
  closeAlert: () => void;
} => {
  const intl = useIntl();
  const handleError = useErrorHanlder();
  const [errorAlert, setErrorAlert] = React.useState<string | null>();

  const closeAlert = () => {
    setErrorAlert(null);
  };

  const { data, loading } = useQuery<
    HealthProfilePasswordResetCheck,
    HealthProfilePasswordResetCheckVariables
  >(USER_CHECK_PASSWORD_RESET, {
    variables: { resetToken: resetToken ?? '' },
    fetchPolicy: 'network-only',
    skip: !resetToken,
    onError: (error) => {
      handleError(error, [
        {
          code: ApolloErrorCode.INVALID_PASSWORDRESET_TOKEN,
          message: intl.formatMessage({
            id: 'forgotpassword.invalid.link.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'error.loading.label',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
      ]);
    },
  });

  return {
    errorAlert,
    closeAlert,
    isLoading: loading,
    isValid: data?.healthProfilePasswordResetCheck?.isValid ?? undefined,
  };
};
