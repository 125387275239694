import { gql } from '@apollo/client';
import { FAQ_INFO_FRAGMENT, FAQCATEG_INFO_FRAGMENT } from './fragment';

export const GET_ALL_FAQ = gql`
  query GetAllFaq {
    faqs {
      ...FaqObjTypeFragment
    }
    faqCategs {
      ...FaqCategObjTypeFragment
    }
  }
  ${FAQ_INFO_FRAGMENT}
  ${FAQCATEG_INFO_FRAGMENT}
`;
