import React, { FC } from 'react';
import { Typography, Box } from '@material-ui/core';
import OldPersonImage from '../../../../assets/images/person-tips-oldman.svg';
import { useStyles } from './styles';

export interface IProps {
  primaryTitle: string;
  secondaryTitle: string;
  className?: string;
}

const TipsHeadline: FC<IProps> = ({
  primaryTitle,
  secondaryTitle,
  className,
}) => {
  const classes = useStyles();
  return (
    <Box component="div" className={className}>
      <Box className={classes.root}>
        <Box>
          <img
            className={classes.tipsHintImage}
            src={OldPersonImage}
            alt="A nurse vaccinates an old man"
          />
        </Box>
        <Box className={classes.titleWrapper}>
          <Typography className={classes.primaryTitle}>
            {primaryTitle}
          </Typography>
          <Typography
            className={classes.secondaryTitle}
            dangerouslySetInnerHTML={{ __html: secondaryTitle }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TipsHeadline;
