import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { ApolloErrorCode } from '../../../consts';
import { useIntlLabel } from '../../../locales';
import { useErrorHanlder } from '../../../modules/hooks';

import { GET_ALL_COMORBIDITY } from '../query';
import {
  GetAllComorbidity,
  GetAllComorbidity_comorbidities,
} from '../types/GetAllComorbidity';

export const useAllComorbidity = (
  other: boolean,
  skip?: boolean,
): {
  isLoading: boolean;
  comorbidities: GetAllComorbidity_comorbidities[];
  refetch: () => void;
} => {
  // Error handler
  const handleError = useErrorHanlder();

  // Intl
  const intl = useIntl();
  const intlLabel = useIntlLabel();

  // Graphql query
  const { data, loading, refetch } = useQuery<GetAllComorbidity>(
    GET_ALL_COMORBIDITY,
    {
      variables: { other },
      skip: skip,
      onError: (error) => {
        handleError(error, [
          {
            code: ApolloErrorCode.UKNOWN_ERROR,
            message: intl.formatMessage({
              id: 'error.loading.label',
            }),
          },
        ]);
      },
      fetchPolicy: 'cache-first',
    },
  );

  return {
    isLoading: loading,
    comorbidities:
      data?.comorbidities?.map?.((comorbidityItem) => ({
        ...comorbidityItem,
        name: intlLabel(comorbidityItem, 'name'),
      })) ?? [],
    refetch,
  };
};
