export default {
  'userprofile.gender.label': 'Genre',
  'userprofile.gender.male.label': 'Homme',
  'userprofile.gender.female.label': 'Femme',
  'userprofile.card': 'Ma carte',
  'userprofile.uploadavatar.title': 'Modifier votre photo de profil',
  'userprofile.uploadavatar.choosefile.label': 'Sélectionnez une photo',
  'userprofile.uploadavatar.saveavatar.label': 'Enregistrer la photo de profil',
  'userprofile.uploadavatar.updated.message':
    'Votre photo de profil a été modifiée',
  'userprofile.uploadavatar.error.message':
    "Une erreur s'est produite lors de la mise à jour de votre photo!",
  'userprofile.cancel.edit.label': 'Annuler la modification',
  'userprofile.edit.label': 'Modifier',
  'userprofile.logout.label': 'Se déconnecter',
};
