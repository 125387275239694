import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { ApolloErrorCode } from '../../../consts';
import { useErrorHanlder } from '../../../modules/hooks';

import { GET_ALL_VACCINE_META } from '../query';
import {
  GetAllVaccineMeta_vaccines,
  GetAllVaccineMeta,
} from '../types/GetAllVaccineMeta';

export const useAllVaccineMeta = (): {
  isLoading: boolean;
  vaccines: GetAllVaccineMeta_vaccines[];
  refetch: () => void;
} => {
  // Error handler
  const handleError = useErrorHanlder();

  // Intl
  const intl = useIntl();

  // Graphql query
  const { data, loading, refetch } = useQuery<GetAllVaccineMeta>(
    GET_ALL_VACCINE_META,
    {
      onError: (error) => {
        handleError(error, [
          {
            code: ApolloErrorCode.UKNOWN_ERROR,
            message: intl.formatMessage({
              id: 'error.loading.label',
            }),
          },
        ]);
      },
    },
  );

  return {
    isLoading: loading,
    vaccines: data?.vaccines ?? [],
    refetch,
  };
};
