import React from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import DialogTitle, {
  DialogTitleProps,
  DialogTitleClassKey,
} from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import useCommonStyles from '../../../styles';
import useStyles from './styles';

export interface IProps extends Omit<DialogTitleProps, 'title' | 'classes'> {
  id?: string;
  icon?: React.ReactElement<any, any>;
  title?: React.ReactNode;
  enableCloseIcon?: boolean;
  disableClose?: boolean;
  onClose?: () => void;
  className?: string;
  classes?: Partial<
    Record<keyof ReturnType<typeof useStyles> | DialogTitleClassKey, string>
  >;
}

const DialogBaseTitle = (props: IProps) => {
  const {
    icon,
    title = '',
    enableCloseIcon = false,
    disableClose = false,
    onClose,
    className,
    classes: {
      root,
      labelWrapper,
      icon: _,
      label,
      iconCloseWrapper,
      iconClose,
      iconCloseDisabled,
      ...dialogTitleClasses
    } = {},
    ...otherProps
  } = props;

  const commonClasses = useCommonStyles();
  const classes = useStyles(props);

  return (
    <DialogTitle
      {...{
        id: 'dialog-base-title',
        disableTypography: true,
        classes: { root: clsx(classes.root, className), ...dialogTitleClasses },
        ...otherProps,
      }}
    >
      <Box {...{ component: 'div', className: classes.labelWrapper }}>
        {icon &&
          React.cloneElement(icon, {
            className: clsx(classes.icon, icon.props?.className),
          })}

        <Box
          {...{
            component: 'span',
            className: clsx(commonClasses.inlineLabel, classes.label),
          }}
        >
          {title}
        </Box>
      </Box>

      {enableCloseIcon && (
        <Box {...{ component: 'div', className: classes.iconCloseWrapper }}>
          <CloseIcon
            {...{
              onClick: !disableClose ? onClose : undefined,
              classes: {
                root: clsx(classes.iconClose, {
                  [classes.iconCloseDisabled]: disableClose,
                }),
              },
            }}
          />
        </Box>
      )}
    </DialogTitle>
  );
};

export default DialogBaseTitle;
