import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { ApolloErrorCode } from '../../../consts';
import { useIntlLabel } from '../../../locales';
import { useErrorHanlder } from '../../../modules/hooks';

import { GET_ALL_FAQ } from '../query';
import {
  GetAllFaq,
  GetAllFaq_faqs,
  GetAllFaq_faqCategs,
} from '../types/GetAllFaq';

export const useAllFaq = (): {
  isLoading: boolean;
  faqs: GetAllFaq_faqs[];
  faqCategs: GetAllFaq_faqCategs[];
  refetch: () => void;
} => {
  // Error handler
  const handleError = useErrorHanlder();

  // Intl
  const intl = useIntl();
  const intlLabel = useIntlLabel();

  // Graphql query
  const { data, loading, refetch } = useQuery<GetAllFaq>(GET_ALL_FAQ, {
    onError: (error) => {
      handleError(error, [
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'error.loading.label',
          }),
        },
      ]);
    },
  });

  return {
    isLoading: loading,
    faqs:
      data?.faqs?.map?.((faqItem) => ({
        ...faqItem,
        answer: intlLabel(faqItem, 'answer'),
        question: intlLabel(faqItem, 'question'),
      })) ?? [],
    faqCategs:
      data?.faqCategs?.map?.((categItem) => ({
        ...categItem,
        name: intlLabel(categItem, 'name'),
      })) ?? [],
    refetch,
  };
};
