import React, { ReactNode } from 'react';
import clsx from 'clsx';
import Button, { ButtonProps, ButtonClassKey } from '@material-ui/core/Button';
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import StyledTooltip from '../../StyledTooltip';

import useCommonStyles from '../../styles';
import useStyles from './styles';

export interface IProps extends ButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  color?: 'primary' | 'secondary' | 'default';
  tooltip?: ReactNode;
  CircularProgressProps?: Partial<CircularProgressProps>;
  className?: string;
  classes?: Partial<
    Record<keyof ReturnType<typeof useStyles> | ButtonClassKey, string>
  >;
}

const ActionButton = (props: IProps) => {
  const {
    children,
    disabled = false,
    loading = false,
    color = 'primary',
    tooltip,
    CircularProgressProps = {},
    className,
    classes: {
      root,
      primary,
      secondary,
      disabled: _,
      label,
      primaryLabel,
      primaryDisabledColor,
      secondaryDisabledColor,
      circularProgress,
      ...buttonClasses
    } = {},
    ...otherProps
  } = props;

  const commonClasses = useCommonStyles();
  const classes = useStyles(props);

  const button = (
    <Button
      {...{
        disabled,
        classes: {
          root: clsx(
            classes.root,
            {
              [classes.wrapped]: !!tooltip && disabled,
              [classes.primary]: color === 'primary',
              [classes.secondary]: color === 'secondary',
              [classes.default]: color === 'default',
            },
            className,
          ),

          disabled: classes.disabled,

          label: clsx(commonClasses.inlineLabel, classes.label, {
            [classes.primaryLabel]: color === 'primary',
            [classes.secondaryLabel]: color === 'secondary',
            [classes.primaryDisabledColor]: disabled && color === 'primary',
            [classes.secondaryDisabledColor]: disabled && color === 'secondary',
          }),
          ...buttonClasses,
        },
        ...otherProps,
      }}
    >
      {loading ? (
        <CircularProgress
          {...{
            size: '22px',
            classes: {
              root: clsx(
                classes.circularProgress,
                {
                  [classes.primaryDisabledColor]: color === 'primary',
                  [classes.secondaryDisabledColor]: color === 'secondary',
                },
                CircularProgressProps.className,
              ),
            },
            ...CircularProgressProps,
          }}
        />
      ) : (
        children
      )}
    </Button>
  );

  if (tooltip)
    return (
      <StyledTooltip {...{ title: tooltip }}>
        {disabled ? (
          <Box {...{ className: clsx(classes.root, classes.buttonWrapper) }}>
            {button}
          </Box>
        ) : (
          button
        )}
      </StyledTooltip>
    );

  return button;
};

export default ActionButton;
