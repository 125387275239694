import { gql } from '@apollo/client';

export const USER_SIGN_IN = gql`
  mutation SignIn($username: String!, $password: String!) {
    healthProfileSignIn(username: $username, password: $password) {
      accessToken
      refreshToken
      certAccessKey
    }
  }
`;

export const USER_UPDATE_PASSWORD = gql`
  mutation UpdatePassword($oldPassword: String!, $newPassword: String!) {
    updatePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      response {
        success
        message
      }
    }
  }
`;

// Trigger password reset (In case of forgotten password)
export const USER_FORGOT_PASSWORD = gql`
  mutation HealthProfileForgotPassword($username: String!) {
    healthProfileForgotPassword(username: $username) {
      emailAddress
      resetToken
    }
  }
`;

// Reset the password
export const USER_RESET_PASSWORD = gql`
  mutation HealthProfileResetPassword(
    $newPassword: String!
    $confirmPassword: String!
    $resetToken: String!
  ) {
    healthProfileResetPassword(
      newPassword: $newPassword
      confirmPassword: $confirmPassword
      resetToken: $resetToken
    ) {
      response {
        success
        message
      }
    }
  }
`;
