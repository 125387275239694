import React, { FC, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { Alert } from '@material-ui/lab';
import ReactToPrint from 'react-to-print';
import { FormattedMessage, useIntl } from 'react-intl';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowRightAltIcon from '@material-ui/icons/ArrowBack';

import LabeledIconButton from '../../components/button/LabeledIconButton';
import PageContainer from '../../components/page/PageContainer';
import InfiniteLoading from '../../components/InfiniteLoading';
import { useCitizenVaccinationProfile } from '../../graphql/citizen/hooks';
import { useAuthContext } from '../../modules/AuthProvider';
import CardCertificate from './CardCertificate';
import useStyles from './styles';
import { DEFAULT_HOME_ROUTE } from '../../consts';
import { useMyProfile } from '../../graphql/authentication/hooks';
import { Typography } from '@material-ui/core';

interface IParams {
  accessKey: string;
}

const VaccinationCard: FC = () => {
  const classes = useStyles();
  const { accessKey } = useParams<IParams>();
  const componentRef = useRef<HTMLDivElement>(null);
  useMyProfile();
  const { currentUser } = useAuthContext();
  const intl = useIntl();

  // Fetch citizen's profile info by access key
  const { isLoading, errorAlert, closeAlert, citizenProfile } =
    useCitizenVaccinationProfile(accessKey);

  const canGetCertificate = (citizenProfile) => {
    console.log('CITIZEN: ', citizenProfile);
    const civilState = citizenProfile?.lastName ? true : false;
    const healthState =
      citizenProfile.health?.vaccineEvents &&
      citizenProfile.health?.vaccineEvents.length > 0
        ? true
        : false;

    return civilState && healthState ? true : false;
  };

  return (
    <Grid>
      {isLoading ? (
        <InfiniteLoading />
      ) : errorAlert || !citizenProfile ? (
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Box component="div" className={classes.alertContainer}>
              <Alert severity={'error'} onClose={() => closeAlert()}>
                {errorAlert}
              </Alert>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          {!canGetCertificate(citizenProfile) ? (
            <Alert severity="warning">
              <Typography
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'common.card.certificate.not.ready.message',
                  }),
                }}
              />
            </Alert>
          ) : (
            <>
              <Box className={classes.toolbar}>
                {currentUser && (
                  <Link to={DEFAULT_HOME_ROUTE}>
                    <LabeledIconButton
                      {...{
                        color: 'secondary',
                        icon: <ArrowRightAltIcon />,
                      }}
                    >
                      <FormattedMessage id="common.card.back.to.profile" />
                    </LabeledIconButton>
                  </Link>
                )}
                <ReactToPrint
                  trigger={() => (
                    <LabeledIconButton
                      {...{
                        color: 'primary',
                        icon: <GetAppIcon />,
                      }}
                    >
                      <FormattedMessage id="common.vaccine.card.download" />
                    </LabeledIconButton>
                  )}
                  documentTitle={
                    citizenProfile.epiId +
                    citizenProfile.lastName +
                    citizenProfile.firstName
                  }
                  bodyClass={classes.printBodyClass}
                  content={() => componentRef.current}
                />
              </Box>
              <Paper className={classes.paper}>
                <CardCertificate
                  printRef={componentRef}
                  accessKey={accessKey}
                />
              </Paper>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

const VaccinationCardWrapper: FC = () => {
  const classes = useStyles();
  return (
    <PageContainer showBackground={true}>
      <Box component="div" className={classes.root}>
        <VaccinationCard />
      </Box>
    </PageContainer>
  );
};

export default VaccinationCardWrapper;
