import React, { FC } from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

import useStyles from './styles';

export interface IStyledTooltipProps extends TooltipProps {
  head?: string;
  classes?: Partial<ReturnType<typeof useStyles>>;
}

const StyledTooltip: FC<IStyledTooltipProps> = (props: IStyledTooltipProps) => {
  const {
    children,
    open,
    title,
    id = 'tooltip',
    head = '',
    className,
    ...otherProps
  } = props;

  const classes = useStyles(props);

  if ((open !== undefined && !open) || !title) return children;

  return (
    <Tooltip
      {...{
        id,
        enterDelay: 750,
        ...otherProps,
        open,
        title: (
          <Box {...{ component: 'div', className: classes.text }}>
            {head && (
              <Box
                {...{
                  component: 'div',
                  style: {
                    borderBottom: '1px dashed white',
                    width: 'min-content',
                    paddingBottom: '10px',
                    marginBottom: '10px',
                    whiteSpace: 'nowrap',
                  },
                }}
              >
                {head} <br />
              </Box>
            )}
            {title}
          </Box>
        ),
        classes: {
          popper: classes.popper,
          tooltip: clsx(classes.tooltip, className),
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default StyledTooltip;
