import { gql } from '@apollo/client';

export const CITIZEN_INFO_FRAGMENT = gql`
  fragment AllCitizenInfoFragment on Citizen {
    id
    prodigyId
    photo
    lastName
    firstName
    address {
      id
      name
    }
    addressId
    birthDate
    birthPlace
    cniDeliveryDate
    cniDeliveryPlace
    cniNum
    email
    gender
    phoneNumber
    epiId
    createdBy
    createdOn
    creator {
      id
      lastName
      firstName
    }
    updatedBy
    updatedOn
    updater {
      id
      lastName
      firstName
    }
    isValidated
    validatedBy
    validatedOn
    validator {
      id
      lastName
      firstName
    }
    fokontany {
      id
      name
    }
    borough {
      id
      name
    }
    municipality {
      id
      name
    }
    district {
      id
      name
    }
    job {
      id
      employerId
      jobId
      otherJobId
      offRegNum
      profRegNum
      workplace
      otherJob {
        code
        id
        name
      }
      employer {
        id
        municipalityId
        name
      }
    }
    health {
      id
      comorbidityId
      covaxPriority
      healthCenterId
      lastPcrTest
      covacDose1Date
      covacDose2Date
      desiredVaccineId
      desiredVaccine {
        id
        name
        uid
      }
      otherComorbidityId
      otherComorbidity {
        id
        name
        other
      }
      healthCenter {
        id
        name
        municipalityId
      }
      comorbidity {
        id
        name
      }
      vaccineEvents {
        vaccineId
        doseNum
        doseName
        vaccineBatchNum
        vaccineDate
        vaccineCenter
      }
      testCovidEvents {
        samplingDate
        deliveryDate
        testResult
        testedPathogen
        usedTechnique
        laboratoryReference
      }
    }
  }
`;

export const CITIZEN_MIN_INFO_FRAGMENT = gql`
  fragment MinCitizenInfoFragment on Citizen {
    id
    prodigyId
    lastName
    firstName
    birthDate
    cniNum
    municipality {
      id
      name
    }
    district {
      id
      name
    }
    isValidated
    validatedBy
    validatedOn
    exportedOn
    createdOn
  }
`;
