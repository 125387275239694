import { makeStyles, Theme } from '@material-ui/core/styles';
import Color from 'color';

export default makeStyles((theme: Theme) => ({
  root: {
    width: 'min-content',
    height: '30px',
    minWidth: '90px',
    minHeight: 'min-content',
    padding: '7px 11px',
    margin: '0px',
    //@ts-ignore
    border: `1px solid ${theme.palette.border.light}`,
    borderRadius: '20px',
    boxSizing: 'border-box',
  },

  content: {
    width: '100%',
    boxSizing: 'border-box',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  primary: {
    border: 'none',
    boxShadow: 'none',
    background: `${theme.palette.secondary.light} 0% 0% no-repeat padding-box`,

    '&:hover': {
      background: `${theme.palette.secondary.main} 0% 0% no-repeat padding-box`,
    },
  },

  secondary: {
    border: 'none',
    boxShadow: 'none',
    background: `${Color(theme.button.default)
      .saturate(0.1)
      .lighten(0.9)
      .alpha(0.7)
      .toString()} 0% 0% no-repeat padding-box`,

    '&:hover': {
      background: `${Color(theme.button.default)
        .saturate(0.1)
        .lighten(0.9)
        .alpha(1)
        .toString()} 0% 0% no-repeat padding-box`,
    },
  },

  disabled: {
    border: 'none',
    boxShadow: 'none',
    background: `${theme.palette.background.default} 0% 0% no-repeat padding-box`,
  },

  primaryDisabled: {},

  icon: {
    flex: '0 0 auto',
    minWidth: 'min-content',
    minHeight: 'min-content',
    marginLeft: '0px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  iconColor: {
    //@ts-ignore
    color: theme.palette.text.black,
    //@ts-ignore
    fill: theme.palette.text.black,
  },

  primaryIconColor: {
    color: theme.palette.grey[100],
    fill: theme.palette.grey[100],
  },

  disabledIconColor: {
    //@ts-ignore
    color: theme.palette.text.disabled,
    //@ts-ignore
    fill: theme.palette.text.disabled,
  },

  label: {
    flex: '1 1 auto',
    fontFamily: theme.fonts.default,
    fontWeight: 500,
    fontSize: '14px',
    //@ts-ignore
    color: theme.palette.text.black,
    textTransform: 'none',
  },

  primaryLabel: {
    color: theme.palette.grey[100],
  },

  disabledLabel: {
    color: theme.palette.text.disabled,
  },
}));
