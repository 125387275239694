import React, { FC, useRef } from 'react';
import useStyles from './styles';

import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Grid, Paper, Typography } from '@material-ui/core';
import PageContainer from '../../components/page/PageContainer';
import TestCertificate from './TestCertificate/TestCertificate';
import LabeledIconButton from '../../components/button/LabeledIconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReactToPrint from 'react-to-print';
import { Link, useParams } from 'react-router-dom';
import { useMyProfile } from '../../graphql/authentication/hooks';
import { useAuthContext } from '../../modules/AuthProvider';
import { useCitizenVaccinationProfile } from '../../graphql/citizen/hooks';
import InfiniteLoading from '../../components/InfiniteLoading';
import { Alert } from '@material-ui/lab';
import { DEFAULT_HOME_ROUTE } from '../../consts';
import ArrowRightAltIcon from '@material-ui/icons/ArrowBack';

interface IParams {
  accessKey: string;
}

const TestCard: FC = () => {
  const classes = useStyles();
  const { accessKey } = useParams<IParams>();
  const componentRef = useRef<HTMLDivElement>(null);
  useMyProfile();
  const { currentUser } = useAuthContext();
  const intl = useIntl();

  // Fetch citizen's profile info by access key
  const { isLoading, errorAlert, closeAlert, citizenProfile } =
    useCitizenVaccinationProfile(accessKey);

  const canGetTestCovidCertificate = (citizenProfile) => {
    console.log('CITIZEN: ', citizenProfile);
    const civilState = citizenProfile?.lastName ? true : false;
    const healthState =
      citizenProfile.health?.testCovidEvents &&
      citizenProfile.health?.testCovidEvents.length > 0
        ? true
        : false;

    return civilState && healthState ? true : false;
  };
  return (
    <Grid>
      {isLoading ? (
        <InfiniteLoading />
      ) : errorAlert || !citizenProfile ? (
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Box component="div" className={classes.alertContainer}>
              <Alert severity={'error'} onClose={() => closeAlert()}>
                {errorAlert}
              </Alert>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <>
          {!canGetTestCovidCertificate(citizenProfile) ? (
            <Alert severity="warning">
              <Typography
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'common.card.test.covid.certificate.not.ready.message',
                  }),
                }}
              />
            </Alert>
          ) : (
            <>
              <Box className={classes.toolbar}>
                {currentUser && (
                  <Link to={DEFAULT_HOME_ROUTE}>
                    <LabeledIconButton
                      {...{
                        color: 'secondary',
                        icon: <ArrowRightAltIcon />,
                      }}
                    >
                      <FormattedMessage id="common.card.back.to.profile" />
                    </LabeledIconButton>
                  </Link>
                )}
                <ReactToPrint
                  trigger={() => (
                    <LabeledIconButton
                      {...{
                        color: 'primary',
                        icon: <GetAppIcon />,
                      }}
                    >
                      <FormattedMessage id="common.test.card.download" />
                    </LabeledIconButton>
                  )}
                  documentTitle={
                    citizenProfile.epiId +
                    citizenProfile.lastName +
                    citizenProfile.firstName
                  }
                  bodyClass={classes.printBodyClass}
                  content={() => componentRef.current}
                />
              </Box>
              <Paper>
                <TestCertificate
                  printRef={componentRef}
                  accessKey={accessKey}
                />
              </Paper>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

const TestCardWrapper: FC = () => {
  const classes = useStyles();

  return (
    <PageContainer showBackground={true}>
      <Box component="div" className={classes.root}>
        <TestCard />
      </Box>
    </PageContainer>
  );
};

export default TestCardWrapper;
