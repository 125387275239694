import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { ApolloErrorCode } from '../../../consts';
import { useErrorHanlder } from '../../../modules/hooks';

import { GET_ALL_HEALTHCENTER } from '../query';
import {
  GetAllHealthCenter,
  GetAllHealthCenterVariables,
  GetAllHealthCenter_healthCenters,
} from '../types/GetAllHealthCenter';

export const useAllHealthCenter = (
  municipalityId?: string | null,
  skip?: boolean,
): {
  isLoading: boolean;
  healthCenters: GetAllHealthCenter_healthCenters[];
} => {
  // Error handler
  const handleError = useErrorHanlder();

  // Intl
  const intl = useIntl();

  // Graphql query
  const { data, loading } = useQuery<
    GetAllHealthCenter,
    GetAllHealthCenterVariables
  >(GET_ALL_HEALTHCENTER, {
    variables: { municipalityId: municipalityId ?? '' },
    skip: !municipalityId || skip,
    onError: (error) => {
      handleError(error, [
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'error.loading.label',
          }),
        },
      ]);
    },
    fetchPolicy: 'cache-first',
  });

  return {
    isLoading: loading,
    healthCenters: data?.healthCenters ?? [],
  };
};
