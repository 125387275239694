import React, { FC } from 'react';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';
import { useAuthContext } from '../../modules/AuthProvider';
import { DEFAULT_HOME_ROUTE } from '../../consts';

interface IProps
  extends Omit<RouteProps, 'children' | 'render' | 'sensitive' | 'strict'> {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const PublicRoute: FC<IProps> = ({
  component: Component,
  ...others
}: IProps) => {
  const { isAuthenticated } = useAuthContext();
  return (
    <Route
      {...others}
      render={(props: RouteComponentProps<any>) =>
        !isAuthenticated() || props.location.pathname.indexOf('/error') > -1 ? (
          <>
            <Component {...props} />
          </>
        ) : (
          <>
            <Redirect
              to={{
                pathname: DEFAULT_HOME_ROUTE,
                search: `from=${props.location.pathname}`,
                state: { from: props.location },
              }}
            />
          </>
        )
      }
    />
  );
};

export default PublicRoute;
