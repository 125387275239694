import { gql } from '@apollo/client';
import { EMPLOYERENTITY_INFO_FRAGMENT, JOB_INFO_FRAGMENT } from './fragment';

export const GET_ALL_JOB = gql`
  query GetAllJob($other: Boolean) {
    jobs(other: $other) {
      ...JobInfoFragment
    }
  }
  ${JOB_INFO_FRAGMENT}
`;

export const GET_ALL_EMPLOYERENTITY = gql`
  query GetAllEmployerEntity($municipalityId: ID!) {
    employerEntities(municipalityId: $municipalityId) {
      ...EmployerEntityInfoFragment
    }
  }
  ${EMPLOYERENTITY_INFO_FRAGMENT}
`;
