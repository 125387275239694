export default {
  'register.title.label': 'Créez un compte',
  'register.button.submit.label': 'Enregistrer',
  'register.button.previous.label': 'Précédent',
  'register.button.next.label': 'Suivant',
  'register.link.label': 'S’inscrire',
  'register.hint.label': 'Pas encore enregistré ?',
  'registration.validation.notification':
    "En attente de validation par l'admin...",
  'registration.validation.notification.title': 'Notification',

  'registration.tips.primary.title': 'VACCIN COVID-19',
  'registration.tips.secondary.title':
    'Comment <br/>puis-je prendre <br/>rendez-vous?',

  'register.step.personalinfo.title.label':
    'Informations personnelles (Etape 1 sur 3)',
  'register.step.jobinfo.title.label':
    'Renseignement profession (Etape 2 sur 3)',
  'register.step.healthinfo.title.label':
    'Renseignement santé (Dernière étape)',
  'registration.enrollment.process.job.label': 'Renseignement profession',
  'registration.enrollment.process.health.label': 'Renseignement santé',
  'registration.enrollment.process.appointment.label': 'Prise de rendez-vous',
  'registration.enrollment.process.ackconfirm.label':
    'Confirmation de réception',

  'registration.test.positive.not.allowed.message':
    'Test positif moins de 3 mois, vous ne devez pas vous faire vacciner',
  'registration.ack.title.label': 'Confirmation de réception',
  'registration.ack.message.label': `
    <p>Votre inscription à la vaccination contre la Covid-19 a été enregistrée. Vous pouvez directement se rapprocher du centre de vaccination qui vous arrange.</p>
    <p>Pour tout besoin d’information complémentaire, veuillez nous contacter par mail au <a href="mailto:vaccinationcovid19@googlegroups.com">vaccinationcovid19@googlegroups.com</a> ou appeler le numéro 910.</p><br/>
    <p>Nous vous remercions de votre intérêt à l'égard du vaccin contre le COVID-19. En se vaccinant, vous allez :</p>
    <p>1- vous protéger contre les formes graves de la maladie et donc de sauver des vies ou d’éviter les effets à longs termes sur la santé (séquelles neurologiques, pulmonaires, cardiovasculaires…),</p>
    <p>2- aider le système de santé en libérant les ressources médicales,</p>
    <p>3- protéger les plus vulnérables en atteignant l’immunité collective.</p>
    `,
};
