import React, { useEffect, FC } from 'react';
import { IntlProvider } from 'react-intl';
import messages from '../../locales';
import LocaleContext, { Locale, EnumLocaleKeys } from './LocaleContext';

interface IProps {
  children: React.ReactNode;
}

const LOCALSTORAGE_FIELD_KEY = 'HEALTH-PROFILE-LOCALE';

const LocaleProvider: FC<IProps> = ({ children }) => {
  const [locale, setLocale] = React.useState<Locale>(EnumLocaleKeys.Malagasy);

  // Load the last configured locale
  const loadLastLocale = async () => {
    const lastLocale = (localStorage.getItem(LOCALSTORAGE_FIELD_KEY) ??
      EnumLocaleKeys.Malagasy) as Locale;

    if (
      EnumLocaleKeys.Francais != lastLocale &&
      EnumLocaleKeys.Malagasy != lastLocale
    ) {
      setLocale(EnumLocaleKeys.Malagasy);
    } else {
      setLocale(lastLocale as Locale);
    }
  };

  // Update locale on mount
  useEffect(() => {
    loadLastLocale();
  });

  return (
    <LocaleContext.Provider
      value={{
        locale,
        setLocale: (newLocale: Locale) => {
          setLocale(newLocale);
          localStorage.setItem(LOCALSTORAGE_FIELD_KEY, newLocale);
        },
      }}
    >
      <IntlProvider
        defaultLocale={EnumLocaleKeys.Malagasy}
        locale={locale}
        messages={messages[locale]}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
