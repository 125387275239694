import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useAuthContext } from '../../../modules/AuthProvider';
import { MY_PROFILE } from '../query';
import { MyProfile, MyProfile_myProfile } from '../types/MyProfile';

export const useMyProfile = (): {
  isLoading: boolean;
  currentProfile?: MyProfile_myProfile;
} => {
  const { setCurrentUser } = useAuthContext();

  const { data, loading } = useQuery<MyProfile>(MY_PROFILE, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setCurrentUser(data?.myProfile ?? undefined);
  }, [data, setCurrentUser]);

  return {
    isLoading: loading,
    currentProfile: data?.myProfile ?? undefined,
  };
};
