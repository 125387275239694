import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    boxSizing: 'border-box',
    marginBottom: theme.spacing(4),
  },
  controlPanel: {
    maxWidth: theme.spacing(160),
    width: '100%',
    paddingBottom: theme.spacing(3),
    alignContent: 'center',
  },
  paper: {
    maxWidth: theme.spacing(160),
    borderRadius: 8,
    alignContent: 'center',
    boxShadow: '0px 4px 80px rgba(0, 0, 0, 0.08)',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2, 4),
    justifyContent: 'center',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      padding: theme.spacing(3),
      flexDirection: 'column',
    },
  },
  technicalLabel: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'left',
    color: theme.palette.secondary.main,
  },
  faqLabel: {
    paddingTop: theme.spacing(2),
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '44px',
    textAlign: 'left',
    letterSpacing: '-0.02em',
    color: theme.colors.text.black00,
  },
  captionMessage: {
    paddingTop: theme.spacing(2),
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '28px',
    textAlign: 'left',
    color: theme.colors.text.black004,
    '& a': {
      color: theme.palette.secondary.main,
    },
  },
  faqCuriousIndividu: {
    width: 'auto',
    height: 'auto',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone + 150)]: {
      width: '217px',
      height: '308px',
    },
  },
  controlForm: {
    flexBasis: '37%',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone + 150)]: {
      flexBasis: '25%',
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      textAlign: 'center',
    },
  },
  qaPanel: {
    flexGrow: 1,
    paddingLeft: theme.spacing(8),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      paddingTop: theme.spacing(3),
      paddingLeft: 0,
    },
  },
  qaEmptyPanel: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      alignItem: 'center',
    },
  },
  backLink: {
    textDecoration: 'none',
  },
  faqEmptyImage: {
    maxWidth: theme.spacing(60),
    maxHeight: theme.spacing(44),
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      width: '100%',
    },
  },
  emptyPrimaryMessage: {
    maxWidth: theme.spacing(60),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '44px',
    textAlign: 'left',
    color: theme.colors.text.black00,
    letterSpacing: '-0.02em',
  },
  emptySecondaryMessage: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '28px',
    textAlign: 'left',
    color: theme.colors.text.black004,
  },
}));

export default styles;
