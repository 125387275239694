import React, { FC, useRef } from 'react';
import PageContainer from '../../components/page/PageContainer';
import useStyles from './styles';
import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import La2mBrandingImage from '../../assets/images/la2m_choice.svg';
import CovaxBrandingImage from '../../assets/images/covax-branding-image.svg';
import { REACT_APP_HEALTH_PROFILE_URL } from '../../config';
import { useParams } from 'react-router-dom';
import ReactLoading from 'react-loading';

interface IParams {
  testKey: string;
  accessKey: string;
}

const cards = [
  { id: 1, title: 'Certificat de Vaccination', typeLogo: 'covax' },
  { id: 2, title: 'Certificat de Test', typeLogo: 'la2m' },
];

const ChoiceCertificate: FC = () => {
  const classes = useStyles();
  const { testKey, accessKey } = useParams<IParams>();
  console.log(testKey);
  const onHandleChoiceCertificate = (typeLogo: string) => (e) => {
    if (typeLogo === 'la2m') {
      window.location.href = REACT_APP_HEALTH_PROFILE_URL + '/#/t/' + accessKey;
    } else {
      window.location.href = REACT_APP_HEALTH_PROFILE_URL + '/#/c/' + accessKey;
    }
  };

  const testSubmit = (data: any) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(data);
      }, 4000);
    });
  };
  return (
    <PageContainer showBackground={true}>
      <Container className={classes.cardGrid} maxWidth="md">
        <div className={classes.choiceCertificateHeading}>
          <Typography
            align="center"
            component="h2"
            variant="h2"
            className={classes.cardContentCaption}
          >
            Veuillez choisir votre certificat
          </Typography>
        </div>
        <Grid container spacing={4}>
          {cards.map((card) => (
            <Grid item key={card.id} xs={12} sm={6} md={6}>
              <Card
                className={classes.card}
                onClick={onHandleChoiceCertificate(card.typeLogo)}
              >
                <CardContent className={classes.cardContent}>
                  <Typography
                    component="h3"
                    variant="h3"
                    className={classes.cardCaption}
                    gutterBottom
                  >
                    {card.title}
                  </Typography>
                </CardContent>
                <CardMedia className={classes.cardMedia}>
                  <img
                    src={
                      card.typeLogo === 'la2m'
                        ? La2mBrandingImage
                        : CovaxBrandingImage
                    }
                    height="120"
                    width="300"
                  />
                </CardMedia>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </PageContainer>
  );
};

export default ChoiceCertificate;
