export default {
  'changepassword.error.min.message':
    'Le mot de passe doit comporter au moins 4 caractères',
  'changepassword.error.max.message':
    'Le mot de passe doit comporter au maximum 32 caractères',
  'changepassword.error.newpassword.required.message':
    'Le nouveau mot de passe est requis',
  'changepassword.error.confirmpassword.required.message':
    'Vous devez confirmer le nouveau mot de passe',
  'changepassword.error.confirmpassword.notmatch.message':
    'Le mot de passe de confirmation ne correspond pas',
};
