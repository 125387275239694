import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: 'min-content',
    maxHeight: theme.spacing(8),
    boxSizing: 'border-box',
    backgroundColor: theme.palette.primary.main,
    padding: '0px',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },

  labelWrapper: {
    flex: '1 1 auto',
    padding: theme.spacing(3),

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  icon: { width: '28px', height: '28px' },

  label: {
    paddingLeft: '10px',
    boxSizing: 'border-box',

    textAlign: 'left',
    font: 'normal normal normal 20px/24px',
    textShadow: '0px 3px 20px #00000014',
    //@ts-ignore
    color: theme.palette.text.white,
  },

  iconCloseWrapper: {
    flex: '0 1 auto',
    padding: '0px',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },

  iconClose: {
    width: '24px',
    height: '24px',
    padding: '0px',
    margin: '10px 13px',
    cursor: 'pointer',
    //@ts-ignore
    color: theme.palette.text.white,
  },

  iconCloseDisabled: {
    cursor: 'default',
    opacity: 0.75,
  },
}));
