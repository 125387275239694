import React, { FC, useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import Paper from '@material-ui/core/Paper';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { DEFAULT_SIGNIN_ROUTE, RegistrationStep } from '../../consts';
import { CreateCitizenInput, EnumGender } from '../../types';
import ActionButton from '../../components/button/ActionButton';
import { usePageMetaContext } from '../../modules/PageMetaProvider';
import PageContainer from '../../components/page/PageContainer';
import { useCitizenSubscribeMutation } from '../../graphql/citizen/hooks';
import useStyles from './styles';
import { useFormik, SubscriptionFieldError } from './formik';
import CitizenInfoForm from './CitizenInfoForm';
import CitizenJobForm from './CitizenJobForm';
import CitizenHealthForm from './CitizenHealthForm';
import RegistrationAckPaper from './RegistrationAckPaper';

const REGISTRATION_STEPS = [
  RegistrationStep.REGISTRATION_PERSONAL_INFO,
  RegistrationStep.REGISTRATION_JOB_INFO,
  RegistrationStep.REGISTRATION_HEALTH_INFO,
];

const Registration: FC = () => {
  const intl = useIntl();
  const [currentStep, setCurrentStep] = useState<RegistrationStep>(
    RegistrationStep.REGISTRATION_PERSONAL_INFO,
  );
  // Page context handler
  const { setPathLabels } = usePageMetaContext();
  const classes = useStyles();

  // Update page context
  useEffect(() => {
    setPathLabels([
      intl.formatMessage({
        id: 'register.title.label',
      }),
    ]);
  }, []);

  // Of formik as form state handler
  const formik = useFormik({
    initialValues: {
      gender: EnumGender.MALE,
      birthDate: null,
      appointmentDate: null,
      desiredVaccineId: null,
    },
    onSubmit: async (values, helper) => {
      createCitizenMutation(values as CreateCitizenInput);
    },
  });

  // Check if the current rendered form is valid
  const isCurrentFormValid = (theErrors: SubscriptionFieldError) => {
    switch (currentStep) {
      case RegistrationStep.REGISTRATION_PERSONAL_INFO:
        if (
          theErrors.lastName ||
          theErrors.phoneNumber ||
          theErrors.gender ||
          theErrors.birthDate ||
          theErrors.cniNum ||
          theErrors.municipalityId ||
          theErrors.fokontanyId ||
          theErrors.addressName
        )
          return false;
        break;
      case RegistrationStep.REGISTRATION_JOB_INFO:
        if (theErrors.jobId) return false;
        break;
      case RegistrationStep.REGISTRATION_HEALTH_INFO:
        if (theErrors.lastName || theErrors.phoneNumber || theErrors.gender)
          return false;
        break;

      default:
        return true;
    }
    return true;
  };

  // Hanle next form navigation
  const handleNavigateNext = () => {
    formik.validateForm().then((theError) => {
      if (isCurrentFormValid(theError)) {
        const currentStepIndex = REGISTRATION_STEPS.indexOf(currentStep);
        if (
          currentStepIndex > -1 &&
          currentStepIndex < REGISTRATION_STEPS.length - 1
        ) {
          setCurrentStep(REGISTRATION_STEPS[currentStepIndex + 1]);
        }
      }
    });
  };

  // Handle previous form navigation
  const handleNavigatePrevious = () => {
    const currentStepIndex = REGISTRATION_STEPS.indexOf(currentStep);
    if (currentStepIndex > 0) {
      setCurrentStep(REGISTRATION_STEPS[currentStepIndex - 1]);
    }
  };

  const {
    createCitizenLoading,
    createCitizenMutation,
    closeAlert: createCloseAlert,
    successAlert: createSuccessAlert,
    errorAlert: createErrorAlert,
  } = useCitizenSubscribeMutation({
    onSuccess: () => {
      setCurrentStep(RegistrationStep.REGISTRATION_ACK_INFO);
    },
  });

  const isLoading = createCitizenLoading;
  const successAlert = createSuccessAlert;
  const errorAlert = createErrorAlert;
  const closeAlert = createCloseAlert;
  const hasNextStep =
    REGISTRATION_STEPS.indexOf(currentStep) < REGISTRATION_STEPS.length - 1;
  const hasPrevousStep = REGISTRATION_STEPS.indexOf(currentStep) > 0;

  // Render the form title of the current step
  const renderRegistrationStepName = useCallback(() => {
    switch (currentStep) {
      case RegistrationStep.REGISTRATION_PERSONAL_INFO:
        return <FormattedMessage id="register.step.personalinfo.title.label" />;
      case RegistrationStep.REGISTRATION_JOB_INFO:
        return <FormattedMessage id="register.step.jobinfo.title.label" />;
      case RegistrationStep.REGISTRATION_HEALTH_INFO:
        return <FormattedMessage id="register.step.healthinfo.title.label" />;

      default:
        return null;
    }
  }, [currentStep]);

  // Render the fields of the current step
  const renderRegistrationForm = useCallback(() => {
    switch (currentStep) {
      case RegistrationStep.REGISTRATION_PERSONAL_INFO:
        return <CitizenInfoForm formik={formik} isLoading={isLoading} />;
      case RegistrationStep.REGISTRATION_JOB_INFO:
        return <CitizenJobForm formik={formik} isLoading={isLoading} />;
      case RegistrationStep.REGISTRATION_HEALTH_INFO:
        return <CitizenHealthForm formik={formik} isLoading={isLoading} />;

      default:
        return null;
    }
  }, [currentStep, isLoading, formik]);

  return (
    <PageContainer showBackground={true}>
      <Box component="div" className={classes.root}>
        <Paper
          className={clsx(classes.paper, {
            [classes.fullWithPaper]:
              currentStep === RegistrationStep.REGISTRATION_PERSONAL_INFO,
          })}
        >
          {currentStep === RegistrationStep.REGISTRATION_ACK_INFO ? (
            <RegistrationAckPaper />
          ) : (
            <Box component="div" className={classes.content}>
              <Typography className={classes.formTitle}>
                {renderRegistrationStepName()}
              </Typography>
              <form
                className={classes.form}
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    {(successAlert || errorAlert) && (
                      <Box component="div" className={classes.alertContainer}>
                        <Alert
                          severity={successAlert ? 'success' : 'error'}
                          onClose={() => closeAlert()}
                        >
                          {successAlert ? successAlert : errorAlert}
                        </Alert>
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container xs={12}>
                  {renderRegistrationForm()}
                  <Grid item xs={12}></Grid>
                  <Grid item sm={6} xs={12} className={classes.requiredHint}>
                    <Typography>
                      <FormattedMessage id="common.editor.required.message" />
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={6} xs={12}>
                    <Grid
                      container
                      className={classes.actionButtonWrapper}
                      spacing={3}
                    >
                      {!hasPrevousStep && (
                        <Grid item md={6} xs={12} justify="flex-end">
                          <Link
                            to={DEFAULT_SIGNIN_ROUTE}
                            className={classes.signLink}
                          >
                            <ActionButton
                              fullWidth
                              color="default"
                              disabled={isLoading}
                              className={classes.actionButton}
                            >
                              <FormattedMessage
                                id={'register.button.previous.label'}
                              />
                            </ActionButton>
                          </Link>
                        </Grid>
                      )}
                      {hasPrevousStep && (
                        <Grid item md={6} xs={12}>
                          <ActionButton
                            disabled={isLoading}
                            color="default"
                            onClick={handleNavigatePrevious}
                            className={classes.actionButton}
                          >
                            <FormattedMessage
                              id={'register.button.previous.label'}
                            />
                          </ActionButton>
                        </Grid>
                      )}
                      {hasNextStep && (
                        <Grid item md={6} xs={12} justify="flex-end">
                          <ActionButton
                            color="primary"
                            onClick={handleNavigateNext}
                            className={classes.actionButton}
                            disabled={
                              !isCurrentFormValid(formik.errors) || isLoading
                            }
                          >
                            <FormattedMessage
                              id={'register.button.next.label'}
                            />
                          </ActionButton>
                        </Grid>
                      )}
                      {!hasNextStep && (
                        <Grid item md={6} xs={12}>
                          <ActionButton
                            loading={isLoading}
                            color="primary"
                            type="submit"
                            disabled={!formik.isValid}
                            className={classes.actionButton}
                          >
                            <FormattedMessage
                              id={'register.button.submit.label'}
                            />
                          </ActionButton>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Box>
          )}
        </Paper>
      </Box>
    </PageContainer>
  );
};

export default Registration;
