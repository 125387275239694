import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';

import AuthProvider from './modules/AuthProvider';
import PageMetaProvider from './modules/PageMetaProvider';
import createApolloClient from './modules/apollo';
import { DefaultTheme } from './themes/DefaultTheme';
import Pages from './pages';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import 'date-fns';
import frLocale from 'date-fns/locale/fr';
import DateFnsUtils from '@date-io/date-fns';
import QRCodeProvider from './modules/QRCodeProvider';
import LocaleProvider from './modules/LocaleProvider';

const client = createApolloClient();

function App() {
  return (
    <SnackbarProvider maxSnack={3}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={DefaultTheme}>
          <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
            <AuthProvider>
              <QRCodeProvider>
                <LocaleProvider>
                  <PageMetaProvider>
                    <Pages />
                  </PageMetaProvider>
                </LocaleProvider>
              </QRCodeProvider>
            </AuthProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ApolloProvider>
    </SnackbarProvider>
  );
}

export default App;
