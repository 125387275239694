import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    marginBottom: theme.spacing(4),
  },
  paper: {
    width: theme.spacing(98),
    borderRadius: 8,
    alignContent: 'center',
    boxShadow: '0px 4px 80px rgba(0, 0, 0, 0.08)',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: theme.screenbreakpoint.smartphone,
      width: 'auto',
    },
  },
  toolbar: {
    marginTop: theme.spacing(2),
  },
  content: {
    position: 'relative',
    textAlign: 'center',
    padding: theme.spacing(4),
    justifyContent: 'center',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      padding: theme.spacing(3),
    },
  },
  printablePage: {
    position: 'relative',
    maxWidth: '902px',
    // minWidth: '512px',
  },
  contentLogo: {
    position: 'absolute',
    bottom: '7%',
    left: '0',
    right: '0',
    margin: 'auto',
  },
  vaccineCertImage: {
    width: '100%',
    height: 'auto',
    borderRadius: 8,
  },
  headerLogo: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    left: theme.spacing(4),
    '& img': {
      [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
        width: `${theme.spacing(12)} !important`,
        height: `${theme.spacing(12)} !important`,
      },
    },
  },
  headerLogoRight: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    right: theme.spacing(4),
    '& img': {
      [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
        width: `${theme.spacing(12)} !important`,
        height: `${theme.spacing(12)} !important`,
      },
    },
  },

  prmLogo: {
    '& img': {
      [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
        // width: theme.spacing(8),
      },
    },
  },
  marginPrimary: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      marginTop: theme.spacing(2),
    },
  },
  marginSecondary: {
    marginTop: theme.spacing(3),
  },
  mainTitle: {
    fontSize: '24px',
    fontWeight: 600,
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      fontSize: 14,
    },
  },
  mainSubTitle: {
    lineHeight: 0.6,
    fontSize: '24px',
    fontWeight: 400,
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      fontSize: 14,
      lineHeight: 0.6,
    },
  },
  mainSubTitleVirus: {
    lineHeight: 1.1,
    fontSize: '24px',
    fontWeight: 400,
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      fontSize: 14,
      lineHeight: 1.1,
    },
  },
  mainLabel: {
    fontStyle: 'italic',
  },
  mainValue: {
    fontWeight: 600,
    fontSize: '24px',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      fontSize: 17,
    },
  },
  mainLocalization: {
    fontWeight: 600,
    fontSize: '22px',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      fontSize: 17,
    },
  },
  contentQRCode: {
    flexGrow: 1,
    padding: theme.spacing(0, 5, 0, 5),
    height: theme.spacing(21),
    alignItems: 'center',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      padding: theme.spacing(3),
      height: theme.spacing(28),
    },
    position: 'relative',
  },
  footerLeftLogo: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    left: theme.spacing(4),
    '& canvas': {
      [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
        width: `${theme.spacing(12)} !important`,
        height: `${theme.spacing(12)} !important`,
      },
    },
  },
  signatureContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(690)]: {
      paddingLeft: theme.spacing(14),
    },
  },
  table: {
    width: 'auto',
    margin: '0 auto',
  },
  tableRow: {
    '&:last-child th, &:last-child td': {
      borderBottom: 0,
    },
    '& th': {
      fontWeight: 600,
    },
  },
  signatureText: {
    fontSize: '11px',
    fontStyle: 'italic',
    whiteSpace: 'nowrap',
    zIndex: 10,
    width: 'min-content',
    [theme.breakpoints.down(690)]: {
      whiteSpace: 'normal',
      width: 'fit-content',
    },
  },
  stamp: {
    width: '144px',
    height: '144px',
  },
  cellDose: {
    fontWeight: 500,
  },
  alertContainer: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  buttonDownload: {
    display: 'flex',
    alignContent: 'flex-end',
  },
  printBodyClass: {
    padding: theme.spacing(6),
    '& div > div.only-on-print': {
      display: 'block',
    },
    '& div.fix-print-padding': {
      padding: theme.spacing(3),
      paddingBottom: theme.spacing(0),
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0),
    },
    '& p.fix-print-font-size': {
      fontSize: '18px',
    },
  },
}));

export default styles;
