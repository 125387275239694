import TravalersPersonaImage from '../../assets/images/signin-persona-ext-travelers.svg';
import HealthWorkerPersonaImage from '../../assets/images/signin-persona-health-worker.svg';
import FairPeoplePersonaImage from '../../assets/images/signin-persona-fair-people.svg';
import GovSecurityPersonaImage from '../../assets/images/signin-persona-gov-security.svg';
import SocialWorkerPersonaImage from '../../assets/images/signin-persona-social-worker.svg';
import TeleOperatorImage from '../../assets/images/signin-persona-teleoperator.svg';

export const PERSONAS_PRIORITY = [
  {
    image: HealthWorkerPersonaImage,
    caption: 'signin.persona.healthworker.label',
  },
  {
    image: SocialWorkerPersonaImage,
    caption: 'signin.persona.socialworker.label',
  },
  {
    image: GovSecurityPersonaImage,
    caption: 'signin.persona.govsecurity.label',
  },
  {
    image: FairPeoplePersonaImage,
    caption: 'signin.persona.frailpeople.label',
  },
  { image: TravalersPersonaImage, caption: 'signin.persona.travelers.label' },
  { image: TeleOperatorImage, caption: 'signin.persona.teleoperator.label' },
];
