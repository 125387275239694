import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { Link, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MakerLogo from '../../../assets/images/egm-brand.png';

import useStyles from './styles';
import { DEFAULT_HOME_ROUTE, DEFAULT_SIGNIN_ROUTE } from '../../../consts';
import ActionButton from '../../../components/button/ActionButton';

const Unauthorized: FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();

  const handleGoBack = () => {
    history.push(DEFAULT_HOME_ROUTE);
  };

  return (
    <Box component="div" className={classes.root}>
      <Paper className={classes.paper} elevation={6}>
        <Typography variant="h4" className={classes.title}>
          <FormattedMessage id="error.title.error" />
        </Typography>
        <Box component="div" className={classes.container}>
          <Typography variant="h5">
            <FormattedMessage id="error.message.forbidden" />
          </Typography>
        </Box>
        <Box>
          <ActionButton
            autoFocus={false}
            color="primary"
            onClick={handleGoBack}
            className={classes.btn}
          >
            {intl.formatMessage({
              id: 'error.title.backToHome',
            })}
          </ActionButton>
        </Box>
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <Typography variant="body2" color="textSecondary" align="center">
              {'Copyright © '} {new Date().getFullYear()}
            </Typography>
          </Grid>
          <Grid item>
            <img className={classes.appMakerLogo} src={MakerLogo} />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Unauthorized;
