import React from 'react';
import { FetchResult, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { useErrorHanlder } from '../../../modules/hooks';
import { ApolloErrorCode } from '../../../consts';
import { CreateCitizenInput } from '../../../types/index';
import { CITIZEN_SUBSCRIBE } from '../mutation';
import {
  CitizenSubscribeMutation,
  CitizenSubscribeMutationVariables,
} from '../types/CitizenSubscribeMutation';

export const useCitizenSubscribeMutation = (
  options?: IMutationOptions,
): {
  createCitizenMutation: (
    createInput: CreateCitizenInput,
  ) => Promise<FetchResult<CitizenSubscribeMutation>>;
  createCitizenLoading: boolean;
  successAlert?: string | null;
  errorAlert?: string | null;
  closeAlert: () => void;
} => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useErrorHanlder();
  const [successAlert, setSuccessAlert] = React.useState<string | null>();
  const [errorAlert, setErrorAlert] = React.useState<string | null>();

  const closeAlert = () => {
    setErrorAlert(null);
    setSuccessAlert(null);
  };

  const [mutate, { loading: createCitizenLoading }] = useMutation<
    CitizenSubscribeMutation,
    CitizenSubscribeMutationVariables
  >(CITIZEN_SUBSCRIBE, {
    onCompleted: (data) => {
      if (data?.selfSubscribeCitizen?.citizen) {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'citizen.success.create.message',
          }),
          {
            variant: 'success',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          },
        );
        setSuccessAlert(
          intl.formatMessage({
            id: 'citizen.success.create.message',
          }),
        );
        options?.onSuccess?.();
      }
    },
    onError: (error) => {
      handleError(error, [
        {
          code: ApolloErrorCode.CITIZEN_EXISTS,
          message: intl.formatMessage({
            id: 'citizen.error.exists.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'citizen.error.create.unknown.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.CITIZEN_CREATION_FAILED,
          message: intl.formatMessage({
            id: 'citizen.error.create.failed.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.INVALID_PHONE_NUMBER,
          message: intl.formatMessage({
            id: 'common.editor.phonenumber.invalid.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.INVALID_EMAIL_ADDRESS,
          message: intl.formatMessage({
            id: 'common.editor.email.not.trusted.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.NON_AUTHORIZED_OPERATION,
          message: intl.formatMessage({
            id: 'error.message.forbidden',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.HEALTH_REGISTRATION_FAILED,
          message: intl.formatMessage({
            id: 'citizen.error.health.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.JOB_REGISTRATION_FAILED,
          message: intl.formatMessage({
            id: 'citizen.error.job.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.ADDRESS_REGISTRATION_FAILED,
          message: intl.formatMessage({
            id: 'citizen.error.address.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.PCR_TEST_NOT_ACCEPTED,
          message: intl.formatMessage({
            id: 'registration.test.positive.not.allowed.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
      ]);
    },
  });

  const createCitizenMutation = (
    createInput: CreateCitizenInput,
  ): Promise<FetchResult<CitizenSubscribeMutation>> => {
    closeAlert();
    return mutate({
      variables: {
        input: {
          firstName: createInput.firstName?.trim?.(),
          lastName: createInput.lastName?.toUpperCase?.()?.trim?.(),
          gender: createInput.gender,
          email: createInput.email,
          phoneNumber: createInput.phoneNumber,

          birthDate: format(new Date(createInput.birthDate), 'yyyy-MM-dd'),
          cniNum: createInput.cniNum,

          comorbidityId: createInput.comorbidityId,
          comorbidityName: createInput.comorbidityName,
          healthCenterId: createInput.healthCenterId,
          lastPcrTest: createInput.lastPcrTest,
          desiredVaccineId: createInput.desiredVaccineId,

          districtId: createInput.districtId,
          municipalityId: createInput.municipalityId,
          fokontanyId: createInput.fokontanyId,
          addressName: createInput.addressName,

          jobId: createInput.jobId,
          jobName: createInput.jobName,
          offRegNum: createInput.offRegNum,
          profRegNum: createInput.profRegNum,
          cnapsId: createInput.cnapsId,
          employerName: createInput.employerName,
          covaxPriority: 1,

          appointmentDate: format(new Date(), 'yyyy-MM-dd'),
        },
      },
    });
  };

  return {
    successAlert,
    errorAlert,
    closeAlert,
    createCitizenMutation,
    createCitizenLoading,
  };
};
