export const REACT_APP_API_URL =
  process.env.REACT_APP_API_URL ?? 'http://localhost:8888/graphql';
export const REACT_APP_REST_URL =
  process.env.REACT_APP_REST_URL ?? 'http://localhost:8888';
export const REACT_APP_WS_URL =
  process.env.REACT_APP_WS_URL ?? 'http://localhost:8888';
export const REACT_APP_SOCKETIO_PATH =
  process.env.REACT_APP_SOCKETIO_PATH ?? '/stream/';
export const REACT_APP_HEALTH_PROFILE_URL =
  process.env.REACT_APP_HEALTH_PROFILE_URL ?? 'http://localhost:3000';
export const REACT_PUBLIC_URL = process.env.PUBLIC_URL ?? '';
