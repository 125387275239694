import React from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import PublicRoute from '../components/PublicRoute';
import PrivateRoute from '../components/PrivateRoute';
import Unauthorized from './ErrorPages/Unauthorized';
import Registration from './Registration';
import SignIn from './SignIn';
import Profile from './Profile';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import VaccinationCard from './VaccinationCard';
import Faq from './Faq';
import ChoiceCertificate from './ChoiceCertificate';
import TestCard from './TestCard';

const Pages = () => (
  <HashRouter>
    <Switch>
      <PrivateRoute exact path="/" component={Profile} />
      <PublicRoute exact path="/registration" component={Registration} />
      <PublicRoute exact path="/signin" component={SignIn} />
      <PublicRoute path="/error/forbidden" component={Unauthorized} />
      <PublicRoute path="/forgot-password" component={ForgotPassword} />
      <PublicRoute
        exact
        path="/reset-password/:resetToken"
        component={ResetPassword}
      />
      <Route path="/vaccination-card/:accessKey" component={VaccinationCard} />
      <Route path="/c/:accessKey" component={VaccinationCard} />
      <Route path="/t/:accessKey" component={TestCard} />
      <Route
        path="/choice-certificate/:accessKey"
        component={ChoiceCertificate}
      />
      <Route path="/faq" component={Faq} />
      <Route path="" component={() => <Redirect to="/signin" />} />
    </Switch>
  </HashRouter>
);

export default Pages;
