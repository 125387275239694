import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: theme.spacing(5),
  },
  appLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  appLogo: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  appMakerLogo: {
    height: theme.spacing(4),
    width: theme.spacing(12),
  },
  loginCaption: {
    textAlign: 'center',
    padding: theme.spacing(2, 0, 2, 0),
    fontSize: '24px',
    fontWeight: 300,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  paper: {
    width: theme.spacing(70),
    padding: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formBox: {
    marginTop: theme.spacing(1),
    '& .MuiTextField-root': {
      margin: theme.spacing(2, 0, 1, 0),
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: theme.spacing(1),
    },
  },
  submitButtom: {
    margin: theme.spacing(3, 0, 0),
  },
  controlsWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  forgotPasswordLink: {
    padding: theme.spacing(1, 0, 1, 0),
  },
  alertContainer: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  registerLink: {
    display: 'block',
    padding: theme.spacing(0, 0, 2, 0),
    textDecoration: 'none',
  },
  title: {
    marginBottom: theme.spacing(7),
    color: theme.palette.secondary.main,
  },
  btn: {
    background: theme.palette.secondary.main,
    marginBottom: theme.spacing(3),
    '& *': {
      color: 'white',
    },
  },
}));

export default styles;
