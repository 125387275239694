import React, { FC, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Alert } from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import { DEFAULT_SIGNIN_ROUTE } from '../../consts';
import ActionButton from '../../components/button/ActionButton';
import { usePageMetaContext } from '../../modules/PageMetaProvider';
import PageContainer from '../../components/page/PageContainer';
import { useForgotPassword } from '../../graphql/authentication/hooks';
import { HealthProfileForgotPassword_healthProfileForgotPassword } from '../../graphql/authentication/types/HealthProfileForgotPassword';
import TextInputField from '../../components/inputfields/TextInputField';
import useStyles from './styles';
import { useFormik } from './formik';
import ForgotPasswordAckPaper from './ForgotPasswordAckPaper';

const ForgotPassword: FC = () => {
  const intl = useIntl();

  // Page context handler
  const { setPathLabels } = usePageMetaContext();
  const classes = useStyles();
  const [showAckForm, setShowAckForm] = useState<boolean>(false);
  const [theEmailAddress, setTheEmailAddress] = useState<string>();

  // Update page context
  useEffect(() => {
    setPathLabels([
      intl.formatMessage({
        id: 'forgotpassword.title.label',
      }),
    ]);
  }, []);

  const {
    forgotPasswordLoading: isLoading,
    forgotPasswordMutation,
    closeAlert,
    errorAlert,
  } = useForgotPassword({
    onSuccess: (
      healthProfileForgotPassword?: HealthProfileForgotPassword_healthProfileForgotPassword,
    ) => {
      if (healthProfileForgotPassword) {
        setShowAckForm(true);
        setTheEmailAddress(healthProfileForgotPassword.emailAddress);
      }
    },
  });

  // Of formik as form state handler
  const formik = useFormik({
    onSubmit: async (values, helper) => {
      forgotPasswordMutation(values.username);
    },
  });

  return (
    <PageContainer showBackground={true}>
      <Box component="div" className={classes.root}>
        <Paper className={clsx(classes.paper, { [classes.ackPaper]: true })}>
          {theEmailAddress && showAckForm ? (
            <ForgotPasswordAckPaper
              theUsername={formik.values.username}
              theEmailAddress={theEmailAddress}
            />
          ) : (
            <Box component="div" className={classes.container}>
              <Typography
                component="h1"
                variant="h4"
                className={classes.formTitle}
              >
                {intl.formatMessage({
                  id: 'forgotpassword.title.label',
                })}
              </Typography>
              <Typography className={classes.messages}>
                {intl.formatMessage({
                  id: 'forgotpassword.hint.message',
                })}
              </Typography>
              {errorAlert && (
                <Box component="div" className={classes.alertContainer}>
                  <Alert severity="error" onClose={() => closeAlert()}>
                    {errorAlert}
                  </Alert>
                </Box>
              )}
              <form
                className={classes.formBox}
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <TextInputField
                  name="username"
                  id="username"
                  required
                  label={intl.formatMessage({
                    id: 'forgotpassword.id.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'forgotpassword.id.label',
                  })}
                  fullWidth
                  disabled={isLoading}
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.username && Boolean(formik.errors.username)
                  }
                  helperText={formik.touched.username && formik.errors.username}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Grid item xs={12}>
                  <ActionButton
                    fullWidth
                    loading={isLoading}
                    color="primary"
                    type="submit"
                    className={classes.actionButton}
                    disabled={!formik.isValid}
                  >
                    <FormattedMessage
                      id={'forgotpassword.reset.mypassword.label'}
                    />
                  </ActionButton>
                </Grid>
              </form>
              <Grid item xs={12}>
                <Link to={DEFAULT_SIGNIN_ROUTE} className={classes.signLink}>
                  <ActionButton
                    fullWidth
                    color={'secondary'}
                    disabled={isLoading}
                    className={classes.actionButton}
                  >
                    <FormattedMessage
                      id={'forgotpassword.reset.cancel.label'}
                    />
                  </ActionButton>
                </Link>
              </Grid>
            </Box>
          )}
        </Paper>
      </Box>
    </PageContainer>
  );
};

export default ForgotPassword;
