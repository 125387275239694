import {
  FormikErrors,
  FormikHelpers,
  useFormik as useBaseFormik,
} from 'formik';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

interface ResetPasswordVariables {
  newPassword: string;
  confirmPassword: string;
}

interface Config {
  onSubmit: (
    values: ResetPasswordVariables,
    formikHelpers: FormikHelpers<ResetPasswordVariables>,
  ) => void;
}

export const useFormik = (config: Config) => {
  const intl = useIntl();

  const validationSchema = yup.object({
    newPassword: yup
      .string()
      .min(
        4,
        intl.formatMessage({
          id: 'changepassword.error.min.message',
        }),
      )
      .max(
        32,
        intl.formatMessage({
          id: 'changepassword.error.max.message',
        }),
      )
      .required(
        intl.formatMessage({
          id: 'changepassword.error.newpassword.required.message',
        }),
      ),
    confirmPassword: yup.string().required(
      intl.formatMessage({
        id: 'changepassword.error.confirmpassword.required.message',
      }),
    ),
  });

  const formik = useBaseFormik<ResetPasswordVariables>({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    validate: (values: ResetPasswordVariables) => {
      const errors: FormikErrors<ResetPasswordVariables> = {};
      if (
        values.newPassword &&
        values.confirmPassword &&
        values.newPassword !== values.confirmPassword
      ) {
        errors.confirmPassword = intl.formatMessage({
          id: 'changepassword.error.confirmpassword.notmatch.message',
        });
      }

      return errors;
    },
    ...config,
  });

  return formik;
};
