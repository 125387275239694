export default {
  'register.title.label': 'Misoratra anarana',
  'register.button.submit.label': 'Enregistrer',
  'register.button.previous.label': 'Teo aloha',
  'register.button.next.label': 'Manaraka',
  'register.link.label': 'Misoratra anarana',
  'register.hint.label': 'Tsy mbola manana kaonty ?',
  'registration.validation.notification':
    "En attente de validation par l'admin...",
  'registration.validation.notification.title': 'Notification',

  'registration.tips.primary.title': 'VAKSINY COVID-19',
  'registration.tips.secondary.title':
    'Comment <br/>puis-je prendre <br/>rendez-vous?',

  'register.step.personalinfo.title.label':
    "Fampahalalana manokana (Dingana 1 amin'ny 3)",
  'register.step.jobinfo.title.label': "Momban'ny asa (dingana 2 amin'ny 3)",
  'register.step.healthinfo.title.label':
    'Fampahalalana momba ny fahasalamana (Dingana farany)',
  'registration.enrollment.process.job.label': "Momban'ny asa",
  'registration.enrollment.process.health.label':
    'Fampahalalana momba ny fahasalamana',
  'registration.enrollment.process.appointment.label': 'Prise de rendez-vous',
  'registration.enrollment.process.ackconfirm.label':
    'Confirmation de réception',

  'registration.test.positive.not.allowed.message':
    'Test positif moins de 3 mois, vous ne devez pas vous faire vacciner',
  'registration.ack.title.label': 'Fanamafisana ny fandraisana',
  'registration.ack.message.label': `
    <p>Voaray ny fangatahanao hisitraka ny vaksiny miaro amin’ny Covid-19. Aza miandry fa manatona haingana toerana fanaovam-baksiny.</p>
    <p>Raha mila fanazavana fanampiny dia antsoy ny laharana 910 na mandefasa mailaka ho any amin’ny <a href="mailto:vaccinationcovid19@googlegroups.com">vaccinationcovid19@googlegroups.com</a>.</p><br/>
    <p>Misaotra anao amin'ny fahazotoana hanao vaksiny miady amin'ny COVID-19.<br/>Ny fanaovana vaksiny dia:</p>
    <ol>
    <li><p>Miaro amin'ny fototr'aretina noho izany mamonjy aina ary misoroka ny fahatanjahan'ny fesehon'aretina eo amin'ny fahasalaman'ny ozatra, havokavoka, fo,...</p></li>
    <li><p>Manampy ny rafitra ara-pahasalamana amin'ny alàlan'ny fanamaivanana ny asan'ireo mpitsabo.</p></li>
    <li><p>Miaro ireo tena marefo hahazoana hery fiarovana hikambanana.</p></li>
    </ol>
    `,
};
