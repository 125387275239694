export default {
  'resetpassword.title.label': 'Famerenana ny teny miafina hadino',
  'resetpassword.password.label': 'Teny miafina vaovao',
  'resetpassword.confirm.password.label': 'Avereno eto ilay teny miafina',
  'resetpassword.newpassword.invalid.message':
    "Tsy ekena io teny miafina io. Tokony hisy soratra miisa 4 ka hatramin'ny 32 ny halavany.",
  'resetpassword.confirmpassword.invalid.message':
    'Tsy ekena io teny miafina io. Tsy mitovy ny teny miafina vaosoratrao.',
  'resetpassword.save.label': 'Mitahiry',
  'resetpassword.cancel.label': 'Miala',
  'resetpassword.ack.title.label': 'Tontosa ny fanovana teny miafina nataonao',
  'resetpassword.ack.gologin.label': "Miverina any amin'ny pejy fandraisana",
  'resetpassword.ack.message.label':
    "Voatahiry ny teny miafina vaovao. Afaka miditra amin'ny kaontinao amin'izay ianao.",
};
