export default {
  'common.card.back.to.profile': 'Retour au profil',
  'common.vaccine.card.download': 'Télécharger le certificat de vaccination',
  'common.test.card.download': 'Télécharger le certificat de test COVID-19',
  'common.card.title': 'KARATRA FANAMARINANA VAKSINY',
  'common.card.title.certificate': 'CERTIFICAT DE VACCINATION',
  'common.card.title.virus': 'COVID-19',
  'common.card.label.fullname.title': 'Anarana feno - Nom complet - Full name',
  'common.card.label.bitrh.title':
    'Daty nahaterahana - Date de naissance - Date of birth',
  'common.card.label.address.title': 'Adiresy - Addresse - Address',
  'common.card.label.country.title': 'Firenena - Pays - Country',
  'common.card.label.vaccine.statut.title': 'Sata - Statut - Status',
  'common.card.signature.1':
    "Voamarina aradalana teo anivon'ny <b>Ministeran'ny Fahasalamam-bahoaka</b>",
  'common.card.signature.2':
    'Certifié par le <b>Ministère de la santé publique</b>',
  'common.card.signature.3': 'Certified by the <b>Public Heath Ministry</b>',
  'common.card.epi.not.found.title': 'Numéro EPI non fourni',
  'common.card.certificate.not.ready.message':
    "<b>Si vous n'êtes pas encore vaccinés, vous ne pouvez pas demander votre certificat.</b><br/><br/>Pour les personnes vaccinées, l'enrichissement des données relatives aux vaccins dans la plateforme <b>peut prendre 2 jours</b>.<br/><br/>Nous vous invitons à revérifier votre certificat après ce délai. Nous vous remercions pour votre compréhension.",
  'common.card.test.covid.certificate.not.ready.message':
    "<b>Si vous n'êtes pas encore testés, vous ne pouvez pas demander votre certificat.</b>",
  'common.card.epi.not.found.label':
    "Impossible de récupérer le certificat du citoyen. Le <b>numéro EPI</b> n'est pas fourni",
  'common.card.invalid.access.message':
    "Vous ne pouvez pas accéder à cette page ou le lien que vous avez essayé d'utiliser a peut-être expiré.",
  'common.card.vaccined.statut.only.one.dose': 'Première dose administrée',
  'common.card.vaccined.statut.two.dose': 'Vacciné(e)',
};
