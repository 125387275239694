import { gql } from '@apollo/client';
import {
  MUNICIPALITY_INFO_FRAGMENT,
  ADDRESS_INFO_FRAGMENT,
  FOKONTANY_INFO_FRAGMENT,
  REGION_INFO_FRAGMENT,
  DISTRICT_INFO_FRAGMENT,
} from './fragment';

export const GET_ALL_REGION = gql`
  query GetAllRegion {
    regions {
      ...RegionInfoFragment
    }
  }
  ${REGION_INFO_FRAGMENT}
`;

export const GET_ALL_DISTRICT = gql`
  query GetAllDistrict {
    districts {
      ...DistrictInfoFragment
    }
  }
  ${DISTRICT_INFO_FRAGMENT}
`;

export const GET_DISTRICTS_BY_REGION = gql`
  query GetDistrictsByRegion($regionId: ID!) {
    districtsByRegion(regionId: $regionId) {
      ...DistrictInfoFragment
    }
  }
  ${DISTRICT_INFO_FRAGMENT}
`;

export const GET_ALL_MUNICIPALITY = gql`
  query GetAllMunicipality {
    municipalities {
      ...MunicipalityInfoFragment
    }
  }
  ${MUNICIPALITY_INFO_FRAGMENT}
`;

export const GET_MUNICIPALITIES_BY_DISTRICT = gql`
  query GetMunicipalitiesByDistrict($districtId: ID!) {
    municipalities(districtId: $districtId) {
      ...MunicipalityInfoFragment
    }
  }
  ${MUNICIPALITY_INFO_FRAGMENT}
`;

export const GET_FOKONTANIES_BY_MUNICIPALITY = gql`
  query GetFokontaniesByMunicipality($municipalityId: ID!) {
    fokontaniesByMunicipality(municipalityId: $municipalityId) {
      ...FokontanyInfoFragment
    }
  }
  ${FOKONTANY_INFO_FRAGMENT}
`;

export const GET_ALL_ADDRESS = gql`
  query GetAllAddress($fokontanyId: ID!) {
    addresses(fokontanyId: $fokontanyId) {
      ...AddressInfoFragment
    }
  }
  ${ADDRESS_INFO_FRAGMENT}
`;
