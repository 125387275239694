import { makeStyles } from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down(theme.screenbreakpoint.tablet + 340)]: {
      marginBottom: theme.spacing(7),
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone + 134)]: {
      flexDirection: 'column',
    },
  },
  tipsHintImage: {
    height: theme.spacing(22),
    width: theme.spacing(23),
  },
  titleWrapper: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      marginLeft: theme.spacing(4),
    },
  },
  primaryTitle: {
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '19px',
    lineHeight: '23px',
    letterSpacing: '4px',
    color: theme.colors.text.tipsPrimaryTitle,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone + 134)]: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
    },
  },
  secondaryTitle: {
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '33px',
    lineHeight: '37px',
    color: theme.colors.text.tipsSecondaryTitle,
    [theme.breakpoints.down(theme.screenbreakpoint.large + 1)]: {
      fontSize: '28px',
      lineHeight: '35px',
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      fontSize: '24px',
      lineHeight: '32px',
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone + 134)]: {
      textAlign: 'center',
    },
  },
}));
