import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useForgotPassword } from '../../../graphql/authentication/hooks';

import ActionButton from '../../../components/button/ActionButton';
import useStyles from './styles';

interface IForgotPasswordAckPaperProps {
  theUsername: string;
  theEmailAddress: string;
}

const ForgotPasswordAckPaper: FC<IForgotPasswordAckPaperProps> = ({
  theUsername,
  theEmailAddress,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { forgotPasswordLoading: isLoading, forgotPasswordMutation } =
    useForgotPassword();

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>
        {intl.formatMessage({
          id: 'forgotpassword.ack.title.label',
        })}
      </Typography>
      <Typography
        className={classes.centent}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'forgotpassword.ack.message.label1',
          }),
        }}
      />
      <Typography className={classes.centent}>
        {intl.formatMessage(
          {
            id: 'forgotpassword.ack.message.label2',
          },
          {
            theEmailAddress: theEmailAddress,
          },
        )}
      </Typography>
      <Typography
        className={classes.centent}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'forgotpassword.ack.message.label3',
          }),
        }}
      />
      <Grid item md={6} xs={12}>
        <ActionButton
          loading={isLoading}
          color="primary"
          type="button"
          className={classes.actionButton}
          onClick={() => {
            forgotPasswordMutation(theUsername);
          }}
        >
          <FormattedMessage id={'forgotpassword.ack.resend.email.label'} />
        </ActionButton>
      </Grid>
    </Box>
  );
};

export default ForgotPasswordAckPaper;
