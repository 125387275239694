import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useStyles from './styles';

const RegistrationAckPaper: FC = () => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>
        {intl.formatMessage({
          id: 'registration.ack.title.label',
        })}
      </Typography>
      <Typography
        className={classes.centent}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'registration.ack.message.label',
          }),
        }}
      />
    </Box>
  );
};

export default RegistrationAckPaper;
