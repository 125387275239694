import { gql } from '@apollo/client';
import { CITIZEN_INFO_FRAGMENT } from './fragment';

export const CITIZEN_SUBSCRIBE = gql`
  mutation CitizenSubscribeMutation($input: CreateCitizenInput!) {
    selfSubscribeCitizen(input: $input) {
      citizen {
        ...AllCitizenInfoFragment
      }
    }
  }
  ${CITIZEN_INFO_FRAGMENT}
`;

export const CITIZEN_EDIT_PROFILE = gql`
  mutation CitizenEditProfileMutation($input: UpdateCitizenProfileInput!) {
    updateCitizenProfile(input: $input) {
      citizen {
        ...AllCitizenInfoFragment
      }
    }
  }
  ${CITIZEN_INFO_FRAGMENT}
`;

export const CITIZEN_UPLOAD_PIC = gql`
  mutation CitizenUpdateProfilePicMutation($thePic: Upload!) {
    updateProfilePic(thePic: $thePic) {
      message
      photo
      success
    }
  }
`;

export const CITIZEN_SIGN_ID = gql`
  mutation CitizenSignIdMutation($citizenId: String!) {
    healthProfileSignInCitizenId(citizenId: $citizenId) {
      accessKey
    }
  }
`;
