import { ApolloError } from '@apollo/client';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';

import { NETWORK_ERROR, ApolloErrorCode } from '../../consts';

interface ApolloErrorHandler {
  code: ApolloErrorCode | typeof NETWORK_ERROR;
  message?: string;
  callback?: (error?: ApolloError, displayMessage?: string) => void;
  silent?: boolean;
}

const mergeErrorHandler = (
  destination: ApolloErrorHandler,
  source: ApolloErrorHandler,
) => ({
  code: destination.code,
  message: source.message || destination.message,
  callback: source.callback || destination.callback,
  silent: source.silent || destination.silent || false,
});

export const useErrorHanlder = (
  defaultMessages: ApolloErrorHandler[] = [],
): ((error: ApolloError, messages?: ApolloErrorHandler[]) => void) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const commonMessages: ApolloErrorHandler[] = [
    {
      code: NETWORK_ERROR,
      message: intl.formatMessage({
        id: 'error.message.networkError',
        defaultMessage:
          'Veuillez vérifier votre connexion Internet et réessayer.',
      }),
    },

    {
      code: ApolloErrorCode.UKNOWN_ERROR,
      message: intl.formatMessage({
        id: 'error.message.unknow',
        defaultMessage: 'An unknown error occured.',
      }),
    },

    {
      code: ApolloErrorCode.INTERNAL_SERVER_ERROR,
      message: intl.formatMessage({
        id: 'error.message.internalServerError',
        defaultMessage: 'Internal server error',
      }),
    },

    {
      code: ApolloErrorCode.UNAUTHENTICATED,
      message: intl.formatMessage({
        id: 'error.message.unauthenticated',
        defaultMessage: 'You must be logged in to perform this operation.',
      }),
    },

    {
      code: ApolloErrorCode.FORBIDDEN,
      message: intl.formatMessage({
        id: 'error.message.forbidden',
        defaultMessage: 'You are not authorized to perform this action.',
      }),
    },

    {
      code: ApolloErrorCode.ARGS_TYPE_ERROR,
      message: intl.formatMessage({
        id: 'error.message.args.type',
        defaultMessage: 'Wrong type of arguments.',
      }),
    },
  ];

  return (error: ApolloError, messages: ApolloErrorHandler[] = []) => {
    const getCurrentErrorCodeMessage = (
      apolloErrorCode: ApolloErrorCode | typeof NETWORK_ERROR,
    ): ApolloErrorHandler => {
      let message = '';
      let callback:
        | ((error?: ApolloError, displayMessage?: string) => void)
        | undefined = undefined;

      for (const errorHandler of [
        ...messages,
        ...defaultMessages,
        ...commonMessages,
      ]) {
        if (errorHandler.code === apolloErrorCode) {
          message = errorHandler.message || message;
          callback = errorHandler.callback || callback;
        }

        if (message)
          return {
            code: apolloErrorCode,
            message,
            callback,
            silent: errorHandler.silent,
          };
      }

      return { code: apolloErrorCode, message, callback, silent: false };
    };

    let handler: ApolloErrorHandler = getCurrentErrorCodeMessage(
      ApolloErrorCode.UKNOWN_ERROR,
    );

    if (error.graphQLErrors?.length) {
      const currentError = error.graphQLErrors[0];
      const currentErrorCode = currentError.extensions?.code;

      if (currentErrorCode) {
        const currentHandler: ApolloErrorHandler =
          getCurrentErrorCodeMessage(currentErrorCode);

        handler = mergeErrorHandler(handler, currentHandler);
      }
    } else if (error.networkError) {
      handler = mergeErrorHandler(
        handler,
        getCurrentErrorCodeMessage(NETWORK_ERROR),
      );
    }

    if (handler.callback) handler.callback(error, handler.message ?? undefined);

    if (!handler.silent)
      enqueueSnackbar(handler.message || '', {
        variant: 'error',
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
      });
  };
};
