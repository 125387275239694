import React, { FC, useState } from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import useStyles from './styles';

interface IProps {
  question: string;
  answer: string;
}

const FaqItem: FC<IProps> = ({ question, answer }) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const classes = useStyles({ open: isOpen });

  const toggleOpen = () => {
    setOpen((currentState) => !currentState);
  };
  return (
    <Box component="div" className={classes.root}>
      <Box className={classes.boxHead}>
        <Typography className={classes.typoQuestion}>{question}</Typography>
        <IconButton aria-label="dopdown" size="small" onClick={toggleOpen}>
          {isOpen ? (
            <RemoveCircleOutlineIcon className={classes.dropDownIcon} />
          ) : (
            <AddCircleOutlineIcon className={classes.dropDownIcon} />
          )}
        </IconButton>
      </Box>
      <Collapse in={isOpen}>
        <Typography
          className={classes.typoAnswer}
          dangerouslySetInnerHTML={{
            __html: answer,
          }}
        />
      </Collapse>
    </Box>
  );
};

export default FaqItem;
