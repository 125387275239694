import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  popper: {},

  tooltip: {
    maxWidth: '300px',
    margin: '0px',
    padding: '10px 12px',
    boxSizing: 'border-box',

    borderRadius: '3px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 3px 6px #00000029',
  },

  text: {
    textAlign: 'left',
    fontFamily: theme.fonts.default,
    fontSize: '14px',
    letterSpacing: '0.4px',
    lineHeight: '18px',
    color: theme.colors.text.black03,
    textTransform: 'none',
  },
}));
