import React from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import { ApolloErrorCode } from '../../../consts';
import { useErrorHanlder } from '../../../modules/hooks';
import { CITIZEN_VACCINATION_PROFILE } from '../query';
import {
  CitizenVaccinationProfile,
  CitizenVaccinationProfileVariables,
  CitizenVaccinationProfile_citizenProfile,
} from '../types/CitizenVaccinationProfile';

export const useCitizenVaccinationProfile = (
  accessKey?: string,
): {
  isLoading: boolean;
  citizenProfile?: CitizenVaccinationProfile_citizenProfile;
  errorAlert?: string | null;
  closeAlert: () => void;
} => {
  const intl = useIntl();
  const handleError = useErrorHanlder();
  const [errorAlert, setErrorAlert] = React.useState<string | null>();

  const closeAlert = () => {
    setErrorAlert(null);
  };

  const { data, loading } = useQuery<
    CitizenVaccinationProfile,
    CitizenVaccinationProfileVariables
  >(CITIZEN_VACCINATION_PROFILE, {
    fetchPolicy: 'network-only',
    skip: !accessKey,
    variables: { accessKey: accessKey ?? '' },
    onError: (error) => {
      handleError(error, [
        {
          code: ApolloErrorCode.INVALID_ACCESS_KEY,
          message: intl.formatMessage({
            id: 'common.card.invalid.access.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.CITIZEN_NOT_FOUND,
          message: intl.formatMessage({
            id: 'citizen.error.notfound.label',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'error.loading.label',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
      ]);
    },
  });

  return {
    errorAlert,
    closeAlert,
    isLoading: loading,
    citizenProfile: data?.citizenProfile ?? undefined,
  };
};
