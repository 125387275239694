import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useStyles from './styles';
import { Grid, Typography } from '@material-ui/core';
import ActionButton from '../../../components/button/ActionButton';
import { Link } from 'react-router-dom';

interface ITooltipsCard {
  close?: any;
  isLoading?: boolean;
}

const TooltipsCard: FC<ITooltipsCard> = ({
  close: close,
  isLoading: isParentFormLoading,
}) => {
  const intl = useIntl();
  const isLoading = isParentFormLoading;
  const classes = useStyles();
  return (
    <>
      <Link to="#" className={classes.close} onClick={close}>
        &times;
      </Link>
      <Grid className={classes.modal}>
        <Grid className={classes.content}>
          <Grid className={classes.title}>
            <FormattedMessage id="tips.card.label" />
          </Grid>
          <a
            href="https://www.youtube.com/watch?v=n4_cR2cmI7Q&list=PLEwWTMGh-GkXxK61DfwVvIdm7XHqoPy6I"
            target="_blank"
            rel="noreferrer"
          >
            <ActionButton
              autoFocus={false}
              color="primary"
              className={classes.button}
            >
              {intl.formatMessage({
                id: 'tips.card.video.label',
              })}
            </ActionButton>
          </a>
        </Grid>
      </Grid>
    </>
  );
};

export default TooltipsCard;
