import React from 'react';
import { TextFieldProps } from '@material-ui/core';
import TextInputField from '../TextInputField';
import RealAutoCompleteField from './RealAutoCompleteField';

export interface IAutoCompleteFieldProps<T> {
  id?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  freeSolo?: boolean;
  items: T[];
  onSelectionChange?: (itemKey?: string) => void;
  getItemLabel?: (item?: T | null) => string;
  getItemKey?: (item?: T | null) => string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  isLoading?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  textFieldProps?: TextFieldProps;
}

export default function AutoCompleteField<T>(
  props: IAutoCompleteFieldProps<T>,
): React.ReactElement {
  const {
    items,
    onSelectionChange,
    required = false,
    multiline = false,
    disabled = false,
    label,
    placeholder,
    name,
    error,
    helperText,
    onBlur,
    defaultValue,
    textFieldProps = {},
  } = props;

  if (!items.length) {
    return (
      <TextInputField
        multiline={multiline}
        rowsMax={4}
        required={required}
        name={name}
        label={label}
        placeholder={placeholder}
        error={error}
        helperText={helperText}
        onBlur={onBlur}
        value={defaultValue ?? ''}
        disabled={!onSelectionChange || disabled}
        fullWidth
        onChange={(event) => {
          onSelectionChange?.(event.target.value);
        }}
        {...textFieldProps}
      />
    );
  }

  return <RealAutoCompleteField {...props} />;
}
