import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  modal: {
    fontSize: '12px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    textAlign: 'center',
    display: 'flex',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
  },
  content: {
    width: '100%',
    padding: theme.spacing(2),
    fontFamily: theme.fonts.default,
    '& a': {
      textDecoration: 'none',
      color: theme.colors.text.tipsSecondaryTitle,
    },
  },
  title: {
    fontSize: '20px',
    fontWeight: 800,
    color: theme.colors.text.tipsPrimaryTitle,
    marginBottom: '20px',
  },
  subTitle: {
    fontStyle: 'italic',
  },
  button: {
    margin: 'auto',
  },
  buttonClose: {
    margin: 'auto',
    backgroundColor: '#FB4D3D',
  },
  notice: {
    fontSize: '12px',
  },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    padding: '1px 9px 5px',
    right: '-10px',
    top: '-10px',
    fontSize: '20px',
    background: '#f4364c',
    borderRadius: '18px',
    textDecoration: 'none',
    color: '#fff',
    fontWeight: 800,
  },
}));

export default styles;
