import { gql } from '@apollo/client';
import { VACCINE_META_INFO_FRAGMENT } from './fragment';

export const GET_ALL_VACCINE_META = gql`
  query GetAllVaccineMeta {
    vaccines {
      ...VaccineMetaInfoFragment
    }
  }
  ${VACCINE_META_INFO_FRAGMENT}
`;
