/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Gender for citizen and user
 */
export enum EnumGender {
  FEMALE = "FEMALE",
  MALE = "MALE",
}

/**
 * Arguments to create a Citizen.
 */
export interface CreateCitizenInput {
  lastName: string;
  firstName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  gender: EnumGender;
  birthDate?: any | null;
  birthPlace?: string | null;
  cniNum?: string | null;
  cniDeliveryDate?: any | null;
  cniDeliveryPlace?: string | null;
  addressId?: string | null;
  jobId: string;
  jobName?: string | null;
  otherJobId?: string | null;
  employerId?: string | null;
  employerName?: string | null;
  offRegNum?: string | null;
  profRegNum?: string | null;
  cnapsId?: string | null;
  workplace?: string | null;
  healthCenterId?: string | null;
  comorbidityId?: string | null;
  comorbidityName?: string | null;
  covaxPriority?: number | null;
  municipalityId: string;
  districtId?: string | null;
  fokontanyId: string;
  addressName?: string | null;
  lastPcrTest?: boolean | null;
  appointmentDate?: any | null;
  desiredVaccineId?: string | null;
}

/**
 * Arguments to update a Citizen profile.
 */
export interface UpdateCitizenProfileInput {
  id: string;
  birthDate?: any | null;
  cniNum?: string | null;
  municipalityId: string;
  fokontanyId: string;
  addressName?: string | null;
  jobId: string;
  jobName?: string | null;
  employerName?: string | null;
  offRegNum?: string | null;
  profRegNum?: string | null;
  cnapsId?: string | null;
  healthCenterId?: string | null;
  lastPcrTest?: boolean | null;
  comorbidityId?: string | null;
  comorbidityName?: string | null;
  desiredVaccineId?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
