import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Link } from 'react-router-dom';

import { DEFAULT_SIGNIN_ROUTE } from '../../../consts';
import ActionButton from '../../../components/button/ActionButton';
import useStyles from './styles';

const ResetPasswordAckPaper: FC = () => {
  const intl = useIntl();
  const classes = useStyles();
  const isLoading = false;

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>
        {intl.formatMessage({
          id: 'resetpassword.ack.title.label',
        })}
      </Typography>
      <Typography
        className={classes.centent}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'resetpassword.ack.message.label',
          }),
        }}
      />
      <Grid item xs={12}>
        <Link to={DEFAULT_SIGNIN_ROUTE} className={classes.signLink}>
          <ActionButton
            fullWidth
            color={'secondary'}
            disabled={isLoading}
            className={classes.actionButton}
          >
            <FormattedMessage id={'resetpassword.ack.gologin.label'} />
          </ActionButton>
        </Link>
      </Grid>
    </Box>
  );
};

export default ResetPasswordAckPaper;
