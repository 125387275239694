export default {
  'user.name.label': 'Nom',
  'user.firstname.label': 'Prénom',
  'citizen.municipality.label': 'District / Arrondissement',
  'citizen.region.label': 'Région',
  'citizen.district.label': 'District',
  'citizen.fokontany.label': 'Fokontany',
  'citizen.municipality.only.label': 'Commune',
  'citizen.borough.label': 'Arrondissement',
  'citizen.address.label': 'Adresse',
  'citizen.birthdate.label': 'Date de naissance',
  'citizen.cni.number.label': 'Numéro CNI',
  'citizen.health.comorbidity.label':
    'Type de commorbidité ou maladie(s) chronique(s) dont vous souffrez et pour la(les)quelle(s) vous faites l’objet d’un suivi médical',
  'citizen.comorbidity.category.other.label':
    'Type de commorbidité ou maladie chronique (si autre)',
  'citizen.comorbidity.category.other.placeholder.label':
    'Type commorbidité ou maladie chronique',
  'citizen.health.facility.label':
    'Parmi les formations sanitaires listées ci-dessous, veuillez cliquer celle qui est la plus proche de votre lieu de résidence',
  'citizen.health.desired.vaccine.label': 'Vaccin souhaité',
  'citizen.health.lastPcrTest.label':
    'Résultat de test PCR ou Genexpert ces deriniers 3 mois',
  'citizen.health.lastPcrTest.positive.label': 'Positif',
  'citizen.health.lastPcrTest.negative.label': 'Négatif',
  'citizen.health.lastPcrTest.notapplicable.label': "Je n'ai pas fait le test",
  'citizen.job.employer.label': 'Etablissement / Société',
  'citizen.job.employer.placeholder.label': 'L’endroit ou vous travaillez',
  'citizen.job.category.label': 'Catégorie',
  'citizen.job.category.placeholder.label': 'Agent de santé',
  'citizen.job.category.other.label': 'Profession (si autre catégorie)',
  'citizen.job.category.other.placeholder.label': 'Votre profession ',
  'citizen.job.offregnum.label': 'Immatriculation (si fonctionnaire)',
  'citizen.job.offregnum.placeholder.label': 'Votre numéro d’immatriculation',
  'citizen.job.cnapsid.label': "N° d'affiliation CNAPS",
  'citizen.job.cnapsid.placeholder.label': "Votre n° d'affiliation CNAPS",
  'citizen.job.profregnum.label': 'Numéro d’ordre (si agent de santé)',
  'citizen.job.profregnum.placeholder.label':
    'Votre numéro d’inscription Ordre',
  'citizen.success.edit.message': 'Votre profil a été mis à jour avec succès',
  'citizen.success.create.message': 'Votre demande est reçu.',
  'citizen.error.create.failed.message':
    "Une erreur s'est produite lors de la création du nouveau citoyen",
  'citizen.error.create.unknown.message':
    "Une erreur s'est produite lors de la création du nouveau citoyen",
  'citizen.error.exists.message':
    'Un citoyen ayant les mêmes informations existe déjà',
  'citizen.error.notfound.label': 'Erreur: citoyen introuvable!',
  'citizen.error.health.message':
    "Une erreur s'est produit lors de l'enregistrement de CSB de rattachement",
  'citizen.error.job.message':
    "Une erreur s'est produit lors de l'enregistrement de Profession",
  'citizen.error.address.message':
    "Une erreur s'est produit lors de l'enregistrement de l'adresse",
  'citizen.search.fokontany.label': 'Rechercher vôtre Fokontany',
  'citizen.search.fokontany.button.label': 'Valider le Fokontany',
};
