import React, { FC, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import useStyles from './styles';
import AutoCompleteField from '../../../components/inputfields/AutoCompleteField';
import ActionButton from '../../../components/button/ActionButton';
import { useFormik } from '../formik';
import {
  useFokontaniesByMunicipality,
  useDistrictsByRegion,
  useMunicipalitesByDistrict,
} from '../../../graphql/localization/hooks';
import { GetAllRegion_regions } from '../../../graphql/localization/types/GetAllRegion';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

interface ICitizenFokontanySearchProps {
  isLoading?: boolean;
  regions: Array<GetAllRegion_regions>;
  stateChange: any;
  formik: ReturnType<typeof useFormik>;
}

const CitizenFokontanySearch: FC<ICitizenFokontanySearchProps> = ({
  isLoading: isParentFormLoading,
  regions,
  stateChange,
  formik,
}) => {
  const intl = useIntl();
  const [currentRegion, setCurrentRegion] = useState(regions[0].id);
  const [currentDistrict, setCurrentDistrict] = useState<string | undefined>();
  const [currentMunicipality, setCurrentMunicipality] =
    useState<string | undefined>();
  const [currentFokontany, setCurrentFokontany] =
    useState<string | undefined>();
  const classes = useStyles();

  const { districts, isLoading: isLoadingDistrict } = useDistrictsByRegion(
    currentRegion,
    !regions.length,
  );

  const { municipalities, isLoading: isLoadingMunicipality } =
    useMunicipalitesByDistrict(currentDistrict, !districts.length);

  const { fokontanies, isLoading: isLoadingFokontany } =
    useFokontaniesByMunicipality(currentMunicipality, !municipalities.length);

  const handleValidFokontany = () => {
    formik.handleChange('municipalityId')(currentMunicipality ?? '');
    formik.handleChange('fokontanyId')(currentFokontany ?? '');
    stateChange(false);
  };

  const isLoading =
    isParentFormLoading ||
    isLoadingDistrict ||
    isLoadingMunicipality ||
    isLoadingFokontany;

  return (
    <Grid container spacing={2} className={classes.modal}>
      <Grid item md={12} xs={12}>
        <Typography variant="h3" className={classes.title}>
          <FormattedMessage id="citizen.search.fokontany.label" />
        </Typography>
        <CloseIcon
          onClick={() => stateChange(false)}
          className={classes.buttonClose}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <AutoCompleteField
          id="municipality-picker"
          name="regionId"
          required={true}
          isLoading={isLoading}
          items={regions}
          label={intl.formatMessage({
            id: 'citizen.region.label',
          })}
          getItemKey={(item) => `${item?.id ?? ''}`}
          getItemLabel={(item) => `${item?.name}`}
          onSelectionChange={(selectedVal) => {
            setCurrentRegion(selectedVal ?? '');
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <AutoCompleteField
          id="municipality-picker"
          name="districtId"
          required={true}
          isLoading={isLoadingDistrict}
          items={districts}
          label={intl.formatMessage({
            id: 'citizen.district.label',
          })}
          getItemKey={(item) => `${item?.id ?? ''}`}
          getItemLabel={(item) => `${item?.name}`}
          onSelectionChange={(selectedVal) => {
            setCurrentDistrict(selectedVal ?? '');
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <AutoCompleteField
          id="municipality-picker"
          name="municipalityId"
          required={true}
          isLoading={isLoadingMunicipality}
          items={municipalities}
          label={intl.formatMessage({
            id: 'citizen.municipality.label',
          })}
          getItemKey={(item) => `${item?.id ?? ''}`}
          getItemLabel={(item) => `${item?.name}`}
          onSelectionChange={(selectedVal) => {
            setCurrentMunicipality(selectedVal ?? '');
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <AutoCompleteField
          id="fokontany-picker"
          name="fokontanyId"
          required={true}
          isLoading={isLoadingFokontany}
          items={fokontanies}
          label={intl.formatMessage({
            id: 'citizen.fokontany.label',
          })}
          getItemKey={(item) => `${item?.id ?? ''}`}
          getItemLabel={(item) => `${item?.name}`}
          onSelectionChange={(selectedVal) => {
            setCurrentFokontany(selectedVal ?? '');
          }}
        />
      </Grid>
      <Grid item md={12} xs={12}>
        <ActionButton
          autoFocus={false}
          color="primary"
          onClick={() => handleValidFokontany()}
          className={classes.button}
        >
          {intl.formatMessage({
            id: 'citizen.search.fokontany.button.label',
          })}
        </ActionButton>
      </Grid>
    </Grid>
  );
};

export default CitizenFokontanySearch;
