import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import InputAdornment from '@material-ui/core/InputAdornment';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import TodayIcon from '@material-ui/icons/Today';
import InputMask from 'react-input-mask';
import { sub } from 'date-fns';
import LabeledIconButton from '../../../components/button/LabeledIconButton';
import ActionButton from '../../../components/button/ActionButton';
import AutoCompleteField from '../../../components/inputfields/AutoCompleteField';
import DateInputField from '../../../components/inputfields/DateInputField';
import TextInputField from '../../../components/inputfields/TextInputField';
import {
  useAllAddress,
  useAllMunicipality,
  useFokontaniesByMunicipality,
} from '../../../graphql/localization/hooks';
import { useFormik } from '../formik';
import useStyles from '../styles';

interface ICitizenInfoFormProps {
  isLoading?: boolean;
  formik: ReturnType<typeof useFormik>;
  disabled?: boolean;
  isVaccined?: boolean;
  onToggleEditor: () => void;
}

const CitizenInfoForm: FC<ICitizenInfoFormProps> = ({
  isLoading: isParentFormLoading,
  formik,
  disabled = true,
  isVaccined,
  onToggleEditor,
}) => {
  const classes = useStyles();
  const intl = useIntl();

  const { municipalities, isLoading: isLoadingMunicipality } =
    useAllMunicipality();

  const { fokontanies, isLoading: isLoadingFokontany } =
    useFokontaniesByMunicipality(
      formik.values.municipalityId,
      !municipalities.length,
    );

  const { addresses, isLoading: isLoadingAddress } = useAllAddress(
    formik.values.fokontanyId,
  );

  const isLoading =
    isParentFormLoading ||
    isLoadingMunicipality ||
    isLoadingFokontany ||
    isLoadingAddress;

  return (
    <Paper className={classes.paper}>
      <Box component="div" className={classes.content}>
        <Box component="div" className={classes.topActionBar}>
          <Typography className={classes.formTitle}>
            <FormattedMessage id="profile.personalinfo.title.label" />
          </Typography>
          {isVaccined && (
            <LabeledIconButton
              {...{
                color: 'secondary',
                icon: disabled ? <EditIcon /> : <ClearIcon />,
                onClick: onToggleEditor,
                hidden: false,
              }}
            >
              <FormattedMessage
                id={
                  disabled
                    ? 'userprofile.edit.label'
                    : 'userprofile.cancel.edit.label'
                }
              />
            </LabeledIconButton>
          )}
        </Box>

        <Grid container xs={12}>
          <Grid item md={6} xs={12}>
            <DateInputField
              name="birthDate"
              label={intl.formatMessage({
                id: 'citizen.birthdate.label',
              })}
              value={formik.values.birthDate}
              onChange={(newBirthDate) =>
                formik.setFieldValue('birthDate', newBirthDate)
              }
              onBlur={formik.handleBlur}
              error={
                formik.touched.birthDate && Boolean(formik.errors.birthDate)
              }
              helperText={formik.touched.birthDate && formik.errors.birthDate}
              disabled={disabled || isLoading}
              inputProps={{
                maxLength: 12,
              }}
              fullWidth
              required
              maxDate={sub(new Date(), { years: 18 })}
              maxDateMessage={intl.formatMessage({
                id: 'error.birth.date.max.label',
              })}
              onError={(_errorElem, dateVal) => {
                String(_errorElem) &&
                  formik.setErrors({
                    birthDate: String(_errorElem) || undefined,
                  });
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <TodayIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <InputMask
              mask="999 999 999 999"
              value={formik.values.cniNum}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disableUnderline
              name="cniNum"
              disabled={disabled || isLoading}
            >
              {(inputProps) => (
                <TextInputField
                  {...inputProps}
                  label={intl.formatMessage({
                    id: 'citizen.cni.number.label',
                  })}
                  disabled={disabled || isLoading}
                  variant="outlined"
                  fullWidth
                  error={formik.touched.cniNum && Boolean(formik.errors.cniNum)}
                  helperText={formik.touched.cniNum && formik.errors.cniNum}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FingerprintIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item md={6} xs={12}>
            <AutoCompleteField
              id="municipality-picker"
              name="municipalityId"
              required={true}
              isLoading={isLoading}
              disabled={disabled || isLoading}
              defaultValue={formik.values.municipalityId ?? ''}
              items={municipalities}
              label={intl.formatMessage({
                id: 'citizen.municipality.label',
              })}
              getItemKey={(item) => `${item?.id ?? ''}`}
              getItemLabel={(item) => `${item?.district?.name} / ${item?.name}`}
              onSelectionChange={(selectedVal) => {
                formik.handleChange('municipalityId')(selectedVal ?? '');
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.municipalityId &&
                Boolean(formik.errors.municipalityId)
              }
              helperText={
                formik.touched.municipalityId && formik.errors.municipalityId
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AutoCompleteField
              id="fokontany-picker"
              name="fokontanyId"
              required={true}
              isLoading={isLoading}
              disabled={disabled || isLoading}
              defaultValue={formik.values.fokontanyId ?? ''}
              items={fokontanies}
              label={intl.formatMessage({
                id: 'citizen.fokontany.label',
              })}
              getItemKey={(item) => `${item?.id ?? ''}`}
              getItemLabel={(item) => `${item?.name}`}
              onSelectionChange={(selectedVal) => {
                formik.handleChange('fokontanyId')(selectedVal ?? '');
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.fokontanyId && Boolean(formik.errors.fokontanyId)
              }
              helperText={
                formik.touched.fokontanyId && formik.errors.fokontanyId
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AutoCompleteField
              id="address-picker"
              name="addressName"
              freeSolo={true}
              required={true}
              isLoading={isLoading}
              disabled={disabled || isLoading}
              defaultValue={formik.values.addressName ?? undefined}
              items={[...new Set(addresses.map((adrItem) => adrItem.name))]}
              label={intl.formatMessage({
                id: 'citizen.address.label',
              })}
              getItemKey={(item) => item ?? ''}
              getItemLabel={(item) => item ?? ''}
              onSelectionChange={(selectedVal) => {
                formik.handleChange('addressName')(selectedVal ?? '');
              }}
              onBlur={formik.handleBlur}
              error={
                formik.touched.addressName && Boolean(formik.errors.addressName)
              }
              helperText={
                formik.touched.addressName && formik.errors.addressName
              }
            />
          </Grid>
        </Grid>
        {isVaccined && (
          <Box component="div" className={classes.bottomaActionBar}>
            <ActionButton
              loading={!disabled && isLoading}
              color="primary"
              type="submit"
              disabled={disabled || !formik.isValid}
              className={classes.actionButton}
            >
              <FormattedMessage id={'register.button.submit.label'} />
            </ActionButton>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default CitizenInfoForm;
