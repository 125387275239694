export default {
  'resetpassword.title.label': 'Réinitialiser votre mot de passe',
  'resetpassword.password.label': 'Nouveau mot de passe',
  'resetpassword.confirm.password.label': 'Confirmation du mot de passe',
  'resetpassword.newpassword.invalid.message':
    "Le nouveau mot de passe n'est pas acceptable. Le mot de passe doit comporter au moins 4 et au maximum 32 caractères.",
  'resetpassword.confirmpassword.invalid.message':
    "Le nouveau mot de passe n'est pas acceptable. Le mot de passe de confirmation ne correspond pas.",
  'resetpassword.save.label': 'Sauvegarder',
  'resetpassword.cancel.label': 'Annuler',
  'resetpassword.ack.title.label':
    'Votre mot de passe a été réinitialisé avec succès',
  'resetpassword.ack.gologin.label': 'Allez à la page de connexion',
  'resetpassword.ack.message.label':
    'Votre nouveau mot de passe a été enregistré avec succès. Vous pouvez accéder à votre profil de santé via la page de connexion.',
};
