import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import CheckedIconDisabled from '../../../assets/images/vaccine-dose-disabled.svg';
import CheckedIconEnabled from '../../../assets/images/vaccine-dose-enabled.svg';
import ActionButton from '../../../components/button/ActionButton';
import { useCitizenSignIdMutation } from '../../../graphql/citizen/hooks';
import { useAuthContext } from '../../../modules/AuthProvider';
import { MyProfile_myProfile_health_vaccineEvents } from '../../../graphql/authentication/types/MyProfile';
import { EnumGender } from '../../../types';
import { useFormik } from '../formik';
import useStyles from '../styles';
import UserAvatar from '../UserAvatar';
import AvatarUploader from '../AvatarUploader';

interface IMainProfileProps {
  isLoading?: boolean;
  formik: ReturnType<typeof useFormik>;
  vaccineEvents?: MyProfile_myProfile_health_vaccineEvents[];
}

const MainProfile: FC<IMainProfileProps> = ({
  isLoading: isParentFormLoading,
  formik,
}) => {
  const classes = useStyles();
  const { currentUser, signOut } = useAuthContext();
  const [uploadNewAvatar, setUploadNewAvatar] = React.useState<boolean>(false);

  const { signIdLoading: isSignIdLoading, signIdMutation } =
    useCitizenSignIdMutation();

  const showCardButton =
    currentUser?.health?.vaccineEvents &&
    currentUser?.health?.vaccineEvents.length > 0 &&
    currentUser?.epiId
      ? true
      : false;

  return (
    <>
      <Paper className={classes.paper}>
        <Box component="div" className={classes.content}>
          <Box component="div" className={classes.mainProfile}>
            <Box>
              <UserAvatar
                size="large"
                src={currentUser?.photo}
                onEditClick={() => setUploadNewAvatar(true)}
              />
            </Box>
            <Box className={classes.mainProfileInfo}>
              <Typography className={classes.mainProfileName}>
                {currentUser?.lastName ?? ''} {currentUser?.firstName ?? ''}
              </Typography>
              {currentUser?.gender && (
                <Typography className={classes.mainProfileAttr}>
                  {currentUser?.gender === EnumGender.MALE && (
                    <FormattedMessage id="userprofile.gender.male.label" />
                  )}
                  {currentUser?.gender === EnumGender.FEMALE && (
                    <FormattedMessage id="userprofile.gender.female.label" />
                  )}
                  {!currentUser?.gender && (
                    <FormattedMessage id="common.noinformation.label" />
                  )}
                </Typography>
              )}
              {currentUser?.phoneNumber && (
                <Typography className={classes.mainProfileAttr}>
                  {currentUser?.phoneNumber}
                </Typography>
              )}
              {currentUser?.email && (
                <Typography className={classes.mainProfileAttr}>
                  {currentUser?.email}
                </Typography>
              )}
              <Box className={classes.vaccineDoseWrapper}>
                {currentUser?.health?.vaccineEvents &&
                  currentUser?.health?.vaccineEvents.length > 0 &&
                  currentUser?.health?.vaccineEvents.map((vaxEvent, index) => (
                    <Box
                      key={`${vaxEvent?.doseName}${vaxEvent?.doseNum}-${index}`}
                      className={classes.vaccineDoseItem}
                    >
                      <img
                        src={CheckedIconEnabled}
                        className={clsx(classes.vaccineDoseIcon)}
                      />
                      <Typography className={classes.vaccineDoseLabel}>
                        <FormattedMessage
                          id="profile.healthinfo.vaccine.dose.label"
                          values={{ doseNum: index + 1 }}
                        />
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Box>
          <br />
          <Box component="div" className={classes.bottomaActionBar}>
            <ActionButton
              {...{
                color: 'primary',
                loading: isSignIdLoading,
                onClick: () => {
                  if (currentUser?.epiId)
                    signIdMutation(currentUser?.prodigyId);
                },
                disabled: !showCardButton || isParentFormLoading,
              }}
            >
              <FormattedMessage id="userprofile.card" />
            </ActionButton>
            <ActionButton
              {...{
                color: 'default',
                onClick: () => signOut(),
              }}
              disabled={isParentFormLoading || isSignIdLoading}
            >
              <FormattedMessage id="userprofile.logout.label" />
            </ActionButton>
          </Box>
        </Box>
      </Paper>
      {currentUser && uploadNewAvatar && (
        <AvatarUploader
          currentAvatar={currentUser?.photo}
          citizenId={currentUser?.id}
          handleCloseUploader={() => setUploadNewAvatar(false)}
        />
      )}
    </>
  );
};

export default MainProfile;
