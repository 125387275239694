import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { ApolloErrorCode } from '../../../consts';
import { useErrorHanlder } from '../../../modules/hooks';

import { GET_FOKONTANIES_BY_MUNICIPALITY } from '../query';
import {
  GetFokontaniesByMunicipality,
  GetFokontaniesByMunicipalityVariables,
  GetFokontaniesByMunicipality_fokontaniesByMunicipality,
} from '../types/GetFokontaniesByMunicipality';

export const useFokontaniesByMunicipality = (
  municipalityId?: string | null,
  skip?: boolean,
): {
  isLoading: boolean;
  fokontanies: GetFokontaniesByMunicipality_fokontaniesByMunicipality[];
} => {
  // Error handler
  const handleError = useErrorHanlder();

  // Intl
  const intl = useIntl();

  // Graphql query
  const { data, loading } = useQuery<
    GetFokontaniesByMunicipality,
    GetFokontaniesByMunicipalityVariables
  >(GET_FOKONTANIES_BY_MUNICIPALITY, {
    variables: { municipalityId: municipalityId ?? '' },
    skip: !municipalityId || skip,
    onError: (error) => {
      handleError(error, [
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'error.loading.label',
          }),
        },
      ]);
    },
    fetchPolicy: 'cache-first',
  });

  return {
    isLoading: loading,
    fokontanies: data?.fokontaniesByMunicipality ?? [],
  };
};
