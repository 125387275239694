import { makeStyles, Theme } from '@material-ui/core/styles';
import Color from 'color';

export default makeStyles((theme: Theme) => ({
  root: {
    flex: '0 1 auto',
    webkitFlex: '0 1 auto',
    msFlex: '0 1 auto',
    minWidth: '220px',
    minHeight: 'min-content',
    margin: '0px',
    padding: theme.spacing(2),
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  buttonWrapper: {
    padding: '0px',
    backgroundColor: 'transparent',
    border: 'none',
    color: 'none',
    display: 'flex',
  },

  wrapped: { width: '100%', flex: 1, margin: '0px' },

  primary: {
    background: `${theme.button.primary} 0% 0% no-repeat padding-box`,

    '&:hover': {
      background: `${Color(theme.button.primary).lighten(
        0.2,
      )} 0% 0% no-repeat padding-box`,
    },

    '&:active': {
      background: `${Color(theme.button.primary).lighten(
        0.2,
      )} 0% 0% no-repeat padding-box`,
    },

    '&:disabled': {
      background: `${theme.button.disabled} 0% 0% no-repeat padding-box`,
      opacity: 0.5,
      '&:hover, &:active': {
        background: `${theme.button.disabled} 0% 0% no-repeat padding-box`,
      },
    },
  },

  secondary: {
    background: `${theme.button.secondary} 0% 0% no-repeat padding-box`,

    '&:hover': {
      background: `${Color(theme.button.secondary).darken(
        0.1,
      )} 0% 0% no-repeat padding-box`,
    },

    '&:active': {
      background: `${Color(theme.button.secondary).darken(
        0.1,
      )} 0% 0% no-repeat padding-box`,
    },

    '&:disabled': {
      background: `${theme.button.disabled} 0% 0% no-repeat padding-box`,
      opacity: 0.5,

      '&:hover, &:active': {
        background: `${theme.button.disabled} 0% 0% no-repeat padding-box`,
      },
    },
  },

  default: {
    background: `${theme.button.disabled} 0% 0% no-repeat padding-box`,
    color: `${theme.button.primary}`,
    '&:hover': {
      color: `${Color(theme.button.primary)}`,
    },
    '&:disabled': {
      background: `${theme.button.disabled} 0% 0% no-repeat padding-box`,
      opacity: 0.5,

      '&:hover, &:active': {
        background: `${theme.button.disabled} 0% 0% no-repeat padding-box`,
      },
    },
  },

  third: {
    color: `${theme.button.primary}`,
    '&:hover': {
      color: `${Color(theme.button.primary)}`,
    },
  },

  disabled: {
    boxShadow: 'none',
  },

  label: {
    fontFamily: theme.fonts.default,
    textAlign: 'center',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 'bolder',
    whiteSpace: 'normal',
    lineHeight: '24px',
  },

  primaryLabel: {
    color: theme.colors.text.white,
  },

  secondaryLabel: { color: theme.colors.text.white },

  primaryDisabledColor: {
    color: theme.palette.text.disabled,
  },

  secondaryDisabledColor: {
    color: theme.palette.text.disabled,
  },

  circularProgress: {
    color: theme.palette.grey[100],
    width: '22px',
    height: '22px',
  },
}));
