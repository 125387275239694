import React, { FC } from 'react';
import useStyles from '../styles';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { REACT_APP_REST_URL } from '../../../config';
import { Grid, Typography } from '@material-ui/core';

interface ITestCertificateProps {
  printRef: React.RefObject<HTMLDivElement> | null;
  accessKey: string;
}

const TestCertificate: FC<ITestCertificateProps> = ({
  printRef,
  accessKey,
}) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <>
      <Helmet titleTemplate="%s" defer={false}>
        <title>
          {intl.formatMessage({ id: 'common.card.title.certificate' })}
        </title>
      </Helmet>
      <div ref={printRef} className={classes.printablePage}>
        <img
          className={classes.vaccineCertImage}
          src={`${REACT_APP_REST_URL}/t/${accessKey}`}
          alt="TEST CERTIFICATE"
        />
      </div>
    </>
  );
};

export default TestCertificate;
