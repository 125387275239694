import React from 'react';
import clsx from 'clsx';
import Dialog, { DialogProps, DialogClassKey } from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { PaperProps } from '@material-ui/core';

import DialogBaseTitle from './DialogBaseTitle';
import { IProps as DialogBaseTitleProps } from './DialogBaseTitle/DialogBaseTitle';

import useStyles from './styles';

export interface IProps extends Omit<DialogProps, 'title'> {
  children: React.ReactNode;
  actions?: React.ReactNode;
  icon?: React.ReactElement<any, any>;
  title?: React.ReactNode;
  enableCloseIcon?: boolean;
  disableClose?: boolean;
  onClose?: () => void;
  DialogTitleComponent?: React.ReactNode;
  DialogTitleProps?: Partial<DialogBaseTitleProps>;
  PaperProps?: Partial<PaperProps>;
  className?: string;
  classes?: Partial<
    Record<keyof ReturnType<typeof useStyles> | DialogClassKey, string>
  >;
}

const DialogBase = (props: IProps) => {
  const {
    open,
    children,
    actions,
    icon,
    title = '',
    enableCloseIcon = false,
    disableClose = false,
    onClose,
    DialogTitleComponent,
    DialogTitleProps = {},
    className,
    classes: { root, content, ...dialogClasses } = {},
    ...otherProps
  } = props;

  const classes = useStyles(props);

  return (
    <Dialog
      {...{
        'aria-labelledby': DialogTitleProps.id || 'dialog-base-title',
        open,
        disableBackdropClick: true,
        fullScreen: false,
        onClose,
        classes: dialogClasses,
        ...otherProps,
        PaperProps: {
          classes: { root: clsx(classes.root, className) },
          ...(otherProps.PaperProps || {}),
        },
      }}
    >
      {DialogTitleComponent ? (
        DialogTitleComponent
      ) : (
        <DialogBaseTitle
          {...{
            icon,
            title,
            enableCloseIcon,
            disableClose,
            onClose,
            ...DialogTitleProps,
          }}
        />
      )}

      <DialogContent {...{ classes: { root: classes.content } }}>
        {children}
      </DialogContent>

      {actions && (
        <DialogActions {...{ classes: { root: classes.actions } }}>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogBase;
