import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';
import { useStyles } from './styles';
import TipsHeadline from './TipsHeadline';

export interface IProps {
  primaryTitle: string;
  secondaryTitle: string;
  hintMessage?: string;
  children?: React.ReactNode;
  hideOnTablet?: boolean;
  hideOnSmartphone?: boolean;
  hideOnLargeScreen?: boolean;
  hideTipsOnTablet?: boolean;
  hideTipsOnSmartphone?: boolean;
  hideTipsOnLargeScreen?: boolean;
  hideHealinesOnTablet?: boolean;
  hideHealinesOnSmartphone?: boolean;
  hideHealinesOnLargeScreen?: boolean;
  hideTips?: boolean;
}

const TipsContainer: FC<IProps> = ({
  primaryTitle,
  secondaryTitle,
  hintMessage,
  children,
  hideOnTablet = false,
  hideOnSmartphone = false,
  hideOnLargeScreen = false,
  hideTipsOnTablet = false,
  hideTipsOnSmartphone = false,
  hideTipsOnLargeScreen = false,
  hideHealinesOnTablet = false,
  hideHealinesOnSmartphone = false,
  hideHealinesOnLargeScreen = false,
  hideTips = false,
}) => {
  const classes = useStyles();
  return (
    <Box
      component="div"
      className={classnames(classes.root, {
        [classes.hideOnSmartphone]: hideOnSmartphone,
        [classes.hideOnTablet]: hideOnTablet,
        [classes.hideOnLargeScreen]: hideOnLargeScreen,
      })}
    >
      <TipsHeadline
        primaryTitle={primaryTitle}
        secondaryTitle={secondaryTitle}
        className={classnames({
          [classes.hideOnSmartphone]: hideHealinesOnSmartphone,
          [classes.hideOnTablet]: hideHealinesOnTablet,
          [classes.hideOnLargeScreen]: hideHealinesOnLargeScreen,
        })}
      />
      {!hideTips && (
        <Box
          component="div"
          className={classnames(classes.tips, {
            [classes.hideOnSmartphone]: hideTipsOnSmartphone,
            [classes.hideOnTablet]: hideTipsOnTablet,
            [classes.hideOnLargeScreen]: hideTipsOnLargeScreen,
          })}
        >
          {hintMessage && (
            <Typography
              className={classes.hintMessage}
              dangerouslySetInnerHTML={{ __html: hintMessage }}
            />
          )}
          {children}
        </Box>
      )}
    </Box>
  );
};

export default TipsContainer;
