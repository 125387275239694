import { gql } from '@apollo/client';

export const FAQ_INFO_FRAGMENT = gql`
  fragment FaqObjTypeFragment on FaqObjType {
    id
    question
    questionMg
    answer
    answerMg
    categId
  }
`;

export const FAQCATEG_INFO_FRAGMENT = gql`
  fragment FaqCategObjTypeFragment on FaqCategObjType {
    id
    name
    nameMg
  }
`;
