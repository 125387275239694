import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  icon: {
    marginRight: '20px',
  },
  buttons: {
    marginTop: '40px',
  },
  cardGrid: {
    padding: '20px 0',
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 8,
    boxShadow: '0px 4px 80px rgba(0, 0, 0, 0.08)',
    background: '#fff',
    cursor: 'pointer',
    '&:hover': {
      background: '#6eb3b3',
      color: '#fff',
    },
  },
  cardContent: {
    flexGrow: 1,
    textAlign: 'center',
    margin: '40px 0px 180px 0px',
    fontStyle: 'bold',
  },
  cardMedia: {
    textAlign: 'center',
    marginBottom: '40px',
  },
  choiceCertificateHeading: {
    marginBottom: '100px',
  },

  cardCaption: {
    textAlign: 'center',
    padding: theme.spacing(4, 0, 4, 0),
    fontSize: '30px',
    fontWeight: 800,
    fontStyle: 'bold',
    lineHeight: '24px',
    letterSpacing: '-0.18px',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      fontWeight: 500,
      padding: theme.spacing(2, 0),
    },
  },

  cardContentCaption: {
    textAlign: 'center',
    padding: theme.spacing(4, 0, 4, 0),
    fontSize: '50px',
    fontWeight: 800,
    fontStyle: 'bold',
    lineHeight: '24px',
    letterSpacing: '-0.18px',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      fontWeight: 300,
      fontSize: '30px',
      fontStyle: 'bold',
      padding: theme.spacing(2, 0),
    },
  },
}));

export default useStyles;
