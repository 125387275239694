import { createContext } from 'react';

type QRCodeContextType = {
  countryCode: string;
  setCountryCode: (countryCode: string) => void;
  numericCode: string;
  setNumericCode: (numericCode: string) => void;
  epi: string;
  setEpi: (epi: string) => void;
  physicalNumber: string;
  setPhysicalNumber: (physicalNumber: string) => void;
};

export const QRCodeContext = createContext<QRCodeContextType>({
  countryCode: '',
  setCountryCode: () => {},
  numericCode: '',
  setNumericCode: () => {},
  epi: '',
  setEpi: () => {},
  physicalNumber: '',
  setPhysicalNumber: () => {},
});

export default QRCodeContext;
