import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  NormalizedCacheObject,
} from '@apollo/client';

import { createUploadLink } from 'apollo-upload-client';

import { REACT_APP_API_URL } from '../config';
import { ACCESS_TOKEN_KEY } from '../consts';

const cache = new InMemoryCache();
const httpLink = createUploadLink({ uri: REACT_APP_API_URL });

const createApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const authMiddleware = () =>
    new ApolloLink((operation, forward) => {
      const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
      // add the authorization to the headers
      if (accessToken) {
        operation.setContext({
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });
      }
      return forward(operation);
    });

  return new ApolloClient({
    link: authMiddleware().concat(httpLink),
    cache,
    defaultOptions: {
      query: { fetchPolicy: 'network-only' },
      watchQuery: { fetchPolicy: 'network-only' },
    },
  });
};

export default createApolloClient;
