import { createContext } from 'react';
import { MyProfile_myProfile } from '../../graphql/authentication/types/MyProfile';

type AuthContextType = {
  currentUser?: MyProfile_myProfile;
  setCurrentUser: (user?: MyProfile_myProfile) => void;
  isAuthenticated: () => boolean;
  signIn: (accessToken: string) => void;
  signOut: () => void;
};

export const AuthContext = createContext<AuthContextType>({
  currentUser: undefined,
  setCurrentUser: () => {},
  isAuthenticated: () => false,
  signIn: (accessToken: string) => {},
  signOut: () => {},
});

export default AuthContext;
