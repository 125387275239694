import {
  FormikErrors,
  FormikHelpers,
  useFormik as useBaseFormik,
} from 'formik';
import * as yup from 'yup';
import { useIntl } from 'react-intl';
import { CreateCitizenInput } from '../../types';
import { OTHER_COMORBIDITY_ID } from '../../consts';

type CitizenSubscriptionField = Partial<CreateCitizenInput>;
export type SubscriptionFieldError = FormikErrors<CreateCitizenInput>;

interface Config {
  initialValues: CitizenSubscriptionField;
  onSubmit: (
    values: CitizenSubscriptionField,
    formikHelpers: FormikHelpers<CitizenSubscriptionField>,
  ) => void;
}

export const useFormik = (config: Config) => {
  const intl = useIntl();

  const validationSchema = yup.object({
    lastName: yup
      .string()
      .min(
        2,
        intl.formatMessage({
          id: 'error.helper.required',
        }),
      )
      .required(
        intl.formatMessage({
          id: 'error.helper.required',
        }),
      ),
    phoneNumber: yup
      .string()
      .required(
        intl.formatMessage({
          id: 'error.helper.required',
        }),
      )
      .matches(
        /^0[0-9]{2}\s?[0-9]{2}\s?[0-9]{3}\s?[0-9]{2}$/,
        intl.formatMessage({
          id: 'common.editor.phonenumber.invalid.message',
        }),
      ),
    email: yup.string().email(
      intl.formatMessage({
        id: 'common.editor.email.invalid.message',
      }),
    ),
    gender: yup.string().required(
      intl.formatMessage({
        id: 'error.helper.required',
      }),
    ),
    municipalityId: yup.string().required(
      intl.formatMessage({
        id: 'error.helper.required',
      }),
    ),
    fokontanyId: yup.string().required(
      intl.formatMessage({
        id: 'error.helper.required',
      }),
    ),

    jobId: yup.string().required(
      intl.formatMessage({
        id: 'error.helper.required',
      }),
    ),
    cnapsId: yup.string().matches(
      /^[\w]*$/,
      intl.formatMessage({
        id: 'common.editor.cnapsid.invalid.message',
      }),
    ),

    healthCenterId: yup.string().required(
      intl.formatMessage({
        id: 'error.helper.required',
      }),
    ),
  });

  const formik = useBaseFormik<CitizenSubscriptionField>({
    validationSchema: validationSchema,
    validateOnChange: true,
    validate: (values: CitizenSubscriptionField) => {
      const errors: FormikErrors<CitizenSubscriptionField> = {};

      if (
        !values.addressName ||
        (values.addressName && values.addressName.trim().length === 0)
      ) {
        errors.addressName = intl.formatMessage({
          id: 'error.helper.required',
        });
      }

      if (!values.birthDate) {
        errors.birthDate = intl.formatMessage({
          id: 'error.helper.required',
        });
      }

      if (!values.cniNum) {
        errors.cniNum = intl.formatMessage({
          id: 'error.helper.required',
        });
      }

      if (values.birthDate && values.birthDate.toString() === 'Invalid Date') {
        errors.birthDate = intl.formatMessage({
          id: 'common.editor.date.invalid.message',
        });
      }

      if (!values.healthCenterId) {
        errors.healthCenterId = intl.formatMessage({
          id: 'error.helper.required',
        });
      }

      if (
        OTHER_COMORBIDITY_ID == values.comorbidityId &&
        !values.comorbidityName
      ) {
        errors.comorbidityName = intl.formatMessage({
          id: 'error.helper.required',
        });
      }

      if (values.cniNum && values.cniNum.includes('_')) {
        errors.cniNum = intl.formatMessage({
          id: 'common.editor.cniNum.invalid.message',
        });
      }

      return errors;
    },
    ...config,
  });

  return formik;
};
