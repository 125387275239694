import { gql } from '@apollo/client';
import {
  COMORBIDITY_INFO_FRAGMENT,
  HEALTHCENTER_INFO_FRAGMENT,
} from './fragment';

export const GET_ALL_COMORBIDITY = gql`
  query GetAllComorbidity($other: Boolean) {
    comorbidities(other: $other) {
      ...ComorbidityInfoFragment
    }
  }
  ${COMORBIDITY_INFO_FRAGMENT}
`;

export const GET_ALL_HEALTHCENTER = gql`
  query GetAllHealthCenter($municipalityId: ID!) {
    healthCenters(municipalityId: $municipalityId) {
      ...HealthCenterInfoFragment
    }
  }
  ${HEALTHCENTER_INFO_FRAGMENT}
`;
