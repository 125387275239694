import React, { useState, FC } from 'react';

import { MyProfile_myProfile } from '../../graphql/authentication/types/MyProfile';
import { ACCESS_TOKEN_KEY } from '../../consts';
import { AuthContext } from './AuthContext';

interface IProps {
  children?: React.ReactNode;
}

const AuthProvider: FC<IProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<MyProfile_myProfile>();

  return (
    <AuthContext.Provider
      value={{
        currentUser: currentUser,
        setCurrentUser: (user?: MyProfile_myProfile) => {
          setCurrentUser(user);
        },
        isAuthenticated: () => Boolean(localStorage.getItem(ACCESS_TOKEN_KEY)),
        signIn: (accessToken: string) => {
          localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
        },
        signOut: () => {
          setCurrentUser(undefined);
          localStorage.removeItem(ACCESS_TOKEN_KEY);
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
