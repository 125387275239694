export const DEFAULT_SIGNIN_ROUTE = '/signin';
export const DEFAULT_REGISTER_ROUTE = '/registration';
export const DEFAULT_FAQ_ROUTE = '/faq';
export const DEFAULT_FORGOTPASSWORD_ROUTE = '/forgot-password';
export const DEFAULT_CHOICE_CERTIFICATE = '/choice-certificate';
export const DEFAULT_SIGNOUT_ROUTE = '/signout';
export const DEFAULT_CARD = '/my_card';
export const DEFAULT_NOTIFICATION_ROUTE = '/notification';
export const DEFAULT_FORBIDDEN_ROUTE = '/error/forbidden';
export const DEFAULT_HOME_ROUTE = '/';

export const DEFAULT_CARD_CHECK_DATA = '/#/check_my_card_data';
export const VACCINE_ARSTRAZENECA_ID = '1';
export const VACCINE_JANSSEN_ID = '2';
export const VACCINED_STATUT_ONLY_ONE_DOSE = '1';
export const VACCINED_STATUT_TWO_DOSE = '2';

export const ACCESS_TOKEN_KEY = `${
  process.env.REACT_APP_BUILD_STAGE ?? 'local'
}_vaksiny_profile_access_token`;
export const LAYOUT_DRAWER_KEY = 'covax_appointment_layout_drawer_state';
export const LAYOUT_DRAWER_OPEN = 'OPEN';
export const LAYOUT_DRAWER_CLOSED = 'CLOSED';

export const NETWORK_ERROR = 'NETWORK_ERROR';

export const OTHER_JOB_ID = '5';
export const OTHER_COMORBIDITY_ID = '4';
export const OTHER_DEFAULT_COMORBIDITY_ID = '16';

export const DEFAULT_CONTRY_CODE = 'MG';
export const DEFAULT_NUMERIC_CODE = '450';

export const COMBO_BOOL_VALUES = [
  {
    id: 'all',
    name: 'Tous',
  },
  {
    id: 'false',
    name: 'Non',
  },
  {
    id: 'true',
    name: 'Oui',
  },
];

export enum RegistrationStep {
  REGISTRATION_PERSONAL_INFO = 'REGISTRATION_PERSONAL_INFO',
  REGISTRATION_JOB_INFO = 'REGISTRATION_JOB_INFO',
  REGISTRATION_HEALTH_INFO = 'REGISTRATION_HEALTH_INFO',
  REGISTRATION_ACK_INFO = 'REGISTRATION_ACK_INFO',
}

export enum ApolloErrorCode {
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  UNAUTHENTICATED = 'UNAUTHENTICATED', // User must be authentified to perform the action
  FORBIDDEN = 'FORBIDDEN', // User is not allowed to perform the action (ex: need admin right)
  BAD_USER_INPUT = 'BAD_USER_INPUT', // Front end input error
  UKNOWN_ERROR = 'UKNOWN_ERROR',
  NOT_FOUND = 'NOT_FOUND',
  ALREADY_EXIST = 'ALREADY_EXIST',
  NOT_IMPLEMENTED = 'NOT_IMPLEMENTED', // Features not implemented yet (ex: get new password by phone)
  ARGS_TYPE_ERROR = 'ARGS_TYPE_ERROR',
  RUNTIME_ERROR = 'RUNTIME_ERROR', // Should never happen, otherwise something went wrong
  INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER',
  INVALID_EMAIL_ADDRESS = 'INVALID_EMAIL_ADDRESS',
  NON_AUTHORIZED_OPERATION = 'NON_AUTHORIZED_OPERATION',

  // User
  INVALID_CREDENTIAL = 'INVALID_CREDENTIAL',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  USER_LOGIN_EXISTS = 'USER_LOGIN_EXISTS',
  USER_EMAIL_EXISTS = 'USER_EMAIL_EXISTS',
  USER_BODY_NOT_FOUND = 'USER_BODY_NOT_FOUND',
  INVALID_OLD_PASSWORD = 'INVALID_OLD_PASSWORD',
  INVALID_PASSWORD = 'INVALID_PASSWORD',

  //CITIZEN
  CITIZEN_NOT_FOUND = 'CITIZEN_NOT_FOUND',
  CITIZEN_EXISTS = 'CITIZEN_EXISTS',
  PCR_TEST_NOT_ACCEPTED = 'PCR_TEST_NOT_ACCEPTED',
  CITIZEN_CREATION_FAILED = 'CITIZEN_CREATION_FAILED',
  HEALTH_REGISTRATION_FAILED = 'HEALTH_REGISTRATION_FAILED',
  JOB_REGISTRATION_FAILED = 'JOB_REGISTRATION_FAILED',
  ADDRESS_REGISTRATION_FAILED = 'ADDRESS_REGISTRATION_FAILED',

  // Reset password
  INVALID_PASSWORDRESET_TOKEN = 'INVALID_PASSWORDRESET_TOKEN',
  PASSWORD_INVALID = 'PASSWORD_INVALID',
  PASSWORD_NOT_MATCH = 'PASSWORD_NOT_MATCH',

  // Access key
  INVALID_ACCESS_KEY = 'INVALID_ACCESS_KEY',
}
