export default {
  'faq.back.button.label': "Miverina any amin'ny fandraisana",
  'faq.page.title': 'Ireo fanontaniana matetika',
  'faq.form.title': 'FAQs',
  'faq.technical.support': 'Fanohanana ara-teknika.',
  'faq.caption.message':
    "Izay rehetra tokony ho fantatrao momba ny vaksiny. Raha tsy hitanao ny valiny tadiavinao? <a href='mailto:vaccinationcovid19@googlegroups.com'>Mifandraisa amin'ny ekipanay.</a>",
  'faq.empty.primary.message': 'Tsy hitantsika io fanontaniana io',
  'faq.empty.secondary.message':
    'Miala tsiny fa tsy misy ny valiny tadiavinao.',
  'faq.choose.category.label': 'Misafidiana sokajin-dresaka',
  'faq.choose.theme.label': 'Mitady teny',
};
