import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { ApolloErrorCode } from '../../../consts';
import { useIntlLabel } from '../../../locales';
import { useErrorHanlder } from '../../../modules/hooks';

import { GET_ALL_JOB } from '../query';
import {
  GetAllJob,
  GetAllJobVariables,
  GetAllJob_jobs,
} from '../types/GetAllJob';

export const useAllJob = (
  other: boolean,
  skip?: boolean,
): {
  isLoading: boolean;
  jobs: GetAllJob_jobs[];
  refetch: () => void;
} => {
  // Error handler
  const handleError = useErrorHanlder();

  // Intl
  const intl = useIntl();
  const intlLabel = useIntlLabel();

  // Graphql query
  const { data, loading, refetch } = useQuery<GetAllJob, GetAllJobVariables>(
    GET_ALL_JOB,
    {
      variables: { other },
      skip: skip,
      onError: (error) => {
        handleError(error, [
          {
            code: ApolloErrorCode.UKNOWN_ERROR,
            message: intl.formatMessage({
              id: 'error.loading.label',
            }),
          },
        ]);
      },
    },
  );

  return {
    isLoading: loading,
    jobs:
      data?.jobs?.map?.((jobItem) => ({
        ...jobItem,
        name: intlLabel(jobItem, 'name'),
      })) ?? [],
    refetch,
  };
};
