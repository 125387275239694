import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  popupActionButton: {
    width: '100%',
    marginTop: theme.spacing(4),
  },
}));

export default styles;
