export default {
  'signin.app.label': 'Application pour la Gestion de Profil de santé',
  'signin.form.hint.label': 'Accéder à votre profil',
  'signin.form.call.for.registration.message': 'Inscrivez-vous',
  'signin.page.title.label': 'Authentification',
  'signin.login.label': 'Identifiant',
  'signin.login.placeholder.label': 'Nom de famille',
  'signin.password.label': 'Mot de passe',
  'signin.password.placeholder.label': 'EPI_99999999',

  'signin.buttom.label': 'Se connecter',
  'signin.faq.label': 'Aller à la foire aux questions',
  'signin.back.button.label': 'Retourner à la page de connection',
  'signin.forgot.password.label': 'Mot de passe oublié ?',
  'signin.keepmeloggedin.label': 'Gardez-moi connecté',
  'signin.login.success': 'Authentifié avec succès',
  'signin.login.error': 'Erreur: identifiant ou mot de passe invalide!',
  'signin.password.error.min.message':
    'Le mot de passe doit comporter au moins 4 caractères',
  'signin.password.error.max.message':
    'Le mot de passe doit comporter au maximum 32 caractères',
  'signin.password.error.required.message': 'Entrer un mot de passe',
  'signin.login.error.required.message': 'Entrez votre identifiant',
  'signin.login.error.min.message':
    'Le login doit comporter au moins 2 caractères',
  'signin.login.error.max.message':
    'Le login doit comporter au maximum 32 caractères',

  'signin.tips.primary.title': 'VACCIN COVID-19',
  'signin.tips.secondary.title':
    "Bienvenue sur le site d'inscription officielle<br/> à la vaccination contre la Covid-19.<br/><strong>“La vaccination est volontaire et gratuite”.</strong>",
  'signin.subscribe.hint.message':
    'L’inscription est ouverte à toute personne désireuse de se faire vacciner et <br/>appartenant aux catégories de personne suivantes : ',
  'signin.persona.travelers.label':
    'Personnes qui doivent voyager à l’extérieur pour motif de travail ou étude et disposant des documents justificatifs probants',
  'signin.persona.healthworker.label':
    'Agents de santé<br/>(publics et privés)',
  'signin.persona.frailpeople.label':
    'Personnes affectées par une (des) maladie(s) chronique(s) et faisant l’objet d’un suivi médical',
  'signin.persona.govsecurity.label': 'Les forces de l’ordre',
  'signin.persona.socialworker.label': 'Les travailleurs sociaux',
  'signin.persona.teleoperator.label':
    'Pour tout besoin d’informations complementaires, Veuillez appeler le numéro 910.',
  'signin.tips.main.message': `<p>La vaccination est maintenant accessible à toutes les personnes âgées de 18ans et plus.
    <p>Vous n’êtes pas encore vacciné ? ou vous êtes vaccinés mais pas encore enregistrés via tablette lors de votre passage au centre de vaccination.</p>
    <p><b>Prenez 2 minutes pour vous inscrire sur cette plateforme!</b></p>
    <p>L’inscription n’est pas obligatoire mais avoir un compte vous permet de bénéficier des avantages suivants :</p>
    <p>- faciliter votre enregistrement au niveau des centres de vaccinations ;</p>
    <p>- organiser votre prochain rendez-vous pour la 2e dose ;</p>
    <p>- disposer ultérieurement des fonctionnalités complémentaires proposées par la plateforme comme la délivrance d’une attestation de vaccination officielle ou d’une carte de vaccination digitale et bien d’autres.</p>
    <p>Pour toutes informations complémentaires, appelez le 910.</p>`,
};
