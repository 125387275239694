import React, { FC } from 'react';
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from 'react-router-dom';

import { useAuthContext } from '../../modules/AuthProvider';
import { DEFAULT_SIGNIN_ROUTE } from '../../consts';

interface IProps
  extends Omit<RouteProps, 'children' | 'render' | 'sensitive' | 'strict'> {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const PrivateRoute: FC<IProps> = ({ component: Component, ...others }) => {
  const { isAuthenticated } = useAuthContext();
  return (
    <Route
      {...others}
      render={(props: RouteComponentProps<any>) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: DEFAULT_SIGNIN_ROUTE,
              search: `from=${props.location.pathname}`,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
