export default {
  'changepassword.error.min.message': 'Tokony hisy soratra 4 farafahakeliny',
  'changepassword.error.max.message': 'Tokony hisy soratra 32 farafahalavany',
  'changepassword.error.newpassword.required.message':
    'Tsy maintsy fenoina ny teny miafina vaovao',
  'changepassword.error.confirmpassword.required.message':
    'Hamarino ilay teny miafina vaovao',
  'changepassword.error.confirmpassword.notmatch.message':
    'Tsy mitovy ireo teny miafina nosoratanao',
};
