import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { useIntl } from 'react-intl';
import AutoCompleteField from '../../../components/inputfields/AutoCompleteField';
import TextInputField from '../../../components/inputfields/TextInputField';
import { useAllEmployerEntity, useAllJob } from '../../../graphql/job/hooks';
import { useFormik } from '../formik';
import { OTHER_JOB_ID } from '../../../consts';

interface ICitizenJobFormProps {
  isLoading?: boolean;
  formik: ReturnType<typeof useFormik>;
}

const CitizenJobForm: FC<ICitizenJobFormProps> = ({
  isLoading: isParentFormLoading,
  formik,
}) => {
  const intl = useIntl();

  const { jobs, isLoading: isLoadingJob } = useAllJob(false);

  const { jobs: otherJobs, isLoading: isLoadingOtherJob } = useAllJob(
    true,
    !jobs.length,
  );

  const { employerEntities, isLoading: isLoadingEmployerEntity } =
    useAllEmployerEntity(formik.values.municipalityId, !jobs.length);

  const isLoading =
    isParentFormLoading ||
    isLoadingJob ||
    isLoadingEmployerEntity ||
    isLoadingOtherJob;

  return (
    <>
      <Grid item md={6} xs={12}>
        <AutoCompleteField
          id="employerentity-picker"
          name="employerName"
          freeSolo={true}
          isLoading={isLoading}
          defaultValue={formik.values.employerName ?? ''}
          items={employerEntities.map((employerItm) => employerItm.name)}
          label={intl.formatMessage({
            id: 'citizen.job.employer.label',
          })}
          placeholder={intl.formatMessage({
            id: 'citizen.job.employer.placeholder.label',
          })}
          getItemKey={(item) => item ?? ''}
          getItemLabel={(item) => item ?? ''}
          onSelectionChange={(selectedVal) => {
            formik.handleChange('employerName')(selectedVal ?? '');
          }}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <AutoCompleteField
          id="job-picker"
          name="jobId"
          required={true}
          isLoading={isLoading}
          defaultValue={formik.values.jobId ?? ''}
          items={jobs}
          label={intl.formatMessage({
            id: 'citizen.job.category.label',
          })}
          placeholder={intl.formatMessage({
            id: 'citizen.job.category.placeholder.label',
          })}
          getItemKey={(item) => `${item?.id}`}
          getItemLabel={(item) => `${item?.name}`}
          onSelectionChange={(selectedVal) => {
            formik.handleChange('jobId')(selectedVal ?? '');
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.jobId && Boolean(formik.errors.jobId)}
          helperText={formik.touched.jobId && formik.errors.jobId}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextInputField
          name="offRegNum"
          label={intl.formatMessage({
            id: 'citizen.job.offregnum.label',
          })}
          placeholder={intl.formatMessage({
            id: 'citizen.job.offregnum.placeholder.label',
          })}
          fullWidth
          inputProps={{
            maxLength: 6,
          }}
          disabled={isLoading}
          value={formik.values.offRegNum}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.offRegNum && Boolean(formik.errors.offRegNum)}
          helperText={formik.touched.offRegNum && formik.errors.offRegNum}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextInputField
          name="profRegNum"
          label={intl.formatMessage({
            id: 'citizen.job.profregnum.label',
          })}
          placeholder={intl.formatMessage({
            id: 'citizen.job.profregnum.placeholder.label',
          })}
          fullWidth
          disabled={isLoading}
          value={formik.values.profRegNum}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.profRegNum && Boolean(formik.errors.profRegNum)}
          helperText={formik.touched.profRegNum && formik.errors.profRegNum}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextInputField
          name="cnapsId"
          label={intl.formatMessage({
            id: 'citizen.job.cnapsid.label',
          })}
          placeholder={intl.formatMessage({
            id: 'citizen.job.cnapsid.placeholder.label',
          })}
          fullWidth
          disabled={isLoading}
          value={formik.values.cnapsId}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.cnapsId && Boolean(formik.errors.cnapsId)}
          helperText={formik.touched.cnapsId && formik.errors.cnapsId}
        />
      </Grid>
      {OTHER_JOB_ID === formik.values.jobId && (
        <Grid item md={6} xs={12}>
          <AutoCompleteField
            id="job-other-picker"
            name="jobName"
            freeSolo={true}
            required={true}
            isLoading={isLoading}
            defaultValue={formik.values.jobName ?? ''}
            items={otherJobs.map((jobItm) => jobItm.name)}
            label={intl.formatMessage({
              id: 'citizen.job.category.other.label',
            })}
            placeholder={intl.formatMessage({
              id: 'citizen.job.category.other.placeholder.label',
            })}
            getItemKey={(item) => item ?? ''}
            getItemLabel={(item) => item ?? ''}
            onSelectionChange={(selectedVal) => {
              formik.handleChange('jobName')(selectedVal ?? '');
            }}
          />
        </Grid>
      )}
    </>
  );
};

export default CitizenJobForm;
