import { gql } from '@apollo/client';
import { CITIZEN_INFO_FRAGMENT } from '../citizen/fragment';

// Get my health profile information
export const MY_PROFILE = gql`
  query MyProfile {
    myProfile {
      ...AllCitizenInfoFragment
    }
  }
  ${CITIZEN_INFO_FRAGMENT}
`;

// Check if the password reset link has not expired
export const USER_CHECK_PASSWORD_RESET = gql`
  query HealthProfilePasswordResetCheck($resetToken: String!) {
    healthProfilePasswordResetCheck(resetToken: $resetToken) {
      isValid
      message
    }
  }
`;
