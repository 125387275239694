import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { FormattedMessage, useIntl } from 'react-intl';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import LabeledIconButton from '../../../components/button/LabeledIconButton';
import ActionButton from '../../../components/button/ActionButton';
import AutoCompleteField from '../../../components/inputfields/AutoCompleteField';
import TextInputField from '../../../components/inputfields/TextInputField';
import { useAllEmployerEntity, useAllJob } from '../../../graphql/job/hooks';
import { OTHER_JOB_ID } from '../../../consts';
import { useFormik } from '../formik';
import useStyles from '../styles';

interface ICitizenJobFormProps {
  isLoading?: boolean;
  formik: ReturnType<typeof useFormik>;
  disabled?: boolean;
  isVaccined?: boolean;
  onToggleEditor: () => void;
}

const CitizenJobForm: FC<ICitizenJobFormProps> = ({
  isLoading: isParentFormLoading,
  formik,
  disabled = true,
  isVaccined,
  onToggleEditor,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { jobs, isLoading: isLoadingJob } = useAllJob(false);

  const { jobs: otherJobs, isLoading: isLoadingOtherJob } = useAllJob(
    true,
    !jobs.length,
  );

  const { employerEntities, isLoading: isLoadingEmployerEntity } =
    useAllEmployerEntity(formik.values.municipalityId, !jobs.length);

  const isLoading =
    isParentFormLoading ||
    isLoadingJob ||
    isLoadingEmployerEntity ||
    isLoadingOtherJob;

  return (
    <Paper className={classes.paper}>
      <Box component="div" className={classes.content}>
        <Box component="div" className={classes.topActionBar}>
          <Typography className={classes.formTitle}>
            <FormattedMessage id="profile.jobinfo.title.label" />
          </Typography>
          {isVaccined && (
            <LabeledIconButton
              {...{
                color: 'secondary',
                icon: disabled ? <EditIcon /> : <ClearIcon />,
                onClick: onToggleEditor,
              }}
            >
              <FormattedMessage
                id={
                  disabled
                    ? 'userprofile.edit.label'
                    : 'userprofile.cancel.edit.label'
                }
              />
            </LabeledIconButton>
          )}
        </Box>
        <Grid container xs={12}>
          <Grid item md={6} xs={12}>
            <AutoCompleteField
              id="employerentity-picker"
              name="employerName"
              freeSolo={true}
              isLoading={isLoading}
              disabled={disabled || isLoading}
              defaultValue={formik.values.employerName ?? ''}
              items={employerEntities.map((employerItm) => employerItm.name)}
              label={intl.formatMessage({
                id: 'citizen.job.employer.label',
              })}
              placeholder={intl.formatMessage({
                id: 'citizen.job.employer.placeholder.label',
              })}
              getItemKey={(item) => item ?? ''}
              getItemLabel={(item) => item ?? ''}
              onSelectionChange={(selectedVal) => {
                formik.handleChange('employerName')(selectedVal ?? '');
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <AutoCompleteField
              id="job-picker"
              name="jobId"
              required={true}
              isLoading={isLoading}
              disabled={disabled || isLoading}
              defaultValue={formik.values.jobId ?? ''}
              items={jobs}
              label={intl.formatMessage({
                id: 'citizen.job.category.label',
              })}
              placeholder={intl.formatMessage({
                id: 'citizen.job.category.placeholder.label',
              })}
              getItemKey={(item) => `${item?.id}`}
              getItemLabel={(item) => `${item?.name}`}
              onSelectionChange={(selectedVal) => {
                formik.handleChange('jobId')(selectedVal ?? '');
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.jobId && Boolean(formik.errors.jobId)}
              helperText={formik.touched.jobId && formik.errors.jobId}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextInputField
              name="offRegNum"
              label={intl.formatMessage({
                id: 'citizen.job.offregnum.label',
              })}
              placeholder={intl.formatMessage({
                id: 'citizen.job.offregnum.placeholder.label',
              })}
              fullWidth
              inputProps={{
                maxLength: 6,
              }}
              disabled={disabled || isLoading}
              value={formik.values.offRegNum}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.offRegNum && Boolean(formik.errors.offRegNum)
              }
              helperText={formik.touched.offRegNum && formik.errors.offRegNum}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextInputField
              name="profRegNum"
              label={intl.formatMessage({
                id: 'citizen.job.profregnum.label',
              })}
              placeholder={intl.formatMessage({
                id: 'citizen.job.profregnum.placeholder.label',
              })}
              fullWidth
              disabled={disabled || isLoading}
              value={formik.values.profRegNum}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.profRegNum && Boolean(formik.errors.profRegNum)
              }
              helperText={formik.touched.profRegNum && formik.errors.profRegNum}
            />
          </Grid>
          {OTHER_JOB_ID === formik.values.jobId && (
            <Grid item md={6} xs={12}>
              <AutoCompleteField
                id="job-other-picker"
                name="jobName"
                freeSolo={true}
                required={true}
                isLoading={isLoading}
                disabled={disabled || isLoading}
                defaultValue={formik.values.jobName ?? ''}
                items={otherJobs.map((jobItm) => jobItm.name)}
                label={intl.formatMessage({
                  id: 'citizen.job.category.other.label',
                })}
                placeholder={intl.formatMessage({
                  id: 'citizen.job.category.other.placeholder.label',
                })}
                getItemKey={(item) => item ?? ''}
                getItemLabel={(item) => item ?? ''}
                onSelectionChange={(selectedVal) => {
                  formik.handleChange('jobName')(selectedVal ?? '');
                }}
              />
            </Grid>
          )}
        </Grid>
        {isVaccined && (
          <Box component="div" className={classes.bottomaActionBar}>
            <ActionButton
              loading={isLoading}
              color="primary"
              type="submit"
              disabled={disabled || !formik.isValid}
              className={classes.actionButton}
            >
              <FormattedMessage id={'register.button.submit.label'} />
            </ActionButton>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default CitizenJobForm;
