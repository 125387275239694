import React, { FC } from 'react';
import classnames from 'classnames';
import { Typography, Box } from '@material-ui/core';
import { useStyles } from './styles';

export interface IProps {
  image: string;
  caption: string;
  className?: string;
}

const TipsItem: FC<IProps> = ({ image, caption, className }) => {
  const classes = useStyles();
  return (
    <Box component="div" className={classnames(classes.root, className)}>
      <Box
        className={classes.mainWrapper}
        display="flex"
        flexDirection="row"
        justifyItems="center"
      >
        <Box className={classes.tipsImageWrapper}>
          <img
            className={classes.tipsImage}
            src={image}
            alt={`IMAGE FOR : ${caption}`}
          />
        </Box>
        <Box className={classes.tipsCaptionWrapper}>
          <Typography
            className={classes.tipsCaption}
            dangerouslySetInnerHTML={{ __html: caption }}
          ></Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TipsItem;
