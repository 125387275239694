export default {
  'title.hellobar': 'Hijery karatra fanamarinana fanaovam-baksiny',
  'notice.hellobar':
    "Ny taratasy fahamarinana ny fanaovana vaksiny dia tsy &quot;passe sanitaire européen&quot; velively, fa antotan-taratasy hafahana<br />mangataka &quot;passe sanitaire&quot; eny anivon'ny Ambasady ny firenena handray anao.",
  'button.hellobar.label': 'Jereo ny torolalana',
  'button.close.hellobar.label': 'Tsia, misaotra',
  'other.service.hellobar.label':
    "Raha hijery servisy hafa hanampy anao amin'ny fiainana andavanandro :<br /><a target='_blank' href='https://torolalana.gov.mg'>https://torolalana.gov.mg</a>",
  'tips.card.label': 'Fomba fampiasana ny torolalana',
  'tips.card.video.label': 'Mijery video',
};
