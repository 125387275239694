export default {
  'common.default.page.label': 'Accueil',
  'common.app.name': 'CoVaX - Profil de santé - Madagascar',
  'common.health.profile.label': 'PROFIL DE SANTÉ',
  'common.page.footer.copyright': `Copyright © ${new Date().getFullYear()} vaksiny.gov.mg`,
  'common.label.cancel': 'Annuler',
  'common.label.nooption': 'Aucune option ne correspond à vos critères',
  'common.noinformation.label': 'Non renseigné',
  'common.button.reload.label': 'Recharger',
  'common.editor.email.invalid.message': 'Adresse e-mail invalide',
  'common.editor.email.not.trusted.message':
    'Adresse e-mail invalide. Utilisez plutôt un service de courrier électronique connu et fiable.',
  'common.editor.phonenumber.invalid.message': 'Numéro de téléphone incorrect',
  'common.editor.cniNum.invalid.message': 'Numéro de CIN incorrect',
  'common.editor.date.invalid.message': 'Date incorrecte',
  'common.editor.cnapsid.invalid.message': 'N° CNAPS invalide!',
  'common.editor.required.message': 'Les champs marqués (*) sont obligatoires.',
  'common.language.fr.iso': 'FR',
  'common.language.fr.iso.tooltip': 'Utiliser la langue française',
  'common.language.mg.iso': 'MG',
  'common.language.mg.iso.tooltip': 'Misafidy ny fiteny malagasy',
};
