export default {
  'user.name.label': 'Anarana',
  'user.firstname.label': "Fanampin'anarana",
  'citizen.municipality.label': 'Distrika / Boriborintany',
  'citizen.region.label': 'Faritra',
  'citizen.district.label': 'Distrika',
  'citizen.fokontany.label': 'Fokontany',
  'citizen.municipality.only.label': 'Kaominina',
  'citizen.borough.label': 'Boriborintany',
  'citizen.address.label': 'Adiresy',
  'citizen.birthdate.label': 'Daty nahaterahana',
  'citizen.cni.number.label': "Laharan'ny kara-panondro",
  'citizen.health.comorbidity.label':
    "Karazana aretina mikiky na aretina mitaiza izay mahazo anao  ary anarahi-maso anao amin'ny fitsaboana",
  'citizen.comorbidity.category.other.label':
    'Karazana aretina mikiky na aretina mitaiza (raha hafa)',
  'citizen.comorbidity.category.other.placeholder.label':
    'Karazana aretina mikiky na aretina mitaiza',
  'citizen.health.facility.label':
    "Anisan'ny toerana ahitana izany ireo voalaza etsy ambany. Misafidiana ny toerana akaiky anao",
  'citizen.health.desired.vaccine.label': '​Vaksiny irina',
  'citizen.health.lastPcrTest.label':
    'Résultat de test PCR ou Genexpert ces deriniers 3 mois',
  'citizen.health.lastPcrTest.positive.label': 'Positif',
  'citizen.health.lastPcrTest.negative.label': 'Négatif',
  'citizen.health.lastPcrTest.notapplicable.label': "Je n'ai pas fait le test",
  'citizen.job.employer.label': 'Tranom-barotra / Orinasa',
  'citizen.job.employer.placeholder.label': 'Toerana iasana',
  'citizen.job.category.label': 'Sokajy',
  'citizen.job.category.placeholder.label': "Mpiasan'ny fahasalamana",
  'citizen.job.category.other.label': 'Asa (raha sokajy hafa) ',
  'citizen.job.category.other.placeholder.label': 'Ny asanao',
  'citizen.job.offregnum.label': '"Immatriculation" (raha mpiasam-panjakana)',
  'citizen.job.offregnum.placeholder.label': 'Laharana maha mpiasam-panjakana',
  'citizen.job.cnapsid.label': 'Laharana CNAPS',
  'citizen.job.cnapsid.placeholder.label': 'Laharana maha mpikambana CNAPS',
  'citizen.job.profregnum.label':
    "Laharana fahazoandalana (mpiasan'ny fahasalamana)",
  'citizen.job.profregnum.placeholder.label': "Laharana ao amin'ny ONM ",
  'citizen.success.edit.message': 'Votre profil a été mis à jour avec succès',
  'citizen.success.create.message': 'Voaray ny fangatahanao.',
  'citizen.error.create.failed.message':
    "Une erreur s'est produite lors de la création du nouveau citoyen",
  'citizen.error.create.unknown.message':
    "Une erreur s'est produite lors de la création du nouveau citoyen",
  'citizen.error.exists.message':
    'Un citoyen ayant les mêmes informations existe déjà',
  'citizen.error.notfound.label': 'Erreur: citoyen introuvable!',
  'citizen.error.health.message':
    "Une erreur s'est produit lors de l'enregistrement de CSB de rattachement",
  'citizen.error.job.message':
    "Une erreur s'est produit lors de l'enregistrement de Profession",
  'citizen.error.address.message':
    "Une erreur s'est produit lors de l'enregistrement de l'adresse",
  'citizen.search.fokontany.label': 'Fitadiavana Fokontany',
  'citizen.search.fokontany.button.label': 'Voasafidy ny Fokontany',
};
