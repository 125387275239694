import { makeStyles } from '@material-ui/core/styles';

interface IProps {
  open: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: (props: IProps) => ({
    position: 'relative',
    borderRadius: theme.spacing(2),
    padding: theme.spacing(1),
    marginBottom: props.open ? theme.spacing(4) : theme.spacing(2),
    background: props.open
      ? 'linear-gradient(180deg, #FFFFFF 0%, #F7F4F4 100%)'
      : 'none',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      padding: theme.spacing(3, 2, 3, 2),
    },
  }),
  boxHead: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
  },
  typoQuestion: (props: IProps) => ({
    flexGrow: 1,
    fontWeight: 500,
    marginBotton: theme.spacing(4),
    fontSize: '18px',
    lineHeight: '25px',
    color: props.open ? theme.colors.text.black004 : theme.colors.text.black00,
    marginRight: theme.spacing(3),
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      marginRight: theme.spacing(2),
    },
  }),
  typoAnswer: (props: IProps) => ({
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '25px',
    color: props.open ? theme.colors.text.black00 : theme.colors.text.black004,
  }),
  dropDownIcon: (props: IProps) => ({
    fontSize: '20px',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
    transform: props.open ? 'rotate(-180deg)' : 'rotate(0)',
  }),
}));

export default useStyles;
