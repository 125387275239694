import { createContext } from 'react';

export type Locale = 'fr' | 'mg';

export enum EnumLocaleKeys {
  Francais = 'fr',
  Malagasy = 'mg',
}

type ContextType = {
  locale: Locale;
  setLocale: (newLocale: Locale) => void;
};

export const LocaleContext = createContext<ContextType>({
  locale: 'mg',
  setLocale: (newLocale: Locale) => {},
});

export default LocaleContext;
