import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Styles } from '@material-ui/styles';

export const mergeClasses = (
  defaultClasses: Record<string, string>,
  newClasses: Record<string, string>,
): Record<string, string> => {
  const currentClasses: Record<string, string> = { ...defaultClasses };

  Object.entries(newClasses).forEach(
    ([classKey, newClassValue]) =>
      (currentClasses[classKey] = clsx(
        currentClasses[classKey],
        newClassValue,
      )),
  );

  return currentClasses;
};

export const styles: Styles<Theme, {}, 'label' | 'inlineLabel' | 'scrollbar'> =
  (theme: Theme) => ({
    label: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',

      textAlign: 'left',
      letterSpacing: '0px',
      font: 'normal normal 500 14px/19px Roboto',
      //@ts-ignore
      color: theme.palette.text.black,
      textTransform: 'none',

      userDrag: 'none',
      userSelect: 'none',
      '-webkit-user-drag': 'none',
      '-webkit-user-select': 'none' /* Safari */,
      '-moz-user-select': 'none' /* Firefox */,
      '-ms-user-select': 'none' /* IE10+/Edge */,
    },

    inlineLabel: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
      letterSpacing: '0px',
      font: 'normal normal 500 14px/19px Roboto',
      //@ts-ignore
      color: theme.palette.text.black,
      textTransform: 'none',
      userDrag: 'none',
      userSelect: 'none',
    },

    scrollbar: {
      scrollbarWidth: 'thin',
      scrollbarColor: `${theme.palette.text.disabled} transparent`,

      '&::-webkit-scrollbar': {
        width: '7px',
        height: '7px',
        backgroundColor: 'transparent',
      },

      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },

      '&::-webkit-scrollbar-thumb': {
        borderRadius: '25px',
        backgroundColor: theme.palette.text.disabled,
      },
    },
  });

export default makeStyles(styles);
