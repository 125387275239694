import React, { FC } from 'react';
import clsx from 'clsx';
import Slider from '@material-ui/core/Slider';
import Box from '@material-ui/core/Box';
import Cropper from 'react-easy-crop';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import { Area } from 'react-easy-crop/types';
import NoImage from '../../../assets/images/default-profile-pic.svg';
import DialogBase from '../../../components/dialog/DialogBase';
import ActionButton from '../../../components/button/ActionButton';
import useCommonStyles from '../../../components/styles';
import { useCitizenUpdateProfilePicMutation } from '../../../graphql/citizen/hooks';

import { getCroppedImg } from './helper';
import { useStyles } from './styles';

interface IProps {
  handleCloseUploader: () => void;
  currentAvatar?: string | null;
  citizenId: string;
}

const AVATAR_SIZE = 256;

const AvatarUploader: FC<IProps> = ({
  handleCloseUploader,
  citizenId,
  currentAvatar = NoImage,
}) => {
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });

  const [cropSize, setCropSize] = React.useState({
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
  });
  const [zoom, setZoom] = React.useState(1);
  const [newAvatar, setNewAvatar] = React.useState<string>();
  const [avatarFilename, setAvatarFilename] = React.useState<string>();
  const [croppedAvatar, setCroppedAvatar] = React.useState<Blob | null>();
  const { updateProfilePicLoading, updateProfilePicMutation } =
    useCitizenUpdateProfilePicMutation(citizenId, {
      onSuccess: () => handleCloseUploader(),
    });

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files.length > 0) {
      const fileTmp = e.currentTarget.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setNewAvatar(reader.result as string);
        setAvatarFilename(fileTmp.name);
      });
      reader.readAsDataURL(fileTmp);
    }
  };

  const onCropComplete = React.useCallback(
    async (croppedArea: Area, croppedAreaPixels: Area) => {
      if (newAvatar) {
        const croppedImage = await getCroppedImg(newAvatar, croppedAreaPixels);
        setCroppedAvatar(croppedImage);
      }
    },
    [newAvatar],
  );

  return (
    <DialogBase
      open
      enableCloseIcon
      {...{
        title: (
          <Typography
            {...{ className: clsx(commonClasses.inlineLabel, classes.title) }}
          >
            <FormattedMessage id="userprofile.uploadavatar.title" />
          </Typography>
        ),
        onClose: handleCloseUploader,
        classes: { content: classes.content },
      }}
    >
      <Box component="div" className={classes.content}>
        {newAvatar && (
          <Box component="div" className={classes.imageCropper}>
            <Cropper
              image={newAvatar}
              crop={crop}
              zoom={zoom}
              aspect={1 / 1}
              cropShape="round"
              showGrid={true}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropSize={{ width: cropSize.width, height: cropSize.height }}
              onCropSizeChange={setCropSize}
              restrictPosition={true}
            />
          </Box>
        )}
        {newAvatar && (
          <Box component="div" className={classes.zoomControl}>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom as number)}
            />
          </Box>
        )}
        {!newAvatar && (
          <Box component="div" className={classes.defaultAvatar}>
            <img
              src={currentAvatar ?? NoImage}
              style={{ height: AVATAR_SIZE, width: AVATAR_SIZE }}
            />
          </Box>
        )}
        <Box component="div" className={classes.actionWrapper}>
          <ActionButton disabled={updateProfilePicLoading} color="secondary">
            <label {...{ className: classes.label }}>
              <input
                name="avatarInput"
                disabled={updateProfilePicLoading}
                style={{ display: 'none', width: '100%' }}
                type="file"
                accept="image/*"
                onChange={onSelectFile}
                multiple={false}
              />

              <FormattedMessage id="userprofile.uploadavatar.choosefile.label" />
            </label>
          </ActionButton>
          <ActionButton
            loading={updateProfilePicLoading}
            disabled={!croppedAvatar}
            color="primary"
            onClick={() => {
              if (croppedAvatar && avatarFilename) {
                updateProfilePicMutation(
                  new File(
                    [croppedAvatar],
                    avatarFilename.replace(/\.[^/.]+$/, '.jpeg'),
                    { type: 'image/jpeg' },
                  ),
                );
              }
            }}
          >
            <FormattedMessage id="userprofile.uploadavatar.saveavatar.label" />
          </ActionButton>
        </Box>
      </Box>
    </DialogBase>
  );
};

export default AvatarUploader;
