import React, { useState, FC } from 'react';
import { QRCodeContext } from './QRCodeContext';

interface IProps {
  children?: React.ReactNode;
}
const QRCodeProvider: FC<IProps> = ({ children }) => {
  const [countryCode, setCountryCode] = useState<string>('');
  const [numericCode, setNumericCode] = useState<string>('');
  const [epi, setEpi] = useState<string>('');
  const [physicalNumber, setPhysicalNumber] = useState<string>('');

  return (
    <QRCodeContext.Provider
      value={{
        countryCode: countryCode,
        setCountryCode: (countryCode: string) => {
          setCountryCode(countryCode);
        },
        numericCode: numericCode,
        setNumericCode: (numericCode: string) => {
          setNumericCode(numericCode);
        },
        epi: epi,
        setEpi: (epi: string) => {
          setEpi(epi);
        },
        physicalNumber: physicalNumber,
        setPhysicalNumber: (physicalNumber: string) => {
          setPhysicalNumber(physicalNumber);
        },
      }}
    >
      {children}
    </QRCodeContext.Provider>
  );
};

export default QRCodeProvider;
