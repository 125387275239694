import React from 'react';
import { useMutation, FetchResult } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

import { useErrorHanlder } from '../../../modules/hooks';
import { ApolloErrorCode } from '../../../consts';
import { USER_RESET_PASSWORD } from '../mutation';
import {
  HealthProfileResetPassword,
  HealthProfileResetPasswordVariables,
  HealthProfileResetPassword_healthProfileResetPassword_response,
} from '../types/HealthProfileResetPassword';

export const usePasswordReset = (
  options?: IMutationOptions<HealthProfileResetPassword_healthProfileResetPassword_response>,
): {
  passwordResetMutation: (
    resetToken: string,
    newPassword: string,
    confirmPassword: string,
  ) => Promise<FetchResult<HealthProfileResetPassword>>;
  passwordResetLoading: boolean;
  errorAlert?: string | null;
  closeAlert: () => void;
} => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const handleError = useErrorHanlder();

  const [errorAlert, setErrorAlert] = React.useState<string | null>();

  const closeAlert = () => {
    setErrorAlert(null);
  };

  const [mutate, { loading: passwordResetLoading }] = useMutation<
    HealthProfileResetPassword,
    HealthProfileResetPasswordVariables
  >(USER_RESET_PASSWORD, {
    onCompleted: (data) => {
      if (data?.healthProfileResetPassword?.response?.success) {
        enqueueSnackbar(
          intl.formatMessage({
            id: 'resetpassword.ack.title.label',
          }),
          {
            variant: 'success',
            anchorOrigin: { horizontal: 'center', vertical: 'top' },
          },
        );
        options?.onSuccess?.(data.healthProfileResetPassword.response);
      }
    },
    onError: (error) => {
      handleError(error, [
        {
          code: ApolloErrorCode.INVALID_PASSWORDRESET_TOKEN,
          message: intl.formatMessage({
            id: 'forgotpassword.invalid.link.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.PASSWORD_INVALID,
          message: intl.formatMessage({
            id: 'resetpassword.newpassword.invalid.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.PASSWORD_NOT_MATCH,
          message: intl.formatMessage({
            id: 'resetpassword.confirmpassword.invalid.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },

        {
          code: ApolloErrorCode.INVALID_EMAIL_ADDRESS,
          message: intl.formatMessage({
            id: 'forgotpassword.email.not.trusted.message',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'error.message.unknow',
          }),
          callback: (error, message) => {
            setErrorAlert(message);
          },
        },
      ]);
    },
  });

  const passwordResetMutation = (
    resetToken: string,
    newPassword: string,
    confirmPassword: string,
  ): Promise<FetchResult<HealthProfileResetPassword>> => {
    return mutate({
      variables: { resetToken, confirmPassword, newPassword },
    });
  };

  return {
    passwordResetMutation,
    passwordResetLoading,
    errorAlert,
    closeAlert,
  };
};
