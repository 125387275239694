import { makeStyles, fade } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  inputLabelRoot: {
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.18px',
    color: theme.colors.text.black02,
    [theme.breakpoints.down(theme.screenbreakpoint.large + 1)]: {
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  optionLabel: {
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '24px',
    letterSpacing: '-0.18px',
    color: theme.colors.text.black02,
    width: 'fit-content',
    [theme.breakpoints.down(theme.screenbreakpoint.large + 1)]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
  },
}));
