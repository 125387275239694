export default {
  'title.hellobar': 'Voir son certificat de vaccination',
  'notice.hellobar':
    "Le certificat de vaccination n'est pas un passe sanitaire européen mais un document obligatoire que vous aurez besoin pour<br />faire une demande de passe auprès de l'ambassade de votre pays d'accueil.",
  'button.hellobar.label': 'Voir les aides',
  'button.close.hellobar.label': 'Non, merci',
  'other.service.hellobar.label':
    'Voir les autres services qui pourraient vous intéresser sur :<br/><a target="_blank" href="https://torolalana.gov.mg">https://torolalana.gov.mg</a>',
  'tips.card.label': 'Accéder à la FAQ',
  'tips.card.video.label': 'Voir la vidéo',
};
