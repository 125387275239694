import React, { useState, FC } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { PageMetaContext } from './PageMetaContext';

interface IProps {
  children?: React.ReactNode;
}
const PageMetaProvider: FC<IProps> = ({ children }) => {
  const intl = useIntl();
  const [pathLabels, setPathLabels] = useState<string[]>([]);

  // Current page label
  const pageTitle =
    pathLabels.length > 0
      ? pathLabels[pathLabels.length - 1]
      : intl.formatMessage({
          id: 'common.default.page.label',
          defaultMessage: 'Accueil',
        });

  // Default page title
  const defaultTitle = intl.formatMessage({
    id: 'common.app.name',
  });

  return (
    <PageMetaContext.Provider
      value={{
        pathLabels: pathLabels.length > 0 ? pathLabels : [pageTitle],
        setPathLabels: (pathLabels: string[]) => {
          setPathLabels(pathLabels);
        },
      }}
    >
      <Helmet
        titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : ''}
        defer={false}
      >
        <title>{pageTitle}</title>
      </Helmet>
      {children}
    </PageMetaContext.Provider>
  );
};

export default PageMetaProvider;
