import React, { FC, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import InputAdornment from '@material-ui/core/InputAdornment';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/AlternateEmail';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import TodayIcon from '@material-ui/icons/Today';
import InputMask from 'react-input-mask';
import { sub } from 'date-fns';
import Popup from 'reactjs-popup';

import { EnumGender } from '../../../types';
import AutoCompleteField from '../../../components/inputfields/AutoCompleteField';
import DateInputField from '../../../components/inputfields/DateInputField';
import TextInputField from '../../../components/inputfields/TextInputField';
import {
  useAllAddress,
  useAllMunicipality,
  useAllRegion,
  useFokontaniesByMunicipality,
} from '../../../graphql/localization/hooks';
import RadioInputField from '../../../components/inputfields/RadioInputField';
import { useFormik } from '../formik';
import useStyles from './styles';
import ActionButton from '../../../components/button/ActionButton';
import CitizenFokontanySearch from '../CitizenFokontanySearch';
import SearchIcon from '@material-ui/icons/Search';

interface ICitizenInfoFormProps {
  isLoading?: boolean;
  formik: ReturnType<typeof useFormik>;
}

const CitizenInfoForm: FC<ICitizenInfoFormProps> = ({
  isLoading: isParentFormLoading,
  formik,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const { regions, isLoading: isLoadingRegion } = useAllRegion();

  const { municipalities, isLoading: isLoadingMunicipality } =
    useAllMunicipality();

  const { fokontanies, isLoading: isLoadingFokontany } =
    useFokontaniesByMunicipality(
      formik.values.municipalityId,
      !municipalities.length,
    );

  const { addresses, isLoading: isLoadingAddress } = useAllAddress(
    formik.values.fokontanyId,
  );

  const [showPopup, setShowPopup] = useState(false);
  const overlayStyle = { background: 'rgba(0,0,0,0.5)' };

  const isLoading =
    isParentFormLoading ||
    isLoadingRegion ||
    isLoadingMunicipality ||
    isLoadingFokontany ||
    isLoadingAddress;

  return (
    <>
      <Grid item md={4} xs={12}>
        <TextInputField
          required={true}
          name="lastName"
          label={intl.formatMessage({
            id: 'user.name.label',
          })}
          fullWidth
          disabled={isLoading}
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextInputField
          name="firstName"
          label={intl.formatMessage({
            id: 'user.firstname.label',
          })}
          fullWidth
          disabled={isLoading}
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <DateInputField
          name="birthDate"
          label={intl.formatMessage({
            id: 'citizen.birthdate.label',
          })}
          value={formik.values.birthDate}
          onChange={(newBirthDate) =>
            formik.setFieldValue('birthDate', newBirthDate)
          }
          onBlur={formik.handleBlur}
          error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
          helperText={formik.touched.birthDate && formik.errors.birthDate}
          disabled={isLoading}
          inputProps={{
            maxLength: 12,
          }}
          fullWidth
          required
          maxDate={sub(new Date(), { years: 18 })}
          maxDateMessage={intl.formatMessage({
            id: 'error.birth.date.max.label',
          })}
          onError={(_errorElem, dateVal) => {
            String(_errorElem) &&
              formik.setErrors({
                birthDate: String(_errorElem) || undefined,
              });
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TodayIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <RadioInputField
          required={true}
          aria-label="gender"
          name="gender"
          label={intl.formatMessage({
            id: 'userprofile.gender.label',
          })}
          value={formik.values.gender}
          row
          onChange={(event, newValue) =>
            formik.handleChange('gender')(newValue)
          }
          options={[
            {
              value: EnumGender.MALE,
              label: intl.formatMessage({
                id: 'userprofile.gender.male.label',
              }),
            },
            {
              value: EnumGender.FEMALE,
              label: intl.formatMessage({
                id: 'userprofile.gender.female.label',
              }),
            },
          ]}
          getOptionKey={(item) => item.value}
          getOptionLabel={(item) => item.label}
          onBlur={formik.handleBlur}
          error={formik.touched.gender && Boolean(formik.errors.gender)}
          helperText={formik.touched.gender && formik.errors.gender}
          disabled={isLoading}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <InputMask
          required={true}
          mask="099 99 999 99"
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disableUnderline
          name="phoneNumber"
          disabled={isLoading}
        >
          {(inputProps) => (
            <TextInputField
              {...inputProps}
              label={intl.formatMessage({
                id: 'user.phonenumber.label',
              })}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              fullWidth
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PhoneIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </InputMask>
      </Grid>
      <Grid item md={4} xs={12}>
        <TextInputField
          name="email"
          label={intl.formatMessage({
            id: 'user.email.label',
          })}
          fullWidth
          disabled={isLoading}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <InputMask
          required={true}
          mask="999 999 999 999"
          value={formik.values.cniNum}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disableUnderline
          name="cniNum"
          disabled={isLoading}
        >
          {(inputProps) => (
            <TextInputField
              {...inputProps}
              label={intl.formatMessage({
                id: 'citizen.cni.number.label',
              })}
              variant="outlined"
              fullWidth
              error={formik.touched.cniNum && Boolean(formik.errors.cniNum)}
              helperText={formik.touched.cniNum && formik.errors.cniNum}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FingerprintIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </InputMask>
      </Grid>
      <Grid item md={4} xs={12}>
        <AutoCompleteField
          id="municipality-picker"
          name="municipalityId"
          required={true}
          isLoading={isLoading}
          defaultValue={formik.values.municipalityId ?? ''}
          items={municipalities}
          label={intl.formatMessage({
            id: 'citizen.municipality.label',
          })}
          getItemKey={(item) => `${item?.id ?? ''}`}
          getItemLabel={(item) => `${item?.district?.name} / ${item?.name}`}
          onSelectionChange={(selectedVal) => {
            formik.handleChange('municipalityId')(selectedVal ?? '');
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.municipalityId &&
            Boolean(formik.errors.municipalityId)
          }
          helperText={
            formik.touched.municipalityId && formik.errors.municipalityId
          }
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <ActionButton
          color="primary"
          className={classes.popupActionButton}
          onClick={() => setShowPopup(true)}
        >
          <SearchIcon />
          <FormattedMessage id={'citizen.search.fokontany.label'} />
        </ActionButton>
      </Grid>
      <Grid item md={4} xs={12}>
        <AutoCompleteField
          id="fokontany-picker"
          name="fokontanyId"
          required={true}
          isLoading={isLoading}
          defaultValue={formik.values.fokontanyId ?? ''}
          items={fokontanies}
          label={intl.formatMessage({
            id: 'citizen.fokontany.label',
          })}
          getItemKey={(item) => `${item?.id ?? ''}`}
          getItemLabel={(item) => `${item?.name}`}
          onSelectionChange={(selectedVal) => {
            formik.handleChange('fokontanyId')(selectedVal ?? '');
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.fokontanyId && Boolean(formik.errors.fokontanyId)
          }
          helperText={formik.touched.fokontanyId && formik.errors.fokontanyId}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <AutoCompleteField
          id="address-picker"
          name="addressName"
          freeSolo={true}
          required={true}
          isLoading={isLoading}
          defaultValue={formik.values.addressName ?? undefined}
          items={[...new Set(addresses.map((adrItem) => adrItem.name))]}
          label={intl.formatMessage({
            id: 'citizen.address.label',
          })}
          getItemKey={(item) => item ?? ''}
          getItemLabel={(item) => item ?? ''}
          onSelectionChange={(selectedVal) => {
            formik.handleChange('addressName')(selectedVal ?? '');
          }}
          onBlur={formik.handleBlur}
          error={formik.touched.addressId && Boolean(formik.errors.addressId)}
          helperText={formik.touched.addressId && formik.errors.addressId}
        />
      </Grid>
      <Popup
        position="center center"
        modal
        open={showPopup}
        onClose={() => setShowPopup(false)}
        overlayStyle={overlayStyle}
        closeOnDocumentClick={false}
      >
        <CitizenFokontanySearch
          formik={formik}
          regions={regions}
          stateChange={setShowPopup}
          isLoading={isLoading}
        />
      </Popup>
    </>
  );
};

export default CitizenInfoForm;
