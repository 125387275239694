import { useIntl } from 'react-intl';
import fr_FR from './fr-FR';
import mg_MG from './mg-MG';

const messages: Record<string, Record<string, string>> = {
  fr: fr_FR,
  mg: mg_MG,
};

const toTitleCase = (str: string): string => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};
// intl helper to load the content according to the current locale
export const useIntlLabel: () => (
  obj: Record<string, string | any>,
  attr: string,
) => string = () => {
  const intl = useIntl();
  return (obj: Record<string, string | any>, attr: string): string => {
    return String(obj[`${attr}${toTitleCase(intl.locale)}`] ?? obj[attr]);
  };
};

export default messages;
