import { makeStyles } from '@material-ui/core/styles';
import GovBrandPatternImage from '../../../assets/images/gov-mg-brand-original-pattern.png';

export interface IStyleProps {
  showBackground?: boolean;
}

export const useStyles = makeStyles((theme) => ({
  root: ({ showBackground }: IStyleProps) => ({
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    background: showBackground
      ? `url(${GovBrandPatternImage}) no-repeat calc(100% - 64px) center / 542px 521px, linear-gradient(to bottom, ${theme.colors.background.paper}, ${theme.colors.background.default})`
      : `linear-gradient(to bottom, ${theme.colors.background.paper}, ${theme.colors.background.default})`,
    overflow: 'auto',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      background: showBackground
        ? `url(${GovBrandPatternImage}) no-repeat center center / 361px 347px, linear-gradient(to bottom, ${theme.colors.background.paper}, ${theme.colors.background.default})`
        : `linear-gradient(to bottom, ${theme.colors.background.paper}, ${theme.colors.background.default})`,
    },
  }),
  container: {
    flexGrow: 1,
    maxWidth: theme.maxContainerWidth,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down(theme.maxContainerWidth + 1)]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone)]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
}));
