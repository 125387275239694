import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme) => ({
  modal: {
    fontSize: '12px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    display: 'flex',
    padding: theme.spacing(5),
  },
  title: {
    fontSize: '25px',
    fontWeight: 800,
    color: theme.colors.text.tipsPrimaryTitle,
    marginBottom: '20px',
    float: 'left',
  },
  subTitle: {
    fontStyle: 'italic',
  },
  button: {
    margin: 'auto',
  },
  buttonClose: {
    margin: 'auto',
    backgroundColor: theme.button.secondary,
    color: theme.colors.text.white,
    borderRadius: 5,
    marginTop: '-11px',
    padding: theme.spacing(1.5),
    float: 'right',
    cursor: 'pointer',
  },
  notice: {
    fontSize: '12px',
  },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    padding: '1px 9px 5px',
    right: '-10px',
    top: '-10px',
    fontSize: '20px',
    background: '#f4364c',
    borderRadius: '18px',
    textDecoration: 'none',
    color: '#fff',
    fontWeight: 800,
  },
}));

export default styles;
