import { makeStyles } from '@material-ui/core/styles';

const DEFAULT_TIPS_WIDHT = 32;
const DEFAULT_TIPS_HEIGHT = 22;
const MAX_LG_TIPS_WIDH = 22;
const MAX_LG_TIPS_HEIGHT = 14;

const DEFAULT_CAPTION_WIDHT = 41;
const MAX_LG_CAPTION_WIDHT = 36;

const LG_SCREEN_BREAKPOINT = 1598;

export const useStyles = makeStyles((theme) => ({
  root: {
    flexBasis: '50%',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down(LG_SCREEN_BREAKPOINT)]: {
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone + 134)]: {
      flexBasis: '100%',
    },
  },
  mainWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'center',
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone + 134)]: {
      flexDirection: 'column',
      justifyItems: 'center',
      justifyContent: 'center',
    },
  },
  tipsImageWrapper: {
    marginBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  tipsImage: {
    maxWidth: theme.spacing(DEFAULT_TIPS_WIDHT),
    maxHeight: theme.spacing(DEFAULT_TIPS_HEIGHT),
    [theme.breakpoints.down(theme.screenbreakpoint.large + 1)]: {
      maxWidth: theme.spacing(MAX_LG_TIPS_WIDH),
      maxHeight: theme.spacing(MAX_LG_TIPS_HEIGHT),
    },
  },
  tipsCaptionWrapper: {
    width: theme.spacing(DEFAULT_CAPTION_WIDHT),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down(theme.screenbreakpoint.large + 1)]: {
      width: theme.spacing(MAX_LG_CAPTION_WIDHT),
    },
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone + 134)]: {
      width: 'auto',
      alignItems: 'center',
    },
  },
  tipsCaption: {
    fontFamily: theme.fonts.default,
    textAlign: 'left',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.82px',
    color: theme.colors.text.black03,
    wordBreak: 'keep-all',
    padding: theme.spacing(0, 3, 0, 3),
    [theme.breakpoints.down(theme.screenbreakpoint.smartphone + 134)]: {
      marginTop: theme.spacing(2),
      textAlign: 'center',
      maxWidth: theme.spacing(MAX_LG_CAPTION_WIDHT),
    },
  },
}));
