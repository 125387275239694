import React, { FC } from 'react';
import { InputLabel, Box } from '@material-ui/core';
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
} from '@material-ui/pickers/DatePicker/DatePicker';
import { useIntl } from 'react-intl';
import { useStyles } from '../TextInputField/styles';

type IDateInputFieldProps = Omit<
  KeyboardDatePickerProps,
  | 'variant'
  | 'color'
  | 'inputVariant'
  | 'disableToolbar'
  | 'disableFuture'
  | 'KeyboardButtonProps'
  | 'invalidDateMessage'
>;

const DateInputField: FC<IDateInputFieldProps> = ({
  label,
  ...otherProps
}): React.ReactElement => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <Box component="div">
      <InputLabel
        disableAnimation={true}
        className={classes.inputLabelRoot}
        required={otherProps.required}
      >
        {label}
      </InputLabel>
      <KeyboardDatePicker
        color="secondary"
        inputVariant="outlined"
        className={classes.textFieldroot}
        disableToolbar
        disableFuture={false}
        variant="inline"
        format="dd/MM/yyyy"
        fullWidth
        inputProps={{
          maxLength: 12,
        }}
        invalidDateMessage={intl.formatMessage({
          id: 'error.date.invalid.message',
        })}
        KeyboardButtonProps={{
          disabled: true,
          style: { display: 'none' },
        }}
        {...otherProps}
      />
    </Box>
  );
};

export default DateInputField;
