export default {
  'forgotpassword.title.label': 'Hadino ny teny miafina',
  'forgotpassword.hint.message':
    'Azafady mba fenoy ity takelaka manaraka ity hamerenana ny teny miafinao.',
  'forgotpassword.username.required.message':
    "Tsy maintsy mampiditra solon'anarana",
  'forgotpassword.id.label': "Adiresy mailaka na solon'anarana",
  'forgotpassword.reset.mypassword.label': 'Avereno ny teny miafiko',
  'forgotpassword.reset.cancel.label': 'Miala',
  'forgotpassword.login.invalid.message':
    "Tsy fantatra io solon'anarana na adiresy mailaka io. Tsy mbola misy kaonty mifanaraka amin'ireo ato!",
  'forgotpassword.email.not.trusted.message':
    'Tsy mety ny adiresy mailaka nomenao. Mety tsy nanome adiresy mailaka koa ianao. Noho izany dia tsy afaka mamerina ny teny miafinao ianao.',
  'forgotpassword.username.invalid.message':
    'Adiresy mailaka na Laharana manokana',
  'forgotpassword.invalid.link.message':
    "Tsy afaka manohy ny fanovana teny miafina ianao. Tokony miverina any amin'ny pejy fanarenana teny miafina hadino raha hanohy izany.",
  'forgotpassword.ack.title.label':
    'Lasa ny mailaka ahafahanao manova ny teny miafinao',
  'forgotpassword.ack.resend.email.label': 'Alefaso ilay mailaka',
  'forgotpassword.ack.message.label1': "<p>Jereo any amin'ny mailakao.</p>",
  'forgotpassword.ack.message.label2':
    "Nisy mailaka nalefa any amin'ny adiresinao {theEmailAddress}.",
  'forgotpassword.ack.message.label3':
    '<p>Kitiho ny hoe "Réinitialiser mon mot de passe", dia araho ny toromarika rehetra.</p><p>Tsy nahavoaray mailaka ve ianao ?</p>',
};
