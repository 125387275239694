import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  root: {
    width: 'min-content',
    height: 'min-content',
    minWidth: '550px',
    minHeight: '260px',
    maxWidth: '1200px',
    maxHeight: '750px',
    boxSizing: 'border-box',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 20px #00000014',
    borderRadius: '15px',
    overflow: 'hidden',

    display: 'flex',
  },

  content: {
    flex: '1 1 auto',
    webkitFlex: '1 1 auto',
    msFlex: '1 1 auto',
    width: '100%',
    minWidth: 'min-content',
    boxSizing: 'border-box',
    padding: '25px',
    overflow: 'hidden',
  },

  actions: {},
}));
