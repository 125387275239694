import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import RadioGroup, { RadioGroupProps } from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useStyles } from './styles';

type IRadioInputFieldProps<T> = RadioGroupProps & {
  id?: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  options: T[];
  getOptionLabel: (item: T) => string;
  getOptionKey: (item: T) => string;
  error?: boolean;
  helperText?: React.ReactNode;
};

export default function RadioInputField<T>({
  label,
  required = false,
  disabled = false,
  error = false,
  helperText,
  options,
  getOptionKey,
  getOptionLabel,
  ...otherProps
}: IRadioInputFieldProps<T>): React.ReactElement {
  const classes = useStyles();
  return (
    <Box component="div">
      <InputLabel
        disableAnimation={true}
        className={classes.inputLabelRoot}
        required={required}
      >
        {label}
      </InputLabel>
      <RadioGroup color="secondary" {...otherProps}>
        {options.map((optionItem: T) => (
          <FormControlLabel
            key={getOptionKey(optionItem)}
            value={getOptionKey(optionItem)}
            control={<Radio />}
            label={getOptionLabel(optionItem)}
            disabled={disabled}
            classes={{ label: classes.optionLabel }}
          />
        ))}
      </RadioGroup>
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </Box>
  );
}
