import signin from './signin';
import common from './common';
import errors from './errors';
import changepassword from './changepassword';
import userprofile from './userprofile';
import user from './user';
import citizen from './citizen';
import register from './register';
import profile from './profile';
import card from './card';
import forgotpassword from './forgotpassword';
import resetpassword from './resetpassword';
import hellobar from './hellobar';
import faq from './faq';

export default {
  ...hellobar,
  ...signin,
  ...register,
  ...common,
  ...errors,
  ...changepassword,
  ...userprofile,
  ...user,
  ...citizen,
  ...profile,
  ...card,
  ...forgotpassword,
  ...resetpassword,
  ...faq,
};
