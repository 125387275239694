import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: '4px 0px',
  },
  container: {
    maxWidth: theme.maxContainerWidth,
    paddingLeft: 0,
    paddingRight: 0,
    [theme.breakpoints.down(theme.maxContainerWidth + 1)]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down(theme.screenbreakpoint.xsmall)]: {
      flexDirection: 'column',
    },
  },
  govBrand: {
    width: 143,
    height: 92,
  },
  msanpBrand: {
    width: 136,
    height: 52,
    [theme.breakpoints.down(theme.maxContainerWidth + 1)]: {
      marginTop: theme.spacing(2),
    },
  },
  flagIcon: {
    height: 32,
    width: 32,
    marginRight: 8,
  },
  languageLabel: {
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 900,
    color: theme.colors.text.black,
  },
}));
