import React, { FC, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage, useIntl } from 'react-intl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Alert } from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';

import { DEFAULT_SIGNIN_ROUTE } from '../../consts';
import ActionButton from '../../components/button/ActionButton';
import { usePageMetaContext } from '../../modules/PageMetaProvider';
import PageContainer from '../../components/page/PageContainer';
import {
  usePasswordResetCheck,
  usePasswordReset,
} from '../../graphql/authentication/hooks';
import TextInputField from '../../components/inputfields/TextInputField';
import useStyles from './styles';
import { useFormik } from './formik';
import ResetPasswordAckPaper from './ResetPasswordAckPaper';

type PasswordField = 'newpassword' | 'confirmpassword';

interface IParams {
  resetToken: string;
}

const ResetPassword: FC = () => {
  const intl = useIntl();

  const [showPassword, setShowPassword] = React.useState<
    Record<PasswordField, boolean>
  >({
    newpassword: false,
    confirmpassword: false,
  });

  const [showAckForm, setShowAckForm] = useState<boolean>(false);

  const { resetToken } = useParams<IParams>();

  // Page context handler
  const { setPathLabels } = usePageMetaContext();
  const classes = useStyles();

  // Update page context
  useEffect(() => {
    setPathLabels([
      intl.formatMessage({
        id: 'resetpassword.title.label',
      }),
    ]);
  }, []);

  // Check if the password reset link has not expired
  const {
    isLoading: passwordResetCheckIsLoading,
    isValid: passwordResetTokenIsValid,
    errorAlert: passwordResetCheckErrorAlert,
    closeAlert: passwordResetCheckCloseAlert,
  } = usePasswordResetCheck(resetToken);

  // Reset the password
  const {
    passwordResetLoading,
    passwordResetMutation,
    closeAlert: passwordResetCloseAlert,
    errorAlert: passwordResetErrorAlert,
  } = usePasswordReset({
    onSuccess: () => {
      setShowAckForm(true);
    },
  });

  // Of formik as form state handler
  const formik = useFormik({
    onSubmit: async (values, helper) => {
      passwordResetMutation(
        resetToken,
        values.newPassword,
        values.confirmPassword,
      );
    },
  });

  const toggleShowPassword = (fieldName: PasswordField) => () => {
    setShowPassword((oldState) => ({
      ...oldState,
      [fieldName]: !oldState[fieldName],
    }));
  };

  const isLoading = passwordResetCheckIsLoading || passwordResetLoading;
  const errorAlert = passwordResetCheckErrorAlert || passwordResetErrorAlert;
  const closeAlert = () => {
    passwordResetCheckCloseAlert();
    passwordResetCloseAlert();
  };

  return (
    <PageContainer showBackground={true}>
      <Box component="div" className={classes.root}>
        <Paper className={clsx(classes.paper, { [classes.ackPaper]: true })}>
          {showAckForm ? (
            <ResetPasswordAckPaper />
          ) : (
            <Box component="div" className={classes.container}>
              <Typography
                component="h1"
                variant="h4"
                className={classes.formTitle}
              >
                {intl.formatMessage({
                  id: 'resetpassword.title.label',
                })}
              </Typography>
              <Typography className={classes.messages}>
                {intl.formatMessage({
                  id: 'forgotpassword.hint.message',
                })}
              </Typography>
              {errorAlert && (
                <Box component="div" className={classes.alertContainer}>
                  <Alert severity="error" onClose={() => closeAlert()}>
                    {errorAlert}
                  </Alert>
                </Box>
              )}
              <form
                className={classes.formBox}
                onSubmit={formik.handleSubmit}
                autoComplete="off"
              >
                <TextInputField
                  name="newPassword"
                  id="newPassword"
                  required
                  type={showPassword.newpassword ? 'text' : 'password'}
                  label={intl.formatMessage({
                    id: 'resetpassword.password.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'resetpassword.password.label',
                  })}
                  fullWidth
                  disabled={isLoading || !passwordResetTokenIsValid}
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.newPassword &&
                    Boolean(formik.errors.newPassword)
                  }
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={toggleShowPassword('newpassword')}
                      >
                        <IconButton size="small">
                          {showPassword.newpassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextInputField
                  name="confirmPassword"
                  id="confirmPassword"
                  required
                  type={showPassword.confirmpassword ? 'text' : 'password'}
                  label={intl.formatMessage({
                    id: 'resetpassword.confirm.password.label',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'resetpassword.confirm.password.label',
                  })}
                  fullWidth
                  disabled={isLoading || !passwordResetTokenIsValid}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={toggleShowPassword('confirmpassword')}
                      >
                        <IconButton size="small">
                          {showPassword.confirmpassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Grid item xs={12}>
                  <ActionButton
                    fullWidth
                    loading={isLoading}
                    disabled={!formik.isValid || !passwordResetTokenIsValid}
                    color="primary"
                    type="submit"
                    className={classes.actionButton}
                  >
                    <FormattedMessage id={'resetpassword.save.label'} />
                  </ActionButton>
                </Grid>
              </form>
              <Grid item xs={12}>
                <Link to={DEFAULT_SIGNIN_ROUTE} className={classes.signLink}>
                  <ActionButton
                    fullWidth
                    color={'secondary'}
                    disabled={isLoading}
                    className={classes.actionButton}
                  >
                    <FormattedMessage
                      id={'forgotpassword.reset.cancel.label'}
                    />
                  </ActionButton>
                </Link>
              </Grid>
            </Box>
          )}
        </Paper>
      </Box>
    </PageContainer>
  );
};

export default ResetPassword;
