import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useIntl } from 'react-intl';
import TextInputField from '../../TextInputField';
import { IAutoCompleteFieldProps } from '../AutoCompleteField';

export default function RealAutoCompleteField<T>({
  id,
  getItemKey,
  getItemLabel,
  items,
  onSelectionChange,
  defaultValue,
  required = false,
  multiline = false,
  disabled = false,
  freeSolo = false,
  label,
  placeholder,
  name,
  error,
  helperText,
  onBlur,
  textFieldProps = {},
}: IAutoCompleteFieldProps<T>): React.ReactElement {
  const intl = useIntl();
  const [autoCompleteValue, setAutoCompleteValue] =
    React.useState<T | string | null>(null);
  const [inputValue, setInputValue] = React.useState<string>('');

  const getOptionLabel = (option: T): string => {
    if (getItemLabel) return getItemLabel?.(option);
    else return String(option);
  };

  const getOptionKey = (option: T | null): string => {
    if (getItemKey) return getItemKey?.(option);
    else return String(option);
  };

  const getDefaultValue = (itemKey?: string | null) => {
    if (items && items.length > 0) {
      const defaultSelectedOption = items.find(
        (itm) => getOptionKey(itm) === itemKey,
      );
      if (defaultSelectedOption) {
        return defaultSelectedOption;
      }
    }
    if (freeSolo) return itemKey;
    return null;
  };

  return (
    <Autocomplete
      id={`controlled-autocomplete-${id}`}
      freeSolo={freeSolo}
      disabled={disabled}
      options={items}
      autoComplete={false}
      autoHighlight={false}
      autoSelect={true}
      noOptionsText={intl.formatMessage({
        id: 'common.label.nooption',
      })}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, currentValue) => {
        return getOptionKey(option) === getOptionKey(currentValue);
      }}
      value={getDefaultValue(defaultValue) ?? autoCompleteValue}
      onChange={(event, newValue) => {
        setAutoCompleteValue(newValue ?? null);
        if (typeof newValue !== 'string') {
          onSelectionChange?.(getOptionKey(newValue));
        } else {
          onSelectionChange?.((newValue as string) ?? '');
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextInputField
          {...{
            ...params,
            InputProps: {
              ...params.InputProps,
              startAdornment: textFieldProps.InputProps?.startAdornment,
            },
          }}
          multiline={multiline}
          rowsMax={4}
          required={required}
          name={name}
          label={label}
          placeholder={placeholder}
          error={error}
          helperText={helperText}
          onBlur={onBlur}
          fullWidth
        />
      )}
    />
  );
}
