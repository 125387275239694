export default {
  'common.default.page.label': 'Fandraisana',
  'common.app.name': 'CoVaX - Profil de santé - Madagascar',
  'common.health.profile.label': 'PROFIL DE SANTÉ',
  'common.page.footer.copyright': `Copyright © ${new Date().getFullYear()} vaksiny.gov.mg`,
  'common.label.cancel': 'Miala',
  'common.label.nooption': 'Tsy misy',
  'common.noinformation.label': 'Tsy fantatra',
  'common.button.reload.label': 'Mamerina misintona',
  'common.editor.email.invalid.message': 'Tsy mety io adiresy mailaka io',
  'common.editor.email.not.trusted.message':
    'Tsy ekena ny adiresy mailaka ampiasainao. Mampiasà adiresy mailaka voamarina ary azo antoka tsara.',
  'common.editor.phonenumber.invalid.message': 'Tsy mety io laharana finday io',
  'common.editor.cniNum.invalid.message': 'Tsy mety io laharana karatra io',
  'common.editor.date.invalid.message': 'Tsy mety io daty io',
  'common.editor.cnapsid.invalid.message': 'Tsy mety io laharana CNAPS!',
  'common.editor.required.message':
    'Tsy maintsy fenoina ireo rehetra misy marika (*).',
  'common.language.fr.iso': 'FR',
  'common.language.fr.iso.tooltip': 'Utiliser la langue française',
  'common.language.mg.iso': 'MG',
  'common.language.mg.iso.tooltip': 'Misafidy ny fiteny malagasy',
};
