import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { ApolloErrorCode } from '../../../consts';
import { useErrorHanlder } from '../../../modules/hooks';

import { GET_DISTRICTS_BY_REGION } from '../query';
import {
  GetDistrictsByRegion,
  GetDistrictsByRegionVariables,
  GetDistrictsByRegion_districtsByRegion,
  GetDistrictsByRegion_districtsByRegion_region,
} from '../types/GetDistrictsByRegion';

export const useDistrictsByRegion = (
  regionId?: string | null,
  skip?: boolean,
): {
  isLoading: boolean;
  districts: GetDistrictsByRegion_districtsByRegion[];
} => {
  // Error handler
  const handleError = useErrorHanlder();

  // Intl
  const intl = useIntl();

  // Graphql query
  const { data, loading } = useQuery<
    GetDistrictsByRegion,
    GetDistrictsByRegionVariables
  >(GET_DISTRICTS_BY_REGION, {
    variables: { regionId: regionId ?? '' },
    skip: !regionId || skip,
    onError: (error) => {
      handleError(error, [
        {
          code: ApolloErrorCode.UKNOWN_ERROR,
          message: intl.formatMessage({
            id: 'error.loading.label',
          }),
        },
      ]);
    },
    fetchPolicy: 'cache-first',
  });

  return {
    isLoading: loading,
    districts: data?.districtsByRegion ?? [],
  };
};
