import { makeStyles, fade } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  inputLabelRoot: {
    fontFamily: theme.fonts.default,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.18px',
    color: theme.colors.text.black02,
    [theme.breakpoints.down(theme.screenbreakpoint.large + 1)]: {
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  textFieldroot: {
    padding: theme.spacing(0),
    margin: theme.spacing(1, 0, 2, 0),
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.colors.background.input,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      backgroundColor: fade(theme.palette.error.main, 0.1),
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: theme.spacing(1),
    },

    [theme.breakpoints.down(theme.screenbreakpoint.large + 1)]: {
      margin: theme.spacing(1, 0, 1, 0),
      '& .MuiOutlinedInput-root': {
        padding: '4px 8px',
      },
      '& .MuiOutlinedInput-input': {
        padding: '8px 14px',
      },
      '& .MuiAutocomplete-input': {
        padding: '8px 14px !important',
      },
    },
  },
}));
